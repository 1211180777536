<template>
    <div>
        <form v-on:submit.prevent="handleSubmit">
            <div class="d-block px-lg-6 py-lg-4">
                <div class="row row-sm">
                    <div class="col-md-6 mb-md-0 mb-3">
                        <div class="d-flex">
                            <div style="width: 30px;" class="flex-shrink-0">1.</div>
                            <div class="form-group mb-4 w-100">
                                <label class="font-weight-bold">Nama Ayah Kandung <span class="text-danger">*</span></label>
                                <input type="text" class="form-control"
                                    :class="{ 'is-invalid': submitted && $v.form.nama_ayah.$error }" v-model="form.nama_ayah">
                                <div v-if="submitted && !$v.form.nama_ayah.required" class="invalid-feedback"> Data Wajib Diisi
                                </div>
                            </div>
                        </div>
                        <div class="d-flex">
                            <div style="width: 30px;" class="flex-shrink-0">2.</div>
                            <div class="form-group mb-4 w-100">
                                <label class="font-weight-bold">Alamat Rumah Tinggal <span class="text-danger">*</span></label>
                                <textarea :class="{ 'is-invalid': submitted && $v.form.alamat_ayah.$error }"
                                    v-model="form.alamat_ayah" cols="30" rows="5" class="form-control"></textarea>
                                <div v-if="submitted && !$v.form.alamat_ayah.required" class="invalid-feedback"> Data Wajib
                                    Diisi</div>
                            </div>
                        </div>
                        <div class="d-flex">
                            <div style="width: 30px;" class="flex-shrink-0">3.</div>
                            <div class="form-group mb-4 w-100">
                                <label class="font-weight-bold">Nomor Hp <span class="text-danger">*</span></label>
                                <input type="text" class="form-control"
                                    :class="{ 'is-invalid': submitted && $v.form.telepon_ayah.$error }"
                                    v-model="form.telepon_ayah">
                                <div v-if="submitted && !$v.form.telepon_ayah.required" class="invalid-feedback"> Data Wajib
                                    Diisi</div>
                                <div v-if="submitted && !$v.form.telepon_ayah.numeric" class="invalid-feedback"> Format Harus Numeric</div>
                            </div>
                        </div>
                        <div class="d-flex">
                            <div style="width: 30px;" class="flex-shrink-0">4.</div>
                            <div class="form-group mb-4 w-100">
                                <label class="font-weight-bold">Email <span class="text-danger">*</span></label>
                                <input type="text" class="form-control"
                                    :class="{ 'is-invalid': submitted && $v.form.email_ayah.$error }" v-model="form.email_ayah">
                                <div v-if="submitted && !$v.form.email_ayah.required" class="invalid-feedback"> Data Wajib Diisi
                                </div>
                                <div v-if="submitted && !$v.form.email_ayah.email" class="invalid-feedback"> Format Email tidak valid
                                </div>
                            </div>
                        </div>
                        <div class="d-flex">
                            <div style="width: 30px;" class="flex-shrink-0">5.</div>
                            <div class="form-group mb-4 w-100">
                                <label class="font-weight-bold">Pekerjaan<span class="text-danger">*</span></label>
                                <input type="text" class="form-control"
                                    :class="{ 'is-invalid': submitted && $v.form.pekerjaan_ayah.$error }"
                                    v-model="form.pekerjaan_ayah">
                                <div v-if="submitted && !$v.form.pekerjaan_ayah.required" class="invalid-feedback"> Data Wajib
                                    Diisi</div>
                            </div>
                        </div>
                        <div class="d-flex">
                            <div style="width: 30px;" class="flex-shrink-0">6.</div>
                            <div class="form-group mb-4 w-100">
                                <label class="font-weight-bold">Nama Kantor/Perusahaan<span class="text-danger">*</span></label>
                                <input type="text" class="form-control"
                                    :class="{ 'is-invalid': submitted && $v.form.kantor_ayah.$error }"
                                    v-model="form.kantor_ayah">
                                <div v-if="submitted && !$v.form.kantor_ayah.required" class="invalid-feedback"> Data Wajib
                                    Diisi</div>
                            </div>
                        </div>
                        <div class="d-flex">
                            <div style="width: 30px;" class="flex-shrink-0">7.</div>
                            <div class="form-group mb-4 w-100">
                                <label class="font-weight-bold">Alamat Kantor/Perusahaan Ayah <span class="text-danger">*</span></label>
                                <textarea :class="{ 'is-invalid': submitted && $v.form.alamat_kantor_ayah.$error }"
                                    v-model="form.alamat_kantor_ayah" cols="30" rows="5" class="form-control"></textarea>
                                <div v-if="submitted && !$v.form.alamat_kantor_ayah.required" class="invalid-feedback"> Data Wajib Diisi</div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="d-flex">
                            <div style="width: 30px;" class="flex-shrink-0">1.</div>
                            <div class="form-group mb-4 w-100">
                                <label class="font-weight-bold">Nama Ibu Kandung <span class="text-danger">*</span></label>
                                <input type="text" class="form-control"
                                    :class="{ 'is-invalid': submitted && $v.form.nama_ibu.$error }" v-model="form.nama_ibu">
                                <div v-if="submitted && !$v.form.nama_ibu.required" class="invalid-feedback"> Data Wajib Diisi
                                </div>
                            </div>
                        </div>
                        <div class="d-flex">
                            <div style="width: 30px;" class="flex-shrink-0">2.</div>
                                <div class="form-group mb-4 w-100">
                                    <label class="font-weight-bold">Alamat Rumah Tinggal <span class="text-danger">*</span></label>
                                    <textarea :class="{ 'is-invalid': submitted && $v.form.alamat_ibu.$error }"
                                        v-model="form.alamat_ibu" cols="30" rows="5" class="form-control"></textarea>
                                    <div v-if="submitted && !$v.form.alamat_ibu.required" class="invalid-feedback"> Data Wajib Diisi
                                </div>
                            </div>
                        </div>
                        <div class="d-flex">
                            <div style="width: 30px;" class="flex-shrink-0">3.</div>
                            <div class="form-group mb-4 w-100">
                                <label class="font-weight-bold">Nomor Hp <span class="text-danger">*</span></label>
                                <input type="text" class="form-control"
                                    :class="{ 'is-invalid': submitted && $v.form.telepon_ibu.$error }"
                                    v-model="form.telepon_ibu">
                                <div v-if="submitted && !$v.form.telepon_ibu.required" class="invalid-feedback"> Data Wajib
                                    Diisi</div>
                                <div v-if="submitted && !$v.form.telepon_ibu.numeric" class="invalid-feedback"> Format Harus Numeric</div>
                            </div>
                        </div>

                        <div class="d-flex">
                            <div style="width: 30px;" class="flex-shrink-0">4.</div>
                            <div class="form-group mb-4 w-100">
                                <label class="font-weight-bold">Email <span class="text-danger">*</span></label>
                                <input type="text" class="form-control"
                                    :class="{ 'is-invalid': submitted && $v.form.email_ibu.$error }" v-model="form.email_ibu">
                                <div v-if="submitted && !$v.form.email_ibu.required" class="invalid-feedback"> Data Wajib Diisi
                                </div>
                                <div v-if="submitted && !$v.form.email_ibu.email" class="invalid-feedback"> Format Email tidak valid
                                </div>
                            </div>
                        </div>
                        <div class="d-flex">
                            <div style="width: 30px;" class="flex-shrink-0">5.</div>
                            <div class="form-group mb-4 w-100">
                                <label class="font-weight-bold">Pekerjaan<span class="text-danger">*</span></label>
                                <input type="text" class="form-control"
                                    :class="{ 'is-invalid': submitted && $v.form.pekerjaan_ibu.$error }"
                                    v-model="form.pekerjaan_ibu">
                                <div v-if="submitted && !$v.form.pekerjaan_ibu.required" class="invalid-feedback"> Data Wajib
                                    Diisi</div>
                            </div>
                        </div>
                        <div class="d-flex">
                            <div style="width: 30px;" class="flex-shrink-0">6.</div>
                            <div class="form-group mb-4 w-100">
                                <label class="font-weight-bold">Nama Kantor/Perusahaan<span class="text-danger">*</span></label>
                                <input type="text" class="form-control"
                                    :class="{ 'is-invalid': submitted && $v.form.kantor_ibu.$error }" v-model="form.kantor_ibu">
                                <div v-if="submitted && !$v.form.kantor_ibu.required" class="invalid-feedback"> Data Wajib Diisi
                                </div>
                            </div>
                        </div>
                        <div class="d-flex">
                            <div style="width: 30px;" class="flex-shrink-0">7.</div>
                            <div class="form-group mb-4 w-100">
                                <label class="font-weight-bold">Alamat Kantor/Perusahaan Ibu <span class="text-danger">*</span></label>
                                <textarea :class="{ 'is-invalid': submitted && $v.form.alamat_kantor_ibu.$error }"
                                    v-model="form.alamat_kantor_ibu" cols="30" rows="5" class="form-control"></textarea>
                                <div v-if="submitted && !$v.form.alamat_kantor_ibu.required" class="invalid-feedback"> Data Wajib Diisi</div>
                            </div>
                        </div>
                    </div>
                </div>

                    <div class="d-flex">
                        <div style="width: 30px;" class="flex-shrink-0">8.</div>
                        <div class="form-group mb-4 w-100">
                            <label class="font-weight-bold">Apakah ada Kakak/Adik yang bersekolah disini?</label>
                            <div class="d-flex">
                                <div class="mr-3">
                                    <div class="pretty p-svg p-curve">
                                        <input type="radio" name="wali_lainnya" value="iya"
                                            v-model="form.wali_lainnya" :checked="form.wali_lainnya == 'iya'" />
                                        <div class="state p-primary">
                                            <svg class="svg svg-icon" viewBox="0 0 20 20">
                                                <path
                                                    d="M7.629,14.566c0.125,0.125,0.291,0.188,0.456,0.188c0.164,0,0.329-0.062,0.456-0.188l8.219-8.221c0.252-0.252,0.252-0.659,0-0.911c-0.252-0.252-0.659-0.252-0.911,0l-7.764,7.763L4.152,9.267c-0.252-0.251-0.66-0.251-0.911,0c-0.252,0.252-0.252,0.66,0,0.911L7.629,14.566z"
                                                    style="stroke: white;fill:white;"></path>
                                            </svg>
                                            <label class="font-weight-bold">Iya</label>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div class="pretty p-svg p-curve">
                                        <input type="radio" name="wali_lainnya" value="tidak"
                                            v-model="form.wali_lainnya" :checked="form.wali_lainnya == 'tidak'" />
                                        <div class="state p-primary">
                                            <svg class="svg svg-icon" viewBox="0 0 20 20">
                                                <path
                                                    d="M7.629,14.566c0.125,0.125,0.291,0.188,0.456,0.188c0.164,0,0.329-0.062,0.456-0.188l8.219-8.221c0.252-0.252,0.252-0.659,0-0.911c-0.252-0.252-0.659-0.252-0.911,0l-7.764,7.763L4.152,9.267c-0.252-0.251-0.66-0.251-0.911,0c-0.252,0.252-0.252,0.66,0,0.911L7.629,14.566z"
                                                    style="stroke: white;fill:white;"></path>
                                            </svg>
                                            <label class="font-weight-bold">Tidak</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div v-if="form.wali_lainnya == 'iya'">
                                <div v-for="(row, index) in form.keterangan_wali_lainnya" :key="index" class="d-block mt-3 border rounded-lg bg-light p-4">
                                    <div class="d-flex">
                                        <div class="flex-shrink-0 mr-md-4 mr-3">
                                            <div class="icon-circle bg-dark text-white">
                                                {{index + 1}}
                                            </div>
                                        </div>
                                        <div class="w-100">
                                            <!-- <div class="d-block">
                                                <label class="text-sm">Kakak/adik {{index + 1}}</label>
                                            </div> -->
                                            <div class="d-block mb-3">
                                                <label class="font-weight-bold">Nama Lengkap Peserta Kakak/Adik</label>
                                                <input type="text" class="form-control form-control-rounded" v-model="form.keterangan_wali_lainnya[index].name" placeholder="Masukan nama peserta">
                                            </div>
                                            <div class="d-block">
                                                <label class="font-weight-bold">Nama Sekolah</label>
                                                <input type="text" class="form-control form-control-rounded" v-model="form.keterangan_wali_lainnya[index].school" placeholder="Masukan nama sekolah peserta">
                                            </div>
                                        </div>
                                    </div>
                                    <div v-if="index > 0" class="text-right d-block mt-3">
                                        <button type="button" class="btn btn-link p-0 text-danger" v-on:click="removeFormBrother(index)"><span class="fe fe-trash mr-2"></span>Hapus</button>
                                    </div>
                                </div>
                                
                                <div class="d-block mt-3" v-if="form.keterangan_wali_lainnya.length < 5">
                                    <button type="button" class="btn btn-white text-primary" v-on:click="addFormBrother()">
                                        <div class="d-flex align-items-center">
                                            <span class="fe fe-plus mr-2"></span>
                                            <span>Tambah Anggota</span>
                                        </div>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>

            </div>

            <div class="d-flex justify-content-end pt-4 border-top">
                <button type="submit" class="btn btn-primary px-5">
                    Simpan<span class="fe fe-arrow-right ml-2"></span></button>
            </div>
        </form>
    </div>
</template>

<script>
    import {required, email, numeric} from "vuelidate/lib/validators";

   export default {
        name: 'DataWali',
        metaInfo: {
            title: 'SchoolPro',
            titleTemplate: '%s - Data Ortu'
        },
        data() {
            return {
                form : {
                    nama_ayah:"",
                    keterangan_wali_lainnya: [{'name': '', 'school': ''}]
                },
                submitted: false,
                allowAcccess : false,
                fetchData : true,
                currentFlagComponent: 'ortu',
            }
        },
        validations: {
            form : {
                nama_ayah: {
                    required
                },
                alamat_ayah: {
                    required
                },
                telepon_ayah: {
                    required,
                    numeric
                },
                pekerjaan_ayah: {
                    required
                },
                kantor_ayah: {
                    required
                },
                email_ayah: {
                    required,
                    email
                },
                alamat_kantor_ayah: {
                    required
                },
                nama_ibu: {
                    required
                },
                alamat_ibu: {
                    required
                },
                telepon_ibu: {
                    required,
                    numeric
                },
                pekerjaan_ibu: {
                    required
                },
                kantor_ibu: {
                    required
                },
                email_ibu: {
                    required,
                    email
                },
                alamat_kantor_ibu: {
                    required
                },
            }
        },
        created() {
            this.getData();            
        },
        methods: {
            handleSubmit() {
                this.submitted = true;
                this.$v.$touch();
                if (this.$v.$invalid) {
                    this.$swal({
                        icon: 'warning',
                        title: 'Ups!',
                        text: 'Harap lengkapi form',
                    });
                    return;
                } else {
                    this.$http.post(this.$apiconfig + 'website/registrasi/save_wali', {
                        registrasi_id   : this.$route.params.id,
                        nama_ayah   : this.form.nama_ayah,
                        alamat_ayah : this.form.alamat_ayah,
                        telepon_ayah    : this.form.telepon_ayah,
                        kantor_ayah         : this.form.kantor_ayah,
                        email_ayah : this.form.email_ayah,
                        alamat_kantor_ayah : this.form.alamat_kantor_ayah,
                        nama_ibu   : this.form.nama_ibu,
                        alamat_ibu : this.form.alamat_ibu,
                        telepon_ibu    : this.form.telepon_ibu,
                        kantor_ibu         : this.form.kantor_ibu,
                        email_ibu : this.form.email_ibu,
                        alamat_kantor_ibu : this.form.alamat_kantor_ibu,
                        pekerjaan_ayah : this.form.pekerjaan_ayah,
                        pekerjaan_ibu : this.form.pekerjaan_ibu,
                        flag_wali : 'ortu',
                        wali_lainnya: this.form.wali_lainnya,
                        keterangan_wali_lainnya : this.form.keterangan_wali_lainnya
                    })
                    .then(response => {
                        if (response.data.status) {
                            this.$swal({
                                icon: 'success',
                                title: 'Berhasil',
                                text: response.data.message,
                                confirmButtonText: 'OK',
                                showCancelButton: false,
                            }).then((result) => {
                                if (result.value) {
                                    this.$parent.getData();
                                    this.$parent.$parent.getStepPendaftaran();
                                    this.$parent.$parent.changeTab('DataBerkas');
                                }
                            });
                        } else {
                            this.$swal({
                                icon: 'error',
                                title: 'Oops...',
                                text: response.data.message,
                            })
                        }
                    })
                    .catch(function (error) {
                        // Swal.fire('Uh oh!', 'Please check your internet connection.', 'error')
                        console.debug("Login issue: " + error)
                    });
                }
            },
            getData: function () {
                this.fetchData = true;
                let uri = this.$apiconfig + 'website/registrasi/check_wali';
                this.$http.get(uri, {
                    params: {
                        registrasi_id: this.$route.params.id
                    }
                }
                ).then(res => {
                    let getResponse = res.data;

                    if(getResponse.status) {
                        if(getResponse.data) {
                            this.form = getResponse.data;                        
                        }
                    }
                    
                    this.allowAcccess = getResponse.status;
                    this.fetchData = false;
                });
            },
            addFormBrother: function() {
                this.form.keterangan_wali_lainnya.push({'name': '', 'school': ''});
            },
            removeFormBrother: function(index) {
                this.form.keterangan_wali_lainnya.splice(index, 1);
            }
        }
   }
</script>
