<template>
    <div>
        <!-- Loader Skeleton -->
        <div class="d-block" v-if="fetchData">
            <div class="row">
                <div class="col-md-7 mb-3">
                    <div class="p-md-3 p-2 d-block rounded border" style="background-color: #E2E7F0;">
                        <div class="card m-0">
                            <div class="card-body">
                                <div class="d-flex d-flex-mobile mb-4">
                                    <div class="skeleton-bar flex-shrink-0 mr-3">
                                        <div class="sb-icon-circle"></div>
                                    </div>
                                    <div class="flex-grow-1">
                                        <div class="skeleton-bar">
                                            <div class="sb-sm mb-3"></div>
                                            <div class="sb-lg sb-thin mb-2"></div>
                                            <div class="sb-md sb-thin"></div>
                                        </div>
                                    </div>
                                </div>
                                <div class="d-flex d-flex-mobile mb-4">
                                    <div class="skeleton-bar flex-shrink-0 mr-3">
                                        <div class="sb-icon-circle"></div>
                                    </div>
                                    <div class="flex-grow-1">
                                        <div class="skeleton-bar">
                                            <div class="sb-sm mb-3"></div>
                                            <div class="sb-lg sb-thin mb-2"></div>
                                            <div class="sb-md sb-thin"></div>
                                        </div>
                                    </div>
                                </div>
                                <div class="d-flex d-flex-mobile mb-4">
                                    <div class="skeleton-bar flex-shrink-0 mr-3">
                                        <div class="sb-icon-circle"></div>
                                    </div>
                                    <div class="flex-grow-1">
                                        <div class="skeleton-bar">
                                            <div class="sb-sm mb-3"></div>
                                            <div class="sb-lg sb-thin mb-2"></div>
                                            <div class="sb-md sb-thin"></div>
                                        </div>
                                    </div>
                                </div>
                                <div class="d-flex d-flex-mobile mb-4">
                                    <div class="skeleton-bar flex-shrink-0 mr-3">
                                        <div class="sb-icon-circle"></div>
                                    </div>
                                    <div class="flex-grow-1">
                                        <div class="skeleton-bar">
                                            <div class="sb-sm mb-3"></div>
                                            <div class="sb-lg sb-thin mb-2"></div>
                                            <div class="sb-md sb-thin"></div>
                                        </div>
                                    </div>
                                </div>
                                <div class="d-flex d-flex-mobile">
                                    <div class="skeleton-bar flex-shrink-0 mr-3">
                                        <div class="sb-icon-circle"></div>
                                    </div>
                                    <div class="flex-grow-1">
                                        <div class="skeleton-bar">
                                            <div class="sb-sm mb-3"></div>
                                            <div class="sb-lg sb-thin mb-2"></div>
                                            <div class="sb-md sb-thin"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-md-5">
                    <div class="p-md-3 p-2 d-block rounded border mb-4" style="background-color: #E2E7F0;">
                        <div class="skeleton-bar">
                            <div class="sb-lg sb-darker mb-3" style="height:40px;"></div>
                            <div class="sb-md sb-thin sb-darker "></div>
                        </div>
                    </div>
                    <div class="p-md-3 p-2 d-block rounded border" style="background-color: #E2E7F0;">
                        <div class="card m-0">
                            <div class="card-body">
                                <div class="d-flex flex-column align-items-center justify-content-center">
                                    <div class="skeleton-bar mb-4">
                                        <div class="sb-icon-circle-lg"></div>
                                    </div>
                                </div>
                                <div class="d-block w-100">
                                    <div class="skeleton-bar w-100 text-center mb-4">
                                        <div class="sb-sm sb-thin mx-auto mb-2"></div>
                                        <div class="sb-md mx-auto"></div>
                                    </div>
                                    <div class="skeleton-bar w-100 text-center mb-4">
                                        <div class="sb-sm sb-thin mx-auto mb-2"></div>
                                        <div class="sb-md mx-auto"></div>
                                    </div>
                                    <div class="skeleton-bar w-100 text-center">
                                        <div class="sb-sm sb-thin mx-auto mb-2"></div>
                                        <div class="sb-md mx-auto"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
        <!-- -->
        
        <div class="d-block" v-if="!fetchData">
            
            <!-- Alert -->
            <div class="d-block" v-if="form.registrasi.status_pendaftaran == 'waiting'">
                <div class="alert alert-info shadow">

                    <div class="d-flex align-items-center">
                        <div class="flex-shrink-0 mr-4">
                            <span class="fe fe-alert-triangle h2 m-0"></span>
                        </div>
                        <div>
                            Status formulir pendaftaran sedang dalam pengecekan.
                        </div>
                    </div>
                </div>
            </div>

            <!-- Re check -->
            <div class="d-block" v-if="form.registrasi.status_pendaftaran == 're-check'">
                <div class="alert alert-warning shadow">

                    <div class="d-flex align-items-center">
                        <div class="flex-shrink-0 mr-4">
                            <span class="fe fe-alert-triangle h2 m-0"></span>
                        </div>
                        <div>
                            Periksa kembali formulir pendaftar yang sudah dikirim
                        </div>
                    </div>
                </div>
            </div>

            <!-- Rejected -->
            <div class="d-block" v-if="form.registrasi.status_pendaftaran == 'rejected'">
                <div class="alert alert-danger shadow">

                    <div class="d-flex align-items-center">
                        <div class="flex-shrink-0 mr-4">
                            <span class="fe fe-alert-triangle h2 m-0"></span>
                        </div>
                        <div>
                            Status formulir pendaftaran anda ditolak.
                        </div>
                    </div>
                </div>
            </div>

            <div class="d-block" v-if="form.registrasi.status_pendaftaran == 'applied'">
                <div class="alert alert-success shadow">

                    <div class="d-flex align-items-center">
                        <div class="flex-shrink-0 mr-4">
                            <span class="fe fe-check-circle  h2 m-0"></span>
                        </div>
                        <div>
                            Status formulir pendaftaran sudah terkonfirmasi.
                        </div>
                    </div>
                </div>
            </div>
            <!-- -->

            <div v-bind:class="form.registrasi.status_pendaftaran == 'pending' || form.registrasi.status_pendaftaran == 're-check' ? 'if-button-floating': '' " class="row">
                <div class="col-md-7 mb-3">
                    <div class="p-md-3 p-2 d-block rounded border" style="background-color: #E2E7F0;">
                        <div class="d-flex justify-content-between mb-2">
                            <div>Data Peserta</div>
                            <div class="d-flex">
                                <div class="mr-2">
                                    <button v-on:click="generateReport" type="button" class="btn btn-link p-0"><span class="fe fe-printer mr-2"></span>Cetak</button>
                                </div>
                                <div>
                                    <button v-on:click="getData" type="button" class="btn btn-link p-0"><span class="fe fe-refresh-ccw mr-2"></span>Muat Ulang</button>
                                </div>
                            </div>
                        </div>

                        <div class="card m-0">
                            <div class="card-body pb-0">
                                <!-- Asal Pendaftar -->
                                <div class="d-block mb-4" v-if="form.formulir_pendaftaran.id">
                                    <div class="d-flex d-flex-mobile">
                                        <div class="icon-circle flex-shrink-0 mr-3 text-muted">1</div>
                                        <div class="flex-grow-1">
                                            <!-- <div class="h3 d-block mb-4 font-weight-bold pt-2">Asal Pendaftar</div> -->
                                            <button class="btn btn-link btn-block text-left px-0 text-dark font-weight-bold pb-0 pt-md-1 pt-3 h3 m-0 btn-collapse" type="button" data-toggle="collapse" data-target="#asalPendaftar" aria-expanded="false">
                                                <div class="d-flex align-items-center justify-content-between border-bottom pb-2">
                                                    <div class="font-weight-bold">
                                                        Asal Pendaftar
                                                    </div>
                                                    <div class="text-right"><span class="fe fe-chevron-down"></span></div>
                                                </div>
                                            </button>
                                            <div class="collapse show pt-3" id="asalPendaftar">
                                                <div class="d-block pb-2 mb-2 border-bottom">
                                                    <div class="mr-3">Kewarganegaraan</div>
                                                    <div class="d-block font-weight-bold">
                                                        <span v-if="form.formulir_pendaftaran.kewarganegaraan == 'wni'">WNI</span>
                                                        <span v-if="form.formulir_pendaftaran.kewarganegaraan == 'wna'">WNA</span>
                                                    </div>
                                                </div>
                                                <div class="d-block pb-2 mb-2 border-bottom">
                                                    <div class="mr-3">Provinsi Asal Bakal Calon Peserta </div>
                                                    <div class="d-block font-weight-bold">{{form.formulir_pendaftaran.asal_provinsi_nama}}</div>
                                                </div>
                                                <div class="d-block pb-2 mb-2 border-bottom">
                                                    <div class="mr-3">Kota Asal Bakal Calon Peserta </div>
                                                    <div class="d-block font-weight-bold">{{form.formulir_pendaftaran.asal_kota_nama}}</div>
                                                </div>
                                                <div class="d-block pb-2 mb-2 border-bottom">
                                                    <div class="mr-3">Asal Sekolah</div>
                                                    <div class="d-block font-weight-bold">{{form.formulir_pendaftaran.asal_pilihan_sekolah}}</div>
                                                </div>
                                                <div class="d-block pb-2 mb-2 border-bottom">
                                                    <div class="mr-3">Nama Asal Sekolah</div>
                                                    <div class="d-block font-weight-bold">{{form.formulir_pendaftaran.asal_sekolah}}</div>
                                                </div>
                                                <div class="d-block pb-2 mb-2 border-bottom">
                                                    <div class="mr-3">NPSN (Nomor Pokok Sekolah Nasional) *Khusus untuk masuk SD, SMP, SMA</div>
                                                    <div class="d-block font-weight-bold">{{form.formulir_pendaftaran.npsn}}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="d-block mb-4" v-if="!form.formulir_pendaftaran.id">
                                    <div class="d-flex d-flex-mobile">
                                        <div class="icon-circle flex-shrink-0 mr-3 text-muted hide-mobile">1</div>
                                        <div class="flex-grow-1">
                                            <div class="h3 d-block mb-4 font-weight-bold pt-2">Asal Pendaftar</div>
                                            <div class="d-block pb-2 mb-2 border-bottom">
                                                <div class="mr-3 text-danger">Data belum dilengkapi</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <!-- Data Pendaftar -->
                                <div class="d-block mb-4" v-if="form.formulir_pendaftaran.id">
                                    <div class="d-flex d-flex-mobile">
                                        <div class="icon-circle flex-shrink-0 mr-3 text-muted">2</div>
                                        <div class="flex-grow-1">                                            
                                            <!-- <div class="h3 d-block mb-4 font-weight-bold pt-2">Data Pendaftar</div> -->
                                            <button class="btn btn-link btn-block text-left px-0 text-dark font-weight-bold pb-0 pt-md-1 pt-3 h3 m-0 btn-collapse" type="button" data-toggle="collapse" data-target="#dataPendaftar" aria-expanded="false">
                                                <div class="d-flex align-items-center justify-content-between border-bottom pb-2">
                                                    <div class="font-weight-bold">
                                                        Data Pendaftar
                                                    </div>
                                                    <div class="text-right"><span class="fe fe-chevron-down"></span></div>
                                                </div>
                                            </button>
                                            <div class="collapse pt-3" id="dataPendaftar">
                                                <div class="d-block pb-2 mb-2 border-bottom">
                                                    <div class="mr-md-4 mr-3 flex-shrink-0">Nama Bakal Calon Peserta</div>
                                                    <div class="d-block font-weight-bold">{{form.formulir_pendaftaran.nama_lengkap}}</div>
                                                </div>
                                                <div class="d-block pb-2 mb-2 border-bottom">
                                                    <div class="mr-md-4 mr-3 flex-shrink-0">Jenis Kelamin</div>
                                                    <div class="d-block font-weight-bold">{{form.formulir_pendaftaran.jenis_kelamin == "L" ? 'Laki - laki':'Perempuan'}}</div>
                                                </div>
                                                <div class="d-block pb-2 mb-2 border-bottom">
                                                    <div class="mr-md-4 mr-3 flex-shrink-0">Tempat Lahir</div>
                                                    <div class="d-block font-weight-bold">{{form.formulir_pendaftaran.tempat_lahir}}</div>
                                                </div>
                                                <div class="d-block pb-2 mb-2 border-bottom">
                                                    <div class="mr-md-4 mr-3 flex-shrink-0">Tanggal Lahir</div>
                                                    <div class="d-block font-weight-bold">{{form.formulir_pendaftaran.tanggal_lahir}}</div>
                                                </div>
                                                <div class="d-block pb-2 mb-2 border-bottom">
                                                    <div class="mr-md-4 mr-3 flex-shrink-0">NISN</div>
                                                    <div class="d-block font-weight-bold">{{form.formulir_pendaftaran.nisn}}</div>
                                                </div>
                                                <div class="d-block pb-2 mb-2 border-bottom">
                                                    <div class="mr-md-4 mr-3 flex-shrink-0">NIK</div>
                                                    <div class="d-block font-weight-bold">{{form.formulir_pendaftaran.nik}}</div>
                                                </div>
                                                <div class="d-block pb-2 mb-2 border-bottom">
                                                    <div class="mr-md-4 mr-3 flex-shrink-0">Nomor Handphone</div>
                                                    <div class="d-block font-weight-bold">{{form.formulir_pendaftaran.nomor_handphone}}</div>
                                                </div>
                                                <div class="d-block pb-2 mb-2 border-bottom">
                                                    <div class="mr-md-4 mr-3 flex-shrink-0">Email</div>
                                                    <div class="d-block font-weight-bold">{{form.formulir_pendaftaran.email}}</div>
                                                </div>
                                                <div class="d-block pb-2 mb-2 border-bottom">
                                                    <div class="mr-md-4 mr-3 flex-shrink-0">Anak Ke</div>
                                                    <div class="d-block font-weight-bold">{{form.formulir_pendaftaran.anak_ke}}</div>
                                                </div>
                                                <div class="d-block pb-2 mb-2 border-bottom">
                                                    <div class="mr-md-4 mr-3 flex-shrink-0">Dari</div>
                                                    <div class="d-block font-weight-bold">{{form.formulir_pendaftaran.jumlah_saudara}}</div>
                                                </div>
                                                <div class="d-block pb-2 mb-2 border-bottom">
                                                    <div class="mr-md-4 mr-3 flex-shrink-0">Provinsi</div>
                                                    <div class="d-block font-weight-bold">{{form.formulir_pendaftaran.provinsi_nama}}</div>
                                                </div>
                                                <div class="d-block pb-2 mb-2 border-bottom">
                                                    <div class="mr-md-4 mr-3 flex-shrink-0">Kota</div>
                                                    <div class="d-block font-weight-bold">{{form.formulir_pendaftaran.kota_nama}}</div>
                                                </div>
                                                <div class="d-block pb-2 mb-2 border-bottom">
                                                    <div class="mr-md-4 mr-3 flex-shrink-0">Kecamatan</div>
                                                    <div class="d-block font-weight-bold">{{form.formulir_pendaftaran.kecamatan_nama}}</div>
                                                </div>
                                                <div class="d-block pb-2 mb-2 border-bottom">
                                                    <div class="mr-md-4 mr-3 flex-shrink-0">Kelurahan</div>
                                                    <div class="d-block font-weight-bold">{{form.formulir_pendaftaran.kelurahan_nama}}</div>
                                                </div>
                                                <div class="d-block pb-2 mb-2 border-bottom">
                                                    <div class="mr-md-4 mr-3 flex-shrink-0">RT</div>
                                                    <div class="d-block font-weight-bold">{{form.formulir_pendaftaran.rt}}</div>
                                                </div>
                                                <div class="d-block pb-2 mb-2 border-bottom">
                                                    <div class="mr-md-4 mr-3 flex-shrink-0">RW</div>
                                                    <div class="d-block font-weight-bold">{{form.formulir_pendaftaran.rw}}</div>
                                                </div>
                                                <div class="d-block pb-2 mb-2 border-bottom">
                                                    <div class="mr-md-4 mr-3 flex-shrink-0">Alamat Lengkap</div>
                                                    <div class="d-block font-weight-bold">{{form.formulir_pendaftaran.alamat_lengkap}}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="d-block mb-4" v-if="!form.formulir_pendaftaran.id">
                                    <div class="d-flex d-flex-mobile">
                                        <div class="icon-circle flex-shrink-0 mr-3 text-muted">2</div>
                                        <div class="flex-grow-1">
                                            <div class="h3 d-block mb-4 font-weight-bold pt-2">Data Pendaftar</div>
                                            <div class="d-block pb-2 mb-2 border-bottom">
                                                <div class="mr-3 text-warning">Data belum dilengkapi</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <!-- Data Wali -->
                                <div class="d-block mb-4" v-if="form.formulir_wali.id">
                                    <div class="d-flex d-flex-mobile">
                                        <div class="icon-circle flex-shrink-0 mr-3 text-muted">3</div>
                                        <div class="flex-grow-1" v-if="form.registrasi.flag_wali == 'ortu'">
                                            <!-- <div class="h3 d-block mb-4 font-weight-bold pt-2">Data Orang Tua</div> -->
                                            <button class="btn btn-link btn-block text-left px-0 text-dark font-weight-bold pb-0 pt-md-1 pt-3 h3 m-0 btn-collapse" type="button" data-toggle="collapse" data-target="#dataOrtu" aria-expanded="false">
                                                <div class="d-flex align-items-center justify-content-between border-bottom pb-2">
                                                    <div class="font-weight-bold">
                                                        Data Orang Tua
                                                    </div>
                                                    <div class="text-right"><span class="fe fe-chevron-down"></span></div>
                                                </div>
                                            </button>
                                            <div class="collapse pt-3" id="dataOrtu">
                                                <div class="d-block pb-2 mb-2 border-bottom">
                                                    <div class="mr-3 flex-shrink-0">Nama ayah</div>
                                                    <div class="d-block font-weight-bold">{{form.formulir_wali.nama_ayah}}</div>
                                                </div>
                                                <div class="d-block pb-2 mb-2 border-bottom">
                                                    <div class="mr-3 flex-shrink-0">Alamat Rumah Tinggal</div>
                                                    <div class="d-block font-weight-bold">{{form.formulir_wali.alamat_ayah}}</div>
                                                </div>
                                                <div class="d-block pb-2 mb-2 border-bottom">
                                                    <div class="mr-3 flex-shrink-0">Nomor Hp</div>
                                                    <div class="d-block font-weight-bold">{{form.formulir_wali.telepon_ayah}}</div>
                                                </div>
                                                <div class="d-block pb-2 mb-2 border-bottom">
                                                    <div class="mr-3 flex-shrink-0">Pekerjaan</div>
                                                    <div class="d-block font-weight-bold">{{form.formulir_wali.pekerjaan_ayah}}</div>
                                                </div>
                                                <div class="d-block pb-2 mb-2 border-bottom">
                                                    <div class="mr-3 flex-shrink-0">Nama Kantor/Perusahaan</div>
                                                    <div class="d-block font-weight-bold">{{form.formulir_wali.kantor_ayah}}</div>
                                                </div>
                                                <div class="d-block pb-2 mb-2 border-bottom">
                                                    <div class="mr-3 flex-shrink-0">Email</div>
                                                    <div class="d-block font-weight-bold">{{form.formulir_wali.email_ayah}}</div>
                                                </div>
                                                <div class="d-block pb-2 mb-2 border-bottom">
                                                    <div class="mr-3 flex-shrink-0">Alamat Kantor/Perusahaan</div>
                                                    <div class="d-block font-weight-bold">{{form.formulir_wali.alamat_kantor_ayah}}</div>
                                                </div>
                                                <br/>
                                                <div class="d-block pb-2 mb-2 border-bottom">
                                                    <div class="mr-3 flex-shrink-0">Nama ibu</div>
                                                    <div class="d-block font-weight-bold">{{form.formulir_wali.nama_ibu}}</div>
                                                </div>
                                                <div class="d-block pb-2 mb-2 border-bottom">
                                                    <div class="mr-3 flex-shrink-0">Alamat Rumah Tinggal</div>
                                                    <div class="d-block font-weight-bold">{{form.formulir_wali.alamat_ibu}}</div>
                                                </div>
                                                <div class="d-block pb-2 mb-2 border-bottom">
                                                    <div class="mr-3 flex-shrink-0">Nomor Hp</div>
                                                    <div class="d-block font-weight-bold">{{form.formulir_wali.telepon_ibu}}</div>
                                                </div>
                                                <div class="d-block pb-2 mb-2 border-bottom">
                                                    <div class="mr-3 flex-shrink-0">Pekerjaan</div>
                                                    <div class="d-block font-weight-bold">{{form.formulir_wali.pekerjaan_ibu}}</div>
                                                </div>
                                                <div class="d-block pb-2 mb-2 border-bottom">
                                                    <div class="mr-3 flex-shrink-0">Kantor</div>
                                                    <div class="d-block font-weight-bold">{{form.formulir_wali.kantor_ibu}}</div>
                                                </div>
                                                <div class="d-block pb-2 mb-2 border-bottom">
                                                    <div class="mr-3 flex-shrink-0">Email</div>
                                                    <div class="d-block font-weight-bold">{{form.formulir_wali.email_ibu}}</div>
                                                </div>
                                                <div class="d-block pb-2 mb-2 border-bottom">
                                                    <div class="mr-3 flex-shrink-0">Alamat Kantor/Perusahaan</div>
                                                    <div class="d-block font-weight-bold">{{form.formulir_wali.alamat_kantor_ibu}}</div>
                                                </div>
                                                <br/>
                                                <div v-if="form.formulir_wali.keterangan_wali_lainnya != null">
                                                    <div class="d-block pb-2 mb-2 border-bottom">
                                                        <div class="mr-3">Kakak/Adik yang bersekolah disini</div>
                                                        <div class="d-block font-weight-bold">{{form.formulir_wali.keterangan_wali_lainnya.length}} Orang</div>
                                                    </div>
                                                    <div class="d-block" v-for="(row, index) in form.formulir_wali.keterangan_wali_lainnya" :key="index">
                                                        <div class="d-flex p-3 mt-2 border shadow-sm rounded">
                                                            <div class="icon-circle flex-shrink-0 mr-3">
                                                                <span class="fe fe-user text-muted"></span>
                                                            </div>
                                                            <div>
                                                                <div class="d-block pb-2 mb-2 border-bottom">
                                                                    Nama Lengkap Peserta Kakak/Adik <div class="d-block font-weight-bold">{{row.name}}</div>
                                                                </div>
                                                                <div class="d-block">
                                                                    Nama Sekolah
                                                                    <div class="d-block font-weight-bold">{{row.school}}</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        
                                        <div class="flex-grow-1" v-if="form.registrasi.flag_wali == 'wali'">
                                            <button class="btn btn-link btn-block text-left px-0 text-dark font-weight-bold pb-0 pt-md-1 pt-3 h3 m-0 btn-collapse" type="button" data-toggle="collapse" data-target="#dataWali" aria-expanded="false">
                                                <div class="d-flex align-items-center justify-content-between border-bottom pb-2">
                                                    <div class="font-weight-bold">
                                                        Data Wali
                                                    </div>
                                                    <div class="text-right"><span class="fe fe-chevron-down"></span></div>
                                                </div>
                                            </button>

                                            <div class="collapse pt-3" id="dataWali">
                                                <div class="d-block pb-2 mb-2 border-bottom">
                                                    <div class="mr-3 flex-shrink-0">Nama Wali</div>
                                                    <div class="d-block font-weight-bold">{{form.formulir_wali.nama_wali}}</div>
                                                </div>
                                                <div class="d-block pb-2 mb-2 border-bottom">
                                                    <div class="mr-3 flex-shrink-0">Alamat Rumah Tinggal</div>
                                                    <div class="d-block font-weight-bold">{{form.formulir_wali.alamat_wali}}</div>
                                                </div>
                                                <div class="d-block pb-2 mb-2 border-bottom">
                                                    <div class="mr-3 flex-shrink-0">Nomor Hp</div>
                                                    <div class="d-block font-weight-bold">{{form.formulir_wali.telepon_wali}}</div>
                                                </div>
                                                <div class="d-block pb-2 mb-2 border-bottom">
                                                    <div class="mr-3 flex-shrink-0">Pekerjaan</div>
                                                    <div class="d-block font-weight-bold">{{form.formulir_wali.pekerjaan_wali}}</div>
                                                </div>
                                                <div class="d-block pb-2 mb-2 border-bottom">
                                                    <div class="mr-3 flex-shrink-0">Nama Kantor/Perusahaan</div>
                                                    <div class="d-block font-weight-bold">{{form.formulir_wali.kantor_wali}}</div>
                                                </div>
                                                <div class="d-block pb-2 mb-2 border-bottom">
                                                    <div class="mr-3 flex-shrink-0">Email</div>
                                                    <div class="d-block font-weight-bold">{{form.formulir_wali.email_wali}}</div>
                                                </div>
                                                <div class="d-block pb-2 mb-2 border-bottom">
                                                    <div class="mr-3 flex-shrink-0">Alamat Kantor/Perusahaan</div>
                                                    <div class="d-block font-weight-bold">{{form.formulir_wali.alamat_kantor_wali}}</div>
                                                </div>
                                                <br/>
                                                <div v-if="form.formulir_wali.keterangan_wali_lainnya != null">
                                                    <div class="d-block pb-2 mb-2 border-bottom">
                                                        <div class="mr-3">Kakak/Adik yang bersekolah disini</div>
                                                        <div class="d-block font-weight-bold">{{form.formulir_wali.keterangan_wali_lainnya.length}} Orang</div>
                                                    </div>
                                                    <div class="d-block" v-for="(row, index) in form.formulir_wali.keterangan_wali_lainnya" :key="index">
                                                        <div class="d-block p-2 mt-2 bg-light rounded">
                                                            <div class="d-flex">
                                                                Nama Lengkap : {{row.name}}
                                                            </div>
                                                            <div class="d-flex">
                                                                Sekolah : {{row.school}}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>

                                <div class="d-block mb-4" v-if="!form.formulir_pendaftaran.id">
                                    <div class="d-flex d-flex-mobile">
                                        <div class="icon-circle flex-shrink-0 mr-3 text-muted">3</div>
                                        <div class="flex-grow-1">
                                            <div class="h3 d-block mb-4 font-weight-bold pt-2">Data Wali</div>
                                            <div class="d-flex justify-content-between pb-2 mb-2 border-bottom">
                                                <div class="mr-3">Data belum dilengkapi</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                
                                <!-- Data Berkas -->
                                <div class="d-block mb-4" v-if="form.berkas.length > 0">
                                    <div class="d-flex d-flex-mobile">
                                        <div class="icon-circle flex-shrink-0 mr-3 text-muted">4</div>
                                        <div class="flex-grow-1">
                                            <!-- <div class="h3 d-block mb-4 font-weight-bold pt-2">Data Berkas</div> -->
                                            <button class="btn btn-link btn-block text-left px-0 text-dark font-weight-bold pb-0 pt-md-1 pt-3 h3 m-0 btn-collapse" type="button" data-toggle="collapse" data-target="#dataBerkas" aria-expanded="false">
                                                <div class="d-flex align-items-center justify-content-between border-bottom pb-2">
                                                    <div class="font-weight-bold">
                                                        Data Berkas
                                                    </div>
                                                    <div class="text-right"><span class="fe fe-chevron-down"></span></div>
                                                </div>
                                            </button>
                                            <div class="collapse pt-3" id="dataBerkas">
                                                <div v-for="(item, index) in form.berkas" :key="index" class="d-block mb-3 pb-3 border-bottom">
                                                    <div class="d-block text-sm mb-2">{{item.label_name}}</div>
                                                    <div class="d-flex align-items-center border rounded px-3 py-2" v-if="item.formulir_berkas_id">
                                                        <div class="flex-shrink-0 mr-3">
                                                            <span class="fe fe-check-circle text-success"></span>
                                                        </div>
                                                        <div><a :href="item.file_attachment" target="blank">Lihat File</a></div>
                                                    </div>

                                                    <div class="d-flex align-items-center border rounded px-3 py-2" v-if="!item.formulir_berkas_id">
                                                        <div class="flex-shrink-0 mr-3">
                                                            <span class="fe fe-alert-triangle text-danger"></span>
                                                        </div>
                                                        <div>File tidak diupload</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="d-block mb-4" v-if="form.berkas.length == 0">
                                    <div class="d-flex d-flex-mobile">
                                        <div class="icon-circle flex-shrink-0 mr-3 text-muted">4</div>
                                        <div class="flex-grow-1">
                                            <div class="h3 d-block mb-4 font-weight-bold pt-2">Data Berkas</div>
                                            <div class="d-flex justify-content-between pb-2 mb-2 border-bottom">
                                                <div class="mr-3">Data belum dilengkapi</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-5">


                    <div v-if="form.registrasi.status_pendaftaran == 'waiting' || form.registrasi.status_pendaftaran == 'applied' || form.registrasi.status_pendaftaran == 'rejected'" class="d-block mb-3 p-md-3 p-2 border rounded" style="background-color: #E2E7F0;">
                        <button type="button" class="btn btn-primary btn-block shadow-sm mb-3 btn-lg" disabled><span
                                class="fe fe-info mr-3"></span>Formulir Terkirim</button>
                        <div class="d-flex align-items-center">
                            <div class="flex-shrink-0 mr-2">
                                <span class="fe fe-info"></span>
                            </div>
                            <div class="text-sm">
                                Data formulir telah dikirim & sedang dilakukan pengecekan, mohon tunggu informasi
                                selanjutnya melalui kotak masuk di email anda.
                            </div>
                        </div>
                    </div>


                    <div class="d-block rounded border" style="background-color: #E2E7F0;">
                        <div class="p-md-3 p-2">
                            <div class="d-flex justify-content-between mb-2">
                                <div>Kartu Peserta</div>
                                <div>
                                    <button v-on:click="cetakKartu" type="button" class="btn btn-link p-0"><span class="fe fe-printer mr-2"></span>Cetak</button>
                                </div>
                            </div>
                            <div class="card m-0 lift rounded-0">
                                <div class="p-3">
                                    <div class="d-block px-3 py-4 border">
                                        <div class="d-flex justify-content-center mb-3">
                                            <!-- Profile Picture -->
                                            <div class="icon-circle icon-circle-xxlg bg-image" v-bind:style="{ 'background-image': 'url(' + form.formulir_pendaftaran.foto + ')' }"></div>
                                            <!-- -->
                                        </div>
                                        <div class="d-block text-center">
                                            <div class="h3 mb-1">{{form.formulir_pendaftaran.nama_lengkap}} ({{form.formulir_pendaftaran.jenis_kelamin}})</div>
                                            <div class="d-block">{{form.formulir_pendaftaran.asal_sekolah}}</div>
                                            <div class="d-block">{{form.formulir_pendaftaran.asal_sekolah_pendaftar}}</div>
                                            <hr>
                                            <div class="d-block text-sm">Nomor Registrasi</div>
                                            <div class="font-weight-bold">{{form.registrasi.status_pendaftaran == 'applied' ? form.registrasi.kode_registrasi : '-'}}</div>
                                            <br>
                                            <div class="d-block text-sm">Nama Formulir</div>
                                            <div class="d-block p-2 bg-light rounded">
                                                {{form.registrasi.nama_formulir}}
                                            </div>
                                            <br>
                                            <div class="d-block text-sm">Tanggal Tes</div>
                                            <div class="font-weight-bold">
                                                {{form.registrasi.jadwal_tes}}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                           </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Send Formulir Button / Floating -->
            <div v-if="form.registrasi.status_pendaftaran == 'pending' || form.registrasi.status_pendaftaran == 're-check'" class="floating-button">
                <div class="card">
                    <div class="p-3">
                        <div class="d-flex align-items-center justify-content-between d-flex-mobile">
                            <div class="ml-md-2 mb-md-0 mb-4 mr-md-3">
                                <div class="d-flex align-items-center">
                                    <div class="icon-circle text-muted flex-shrink-0 mr-3"><span class="fe fe-file"></span></div>
                                    <div>
                                        <div class="font-weight-bold">Pengiriman Formulir</div>
                                        <div class="text-sm">
                                            Mohon untuk cek kembali data pendaftar yang akan dikirimkan.
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="w-100-mobile">
                                <button v-on:click="sendFormulir" type="button" class="btn btn-primary btn-block-mobile btn-rounded px-4 font-weight-bold"><span class="fe fe-send mr-3"></span>Kirim&nbsp;Formulir</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
        
        <vue-html2pdf
                :show-layout="false"
                :float-layout="true"
                :enable-download="false"
                :preview-modal="true"
                :paginate-elements-by-height="1400"
                :filename="'datapendaftar_'+form.registrasi.kode_registrasi"
                :pdf-quality="1"
                :manual-pagination="true"
                pdf-format="a4"
                pdf-orientation="portrait"
                pdf-content-width="800px"        
                ref="html2Pdf"
                >
                <section slot="pdf-content">
                    <div class="card-body pb-0">
                        <!-- Asal Pendaftar -->
                        <div class="d-block mb-4" v-if="form.formulir_pendaftaran.id">
                            <div class="d-flex d-flex-mobile">
                                <div class="icon-circle flex-shrink-0 mr-3 text-muted">1</div>
                                <div class="flex-grow-1">
                                    <!-- <div class="h3 d-block mb-4 font-weight-bold pt-2">Asal Pendaftar</div> -->
                                    <button class="btn btn-link btn-block text-left px-0 text-dark font-weight-bold pb-0 pt-md-1 pt-3 h3 m-0 btn-collapse" type="button" data-toggle="collapse" data-target="#asalPendaftar" aria-expanded="false">
                                        <div class="d-flex align-items-center justify-content-between border-bottom pb-2">
                                            <div class="font-weight-bold">
                                                Asal Pendaftar
                                            </div>
                                        </div>
                                    </button>
                                    <div class="collapse show pt-3" id="asalPendaftar">
                                        <div class="d-block pb-2 mb-2 border-bottom">
                                            <div class="mr-3">Kewarganegaraan</div>
                                            <div class="d-block font-weight-bold">
                                                <span v-if="form.formulir_pendaftaran.kewarganegaraan == 'wni'">WNI</span>
                                                <span v-if="form.formulir_pendaftaran.kewarganegaraan == 'wna'">WNA</span>
                                            </div>
                                        </div>
                                        <div class="d-block pb-2 mb-2 border-bottom">
                                            <div class="mr-3">Provinsi Asal Bakal Calon Peserta </div>
                                            <div class="d-block font-weight-bold">{{form.formulir_pendaftaran.asal_provinsi_nama}}</div>
                                        </div>
                                        <div class="d-block pb-2 mb-2 border-bottom">
                                            <div class="mr-3">Kota Asal Bakal Calon Peserta </div>
                                            <div class="d-block font-weight-bold">{{form.formulir_pendaftaran.asal_kota_nama}}</div>
                                        </div>
                                        <div class="d-block pb-2 mb-2 border-bottom">
                                            <div class="mr-3">Asal Sekolah</div>
                                            <div class="d-block font-weight-bold">{{form.formulir_pendaftaran.asal_pilihan_sekolah}}</div>
                                        </div>
                                        <div class="d-block pb-2 mb-2 border-bottom">
                                            <div class="mr-3">Nama Asal Sekolah</div>
                                            <div class="d-block font-weight-bold">{{form.formulir_pendaftaran.asal_sekolah}}</div>
                                        </div>
                                        <div class="d-block pb-2 mb-2 border-bottom">
                                            <div class="mr-3">NPSN (Nomor Pokok Sekolah Nasional) *Khusus untuk masuk SD, SMP, SMA</div>
                                            <div class="d-block font-weight-bold">{{form.formulir_pendaftaran.npsn}}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="d-block mb-4" v-if="!form.formulir_pendaftaran.id">
                            <div class="d-flex d-flex-mobile">
                                <div class="icon-circle flex-shrink-0 mr-3 text-muted hide-mobile">1</div>
                                <div class="flex-grow-1">
                                    <div class="h3 d-block mb-4 font-weight-bold pt-2">Asal Pendaftar</div>
                                    <div class="d-block pb-2 mb-2 border-bottom">
                                        <div class="mr-3 text-danger">Data belum dilengkapi</div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- Data Pendaftar -->
                        <div class="d-block mb-4" v-if="form.formulir_pendaftaran.id">
                            <div class="d-flex d-flex-mobile">
                                <div class="icon-circle flex-shrink-0 mr-3 text-muted">2</div>
                                <div class="flex-grow-1">                                            
                                    <!-- <div class="h3 d-block mb-4 font-weight-bold pt-2">Data Pendaftar</div> -->
                                    <button class="btn btn-link btn-block text-left px-0 text-dark font-weight-bold pb-0 pt-md-1 pt-3 h3 m-0 btn-collapse" type="button" data-toggle="collapse" data-target="#dataPendaftar" aria-expanded="false">
                                        <div class="d-flex align-items-center justify-content-between border-bottom pb-2">
                                            <div class="font-weight-bold">
                                                Data Pendaftar
                                            </div>
                                        </div>
                                    </button>
                                    <div class="collapse show pt-3" id="dataPendaftar">
                                        <div class="d-block pb-2 mb-2 border-bottom">
                                            <div class="mr-md-4 mr-3 flex-shrink-0">Nama Bakal Calon Peserta</div>
                                            <div class="d-block font-weight-bold">{{form.formulir_pendaftaran.nama_lengkap}}</div>
                                        </div>
                                        <div class="d-block pb-2 mb-2 border-bottom">
                                            <div class="mr-md-4 mr-3 flex-shrink-0">Jenis Kelamin</div>
                                            <div class="d-block font-weight-bold">{{form.formulir_pendaftaran.jenis_kelamin == "L" ? 'Laki - laki':'Perempuan'}}</div>
                                        </div>
                                        <div class="d-block pb-2 mb-2 border-bottom">
                                            <div class="mr-md-4 mr-3 flex-shrink-0">Tempat Lahir</div>
                                            <div class="d-block font-weight-bold">{{form.formulir_pendaftaran.tempat_lahir}}</div>
                                        </div>
                                        <div class="d-block pb-2 mb-2 border-bottom">
                                            <div class="mr-md-4 mr-3 flex-shrink-0">Tanggal Lahir</div>
                                            <div class="d-block font-weight-bold">{{form.formulir_pendaftaran.tanggal_lahir}}</div>
                                        </div>
                                        <div class="d-block pb-2 mb-2 border-bottom">
                                            <div class="mr-md-4 mr-3 flex-shrink-0">NISN</div>
                                            <div class="d-block font-weight-bold">{{form.formulir_pendaftaran.nisn}}</div>
                                        </div>
                                        <div class="d-block pb-2 mb-2 border-bottom">
                                            <div class="mr-md-4 mr-3 flex-shrink-0">NIK</div>
                                            <div class="d-block font-weight-bold">{{form.formulir_pendaftaran.nik}}</div>
                                        </div>
                                        <div class="d-block pb-2 mb-2 border-bottom">
                                            <div class="mr-md-4 mr-3 flex-shrink-0">Nomor Handphone</div>
                                            <div class="d-block font-weight-bold">{{form.formulir_pendaftaran.nomor_handphone}}</div>
                                        </div>
                                        <div class="d-block pb-2 mb-2 border-bottom">
                                            <div class="mr-md-4 mr-3 flex-shrink-0">Email</div>
                                            <div class="d-block font-weight-bold">{{form.formulir_pendaftaran.email}}</div>
                                        </div>
                                        <div class="d-block pb-2 mb-2 border-bottom">
                                            <div class="mr-md-4 mr-3 flex-shrink-0">Anak Ke</div>
                                            <div class="d-block font-weight-bold">{{form.formulir_pendaftaran.anak_ke}}</div>
                                        </div>
                                        <div class="d-block pb-2 mb-2 border-bottom">
                                            <div class="mr-md-4 mr-3 flex-shrink-0">Dari</div>
                                            <div class="d-block font-weight-bold">{{form.formulir_pendaftaran.jumlah_saudara}}</div>
                                        </div>
                                        <div class="d-block pb-2 mb-2 border-bottom">
                                            <div class="mr-md-4 mr-3 flex-shrink-0">Provinsi</div>
                                            <div class="d-block font-weight-bold">{{form.formulir_pendaftaran.provinsi_nama}}</div>
                                        </div>
                                        <div class="d-block pb-2 mb-2 border-bottom">
                                            <div class="mr-md-4 mr-3 flex-shrink-0">Kota</div>
                                            <div class="d-block font-weight-bold">{{form.formulir_pendaftaran.kota_nama}}</div>
                                        </div>
                                        <div class="d-block pb-2 mb-2 border-bottom">
                                            <div class="mr-md-4 mr-3 flex-shrink-0">Kecamatan</div>
                                            <div class="d-block font-weight-bold">{{form.formulir_pendaftaran.kecamatan_nama}}</div>
                                        </div>
                                        <div class="d-block pb-2 mb-2 border-bottom">
                                            <div class="mr-md-4 mr-3 flex-shrink-0">Kelurahan</div>
                                            <div class="d-block font-weight-bold">{{form.formulir_pendaftaran.kelurahan_nama}}</div>
                                        </div>
                                        <div class="d-block pb-2 mb-2 border-bottom">
                                            <div class="mr-md-4 mr-3 flex-shrink-0">RT</div>
                                            <div class="d-block font-weight-bold">{{form.formulir_pendaftaran.rt}}</div>
                                        </div>
                                        <div class="d-block pb-2 mb-2 border-bottom">
                                            <div class="mr-md-4 mr-3 flex-shrink-0">RW</div>
                                            <div class="d-block font-weight-bold">{{form.formulir_pendaftaran.rw}}</div>
                                        </div>
                                        <div class="d-block pb-2 mb-2 border-bottom">
                                            <div class="mr-md-4 mr-3 flex-shrink-0">Alamat Lengkap</div>
                                            <div class="d-block font-weight-bold">{{form.formulir_pendaftaran.alamat_lengkap}}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="d-block mb-4" v-if="!form.formulir_pendaftaran.id">
                            <div class="d-flex d-flex-mobile">
                                <div class="icon-circle flex-shrink-0 mr-3 text-muted">2</div>
                                <div class="flex-grow-1">
                                    <div class="h3 d-block mb-4 font-weight-bold pt-2">Data Pendaftar</div>
                                    <div class="d-block pb-2 mb-2 border-bottom">
                                        <div class="mr-3 text-warning">Data belum dilengkapi</div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- Data Wali -->
                        <div class="d-block mb-4" v-if="form.formulir_wali.id">
                            <div class="d-flex d-flex-mobile">
                                <div class="icon-circle flex-shrink-0 mr-3 text-muted">3</div>
                                <div class="flex-grow-1" v-if="form.registrasi.flag_wali == 'ortu'">
                                    <!-- <div class="h3 d-block mb-4 font-weight-bold pt-2">Data Orang Tua</div> -->
                                    <button class="btn btn-link btn-block text-left px-0 text-dark font-weight-bold pb-0 pt-md-1 pt-3 h3 m-0 btn-collapse" type="button" data-toggle="collapse" data-target="#dataOrtu" aria-expanded="false">
                                        <div class="d-flex align-items-center justify-content-between border-bottom pb-2">
                                            <div class="font-weight-bold">
                                                Data Orang Tua
                                            </div>
                                        </div>
                                    </button>
                                    <div class="collapse show pt-3" id="dataOrtu">
                                        <div class="d-block pb-2 mb-2 border-bottom">
                                            <div class="mr-3 flex-shrink-0">Nama ayah</div>
                                            <div class="d-block font-weight-bold">{{form.formulir_wali.nama_ayah}}</div>
                                        </div>
                                        <div class="d-block pb-2 mb-2 border-bottom">
                                            <div class="mr-3 flex-shrink-0">Alamat Rumah Tinggal</div>
                                            <div class="d-block font-weight-bold">{{form.formulir_wali.alamat_ayah}}</div>
                                        </div>
                                        <div class="d-block pb-2 mb-2 border-bottom">
                                            <div class="mr-3 flex-shrink-0">Nomor Hp</div>
                                            <div class="d-block font-weight-bold">{{form.formulir_wali.telepon_ayah}}</div>
                                        </div>
                                        <div class="d-block pb-2 mb-2 border-bottom">
                                            <div class="mr-3 flex-shrink-0">Pekerjaan</div>
                                            <div class="d-block font-weight-bold">{{form.formulir_wali.pekerjaan_ayah}}</div>
                                        </div>
                                        <div class="d-block pb-2 mb-2 border-bottom">
                                            <div class="mr-3 flex-shrink-0">Nama Kantor/Perusahaan</div>
                                            <div class="d-block font-weight-bold">{{form.formulir_wali.kantor_ayah}}</div>
                                        </div>
                                        <div class="d-block pb-2 mb-2 border-bottom">
                                            <div class="mr-3 flex-shrink-0">Email</div>
                                            <div class="d-block font-weight-bold">{{form.formulir_wali.email_ayah}}</div>
                                        </div>
                                        <div class="d-block pb-2 mb-2 border-bottom">
                                            <div class="mr-3 flex-shrink-0">Alamat Kantor/Perusahaan</div>
                                            <div class="d-block font-weight-bold">{{form.formulir_wali.alamat_kantor_ayah}}</div>
                                        </div>
                                        <br/>
                                        <div class="d-block pb-2 mb-2 border-bottom">
                                            <div class="mr-3 flex-shrink-0">Nama ibu</div>
                                            <div class="d-block font-weight-bold">{{form.formulir_wali.nama_ibu}}</div>
                                        </div>
                                        <div class="d-block pb-2 mb-2 border-bottom">
                                            <div class="mr-3 flex-shrink-0">Alamat Rumah Tinggal</div>
                                            <div class="d-block font-weight-bold">{{form.formulir_wali.alamat_ibu}}</div>
                                        </div>
                                        <div class="d-block pb-2 mb-2 border-bottom">
                                            <div class="mr-3 flex-shrink-0">Nomor Hp</div>
                                            <div class="d-block font-weight-bold">{{form.formulir_wali.telepon_ibu}}</div>
                                        </div>
                                        <div class="d-block pb-2 mb-2 border-bottom">
                                            <div class="mr-3 flex-shrink-0">Pekerjaan</div>
                                            <div class="d-block font-weight-bold">{{form.formulir_wali.pekerjaan_ibu}}</div>
                                        </div>
                                        <div class="d-block pb-2 mb-2 border-bottom">
                                            <div class="mr-3 flex-shrink-0">Kantor</div>
                                            <div class="d-block font-weight-bold">{{form.formulir_wali.kantor_ibu}}</div>
                                        </div>
                                        <div class="d-block pb-2 mb-2 border-bottom">
                                            <div class="mr-3 flex-shrink-0">Email</div>
                                            <div class="d-block font-weight-bold">{{form.formulir_wali.email_ibu}}</div>
                                        </div>
                                        <div class="d-block pb-2 mb-2 border-bottom">
                                            <div class="mr-3 flex-shrink-0">Alamat Kantor/Perusahaan</div>
                                            <div class="d-block font-weight-bold">{{form.formulir_wali.alamat_kantor_ibu}}</div>
                                        </div>
                                        <br/>
                                        <div v-if="form.formulir_wali.keterangan_wali_lainnya != null">
                                            <div class="d-block pb-2 mb-2 border-bottom">
                                                <div class="mr-3">Kakak/Adik yang bersekolah disini</div>
                                                <div class="d-block font-weight-bold">{{form.formulir_wali.keterangan_wali_lainnya.length}} Orang</div>
                                            </div>
                                            <div class="d-block" v-for="(row, index) in form.formulir_wali.keterangan_wali_lainnya" :key="index">
                                                <div class="d-block p-2 mt-2 bg-light rounded">
                                                    <div class="d-flex">
                                                        Nama Lengkap : {{row.name}}
                                                    </div>
                                                    <div class="d-flex">
                                                        Sekolah : {{row.school}}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                
                                <div class="flex-grow-1" v-if="form.registrasi.flag_wali == 'wali'">
                                    <button class="btn btn-link btn-block text-left px-0 text-dark font-weight-bold pb-0 pt-md-1 pt-3 h3 m-0 btn-collapse" type="button" data-toggle="collapse" data-target="#dataWali" aria-expanded="false">
                                        <div class="d-flex align-items-center justify-content-between border-bottom pb-2">
                                            <div class="font-weight-bold">
                                                Data Wali
                                            </div>
                                        </div>
                                    </button>

                                    <div class="collapse show pt-3" id="dataWali">
                                        <div class="d-block pb-2 mb-2 border-bottom">
                                            <div class="mr-3 flex-shrink-0">Nama Wali</div>
                                            <div class="d-block font-weight-bold">{{form.formulir_wali.nama_wali}}</div>
                                        </div>
                                        <div class="d-block pb-2 mb-2 border-bottom">
                                            <div class="mr-3 flex-shrink-0">Alamat Rumah Tinggal</div>
                                            <div class="d-block font-weight-bold">{{form.formulir_wali.alamat_wali}}</div>
                                        </div>
                                        <div class="d-block pb-2 mb-2 border-bottom">
                                            <div class="mr-3 flex-shrink-0">Nomor Hp</div>
                                            <div class="d-block font-weight-bold">{{form.formulir_wali.telepon_wali}}</div>
                                        </div>
                                        <div class="d-block pb-2 mb-2 border-bottom">
                                            <div class="mr-3 flex-shrink-0">Pekerjaan</div>
                                            <div class="d-block font-weight-bold">{{form.formulir_wali.pekerjaan_wali}}</div>
                                        </div>
                                        <div class="d-block pb-2 mb-2 border-bottom">
                                            <div class="mr-3 flex-shrink-0">Nama Kantor/Perusahaan</div>
                                            <div class="d-block font-weight-bold">{{form.formulir_wali.kantor_wali}}</div>
                                        </div>
                                        <div class="d-block pb-2 mb-2 border-bottom">
                                            <div class="mr-3 flex-shrink-0">Email</div>
                                            <div class="d-block font-weight-bold">{{form.formulir_wali.email_wali}}</div>
                                        </div>
                                        <div class="d-block pb-2 mb-2 border-bottom">
                                            <div class="mr-3 flex-shrink-0">Alamat Kantor/Perusahaan</div>
                                            <div class="d-block font-weight-bold">{{form.formulir_wali.alamat_kantor_wali}}</div>
                                        </div>
                                        <br/>
                                        <div v-if="form.formulir_wali.keterangan_wali_lainnya != null">
                                            <div class="d-block pb-2 mb-2 border-bottom">
                                                <div class="mr-3">Kakak/Adik yang bersekolah disini</div>
                                                <div class="d-block font-weight-bold">{{form.formulir_wali.keterangan_wali_lainnya.length}} Orang</div>
                                            </div>
                                            <div class="d-block" v-for="(row, index) in form.formulir_wali.keterangan_wali_lainnya" :key="index">
                                                <div class="d-block p-2 mt-2 bg-light rounded">
                                                    <div class="d-flex">
                                                        Nama Lengkap : {{row.name}}
                                                    </div>
                                                    <div class="d-flex">
                                                        Sekolah : {{row.school}}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>

                        <div class="d-block mb-4" v-if="!form.formulir_pendaftaran.id">
                            <div class="d-flex d-flex-mobile">
                                <div class="icon-circle flex-shrink-0 mr-3 text-muted">3</div>
                                <div class="flex-grow-1">
                                    <div class="h3 d-block mb-4 font-weight-bold pt-2">Data Wali</div>
                                    <div class="d-flex justify-content-between pb-2 mb-2 border-bottom">
                                        <div class="mr-3">Data belum dilengkapi</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
        </vue-html2pdf>

        <vue-html2pdf
                :show-layout="false"
                :float-layout="true"
                :enable-download="false"
                :preview-modal="true"
                :paginate-elements-by-height="1400"
                :filename="'datapendaftar_'+form.registrasi.kode_registrasi"
                :pdf-quality="1"
                :manual-pagination="true"
                pdf-format="a4"
                pdf-orientation="portrait"
                pdf-content-width="400px"        
                ref="cetak_kartu"
                >
                <section slot="pdf-content">
                    <div class="card m-0 lift rounded-0">
                        <div class="p-3">
                            <div class="d-block px-3 py-4 border">
                                <div class="d-flex justify-content-center mb-3">
                                    <!-- Profile Picture -->
                                    <div class="icon-circle icon-circle-xxlg bg-image" v-bind:style="{ 'background-image': 'url(' + form.formulir_pendaftaran.foto + ')' }"></div>
                                    <!-- -->
                                </div>
                                <div class="d-block text-center">
                                    <div class="h3 mb-1">{{form.formulir_pendaftaran.nama_lengkap}} ({{form.formulir_pendaftaran.jenis_kelamin}})</div>
                                    <div class="d-block">{{form.formulir_pendaftaran.asal_sekolah}}</div>
                                    <div class="d-block">{{form.formulir_pendaftaran.asal_sekolah_pendaftar}}</div>
                                    <hr>
                                    <div class="d-block text-sm">Nomor Registrasi</div>
                                    <div class="font-weight-bold">{{form.registrasi.kode_registrasi}}</div>
                                    <br>
                                    <div class="d-block text-sm">Nama Formulir</div>
                                    <div class="d-block p-2 bg-light rounded">
                                        {{form.registrasi.nama_formulir}}
                                    </div>
                                    <br>
                                    <div class="d-block text-sm">Tanggal Tes</div>
                                    <div class="font-weight-bold">
                                        {{form.registrasi.jadwal_tes}}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
        </vue-html2pdf>
    </div>
</template>

<script>
   import VueHtml2pdf from 'vue-html2pdf'

   export default {
        name: 'Pratinjau',
        metaInfo: {
            title: 'SchoolPro',
            titleTemplate: '%s - Pratinjau'
        },
        components : {
            VueHtml2pdf
        },
        data() {
            return {
                form : {
                    registrasi : {
                        id : "",
                        status_pendaftaran : ""
                    },
                    formulir_wali : {
                        id : ""
                    },
                    formulir_pendaftaran : {
                        id : ""
                    },
                    berkas : [],
                },
                submitted: false,
                allowAcccess : false,
                fetchData : true,
                currentFlagComponent: '',
            }
        },
        created() {
            this.getData();            
        },
        methods: {
            getData: function () {
                this.fetchData = true;
                let uri = this.$apiconfig + 'website/registrasi/pratinjau';
                this.$http.get(uri, {
                    params: {
                        registrasi_id: this.$route.params.id,
                        step_users_id: this.$parent.$parent.step_register_id
                    }
                }
                ).then(res => {
                    let getResponse = res.data;

                    if(getResponse.status) {
                        this.form = getResponse;
                    }
                    
                    this.allowAcccess = getResponse.status;
                    this.fetchData = false;
                });
            },
            sendFormulir: function() {
                this.$swal({
                    icon: 'info',
                    title: 'Kirim Formulir',
                    text: 'Apakah kamu yakin ingin mengirimkan data ini?',
                    confirmButtonText: 'Kirim',
                    showCancelButton: true,
                    CancelButtonText: 'Batal',
                }).then((result) => {
                if (result.value) {
                    this.$http.post(this.$apiconfig + 'website/registrasi/send_formulir', {
                        registrasi_id   : this.$route.params.id,
                        step_users_id   : this.$parent.$parent.step_register_id
                    })
                    .then(response => {
                        if (response.data.status) {
                            this.$swal({
                                icon: 'success',
                                title: 'Berhasil',
                                text: response.data.message,
                                confirmButtonText: 'OK',
                                showCancelButton: false,
                            }).then((result) => {
                                if (result.value) {
                                    location.reload();
                                }
                            });
                        } else {
                            this.$swal({
                                icon: 'error',
                                title: 'Oops...',
                                text: response.data.message,
                            })
                        }
                    })
                    .catch(function (error) {
                        // Swal.fire('Uh oh!', 'Please check your internet connection.', 'error')
                        console.debug("Login issue: " + error)
                    });
                }
                });
            },
            generateReport () {
                this.$refs.html2Pdf.generatePdf()
            },
            cetakKartu : function() {
                this.$refs.cetak_kartu.generatePdf()
            }
        },
   }

</script>
