<template>
    <div>
        <div class="content-wizard">
            <div id="registrasiMenu" class="col-wiz-l">
                <div class="d-block h-100">

                    <div class="d-flex flex-column justify-content-between h-100">

                        <div class="flex-grow-1 p-4">
                            <div v-if="registrasiData.status">
                                <div class="card">
                                    <div class="d-flex align-items-center justify-content-between p-3">
                                        <div class="d-flex align-items-center">
                                            <div class="icon-circle icon-circle-primary-light mr-3">
                                                <span class="fe fe-user"></span>
                                            </div>
                                            <div>
                                                <div class="d-block user-wizard-sidebar font-weight-bold" data-toggle="tooltip"
                                                    data-placement="top">{{registrasiData.data.fullname}}</div>
                                                <div class="text-sm user-wizard-sidebar" data-toggle="tooltip" data-placement="top">
                                                    {{registrasiData.data.email}}</div>
                                            </div>
                                        </div>
                                        <div>
                                            <router-link to="/signout" class="btn btn-white btn-sm shadow-none">Keluar</router-link>
                                        </div>
                                    </div>
                                </div>

                                <div class="d-block mb-4">
                                    <div class="d-block mb-2 text-sm">Nomor Seleksi</div>
                                    <div class="d-block pb-2 border-bottom font-weight-bold">{{registrasiData.data.selection ? registrasiData.data.kode_registrasi : '-' }}<span class="ml-2 badge badge-primary" style="font-size: 12px;">{{registrasiData.data.kategori}}</span>
                                    </div>
                                </div>

                                <div class="d-block mb-4">
                                    <div class="d-block mb-2 text-sm">Formulir</div>
                                    <div class="d-block pb-2 border-bottom">
                                        <div class="h3 font-weight-bold d-block mb-2">{{registrasiData.data.nama_formulir}}</div>
                                        <a href="javascript: void(0)" v-on:click="viewFormulir" class="text-sm font-weight-bold">Lihat deskripsi formulir</a>
                                    </div>
                                </div>

                                <div class="d-block mb-4">
                                    <div class="d-block mb-3 text-sm">Langkah pendaftaran formulir</div>
                                    <div class="d-block">
                                        <div style="cursor:pointer" v-for="(item, index) in registrasiData.data.list_step"
                                            :key="index" class="d-flex w-100 mb-3 text-dark"
                                            v-on:click="changeStep(item.step_id, item.url, item.status, item.title)"
                                            :disabled="item.status == 'lock'">
                                            <div class="mr-2 flex-shrink-0"
                                                v-bind:class="step_register_id == item.step_id ?'':'text-muted'">{{index+1}}</div>
                                            <div class="d-flex align-items-center justify-content-between flex-grow-1 pb-2 border-bottom"
                                                v-bind:class="step_register_id == item.step_id ?'border-primary':''">
                                                <div
                                                    v-bind:class="step_register_id == item.step_id ?'font-weight-bold':'text-muted'">
                                                    {{item.title}}
                                                </div>
                                                <div v-if="step_register_id == item.step_id && item.finished == '0'"
                                                    class="fe fe-chevron-right"></div>
                                                <div v-if="item.status == 'lock'" class="fe fe-lock"></div>

                                                <div v-if="item.status == 'open' && item.finished == '1'">
                                                    <div class="text-primary text-sm"><span
                                                            class="fe fe-check-circle mr-2"></span>{{item.text_finished}}</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div v-if="$beta_cicilan && registrasiData.data.status_cicilan == 1 && registrasiData.data.status_registrasi == 'LOLOS'" style="cursor:pointer" class="d-flex w-100 mb-3 text-dark" v-on:click="openStep()">
                                            <div class="mr-2 flex-shrink-0">5</div>
                                            <div class="d-flex align-items-center justify-content-between flex-grow-1 pb-2 border-bottom border-primary">
                                                <div class="font-weight-bold">
                                                    Cicilan 
                                                </div>
                                                <div>
                                                    <div class="text-sm">{{ registrasiData.data.count_cicilan }}/{{ registrasiData.data.list_cicilan.length }}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <router-link to="/calon-siswa/formulir" class="btn btn-link p-0 mt-4 btn-sm"><span
                                    class="fe fe-user mr-2"></span>Ke Akun Saya</router-link>

                            <div class="d-none p-3 bg-light rounded mt-4">
                                <div class="d-block text-sm">
                                    <div class="d-block font-weight-bold">Menemukan masalah?</div>
                                    <div class="d-block">Hubungi tim handal kami <a href="#">disini</a></div>
                                </div>
                                <div class="d-block pt-4">
                                    <div class="d-block text-sm text-muted">Scola 2021. All Right Reserved</div>
                                </div>
                            </div>
                        </div>

                        <!-- Button Menu -->
                        <div class="show-mobile">
                            <div class="btn-close-menu-registrasi">
                                <button v-on:click="hideRegistrasiMenu" type="button" class="btn btn-white bg-white btn-block">
                                    <div class="d-flex align-items-center justify-content-center">
                                        <span class="fe fe-x mr-2"></span>Tutup Menu
                                    </div>
                                </button>
                            </div>
                        </div>

                    </div>
                    

                </div>
            </div>

            <keep-alive>
                <component :is="currentRegistrasiComponent"></component>
            </keep-alive>
        </div>

        <!-- Button Menu -->
        <div class="show-mobile">
            <div class="btn-menu-registrasi">
                <button v-on:click="showRegistrasiMenu" type="button" class="btn btn-outline-primary bg-white btn-block shadow">
                    <div class="d-flex align-items-center justify-content-center">
                        <span class="fe fe-menu mr-2"></span>Lihat Menu
                    </div>
                </button>
            </div>
        </div>

    </div>

</template>

<script>
    import pembayaran from "@/components/siswa/registrasi/pembayaran.vue"
    import pendaftaran from "@/components/siswa/registrasi/pendaftaran.vue"
    import DetailFormulir from "@/components/siswa/registrasi/DetailFormulir.vue"
    import seleksi from "@/components/siswa/registrasi/seleksi.vue"
    import tinjauan from "@/components/siswa/registrasi/tinjauan.vue"
    import LaporDiri from "@/components/siswa/registrasi/LaporDiri.vue"
    import Cicilan from "@/components/siswa/registrasi/Cicilan.vue"

    export default {
        components: {
            pembayaran,
            pendaftaran,
            DetailFormulir,
            seleksi,
            tinjauan,
            LaporDiri,
            Cicilan
        },
        data() {
            return {
                search: "",
                pageOfItems: [],
                registrasiData: [],
                fetchRegistrasi: true,
                step_register_id: "",
                currentRegistrasiComponent: "",
                step_title : ''
            }
        },
        created() {
            this.getData();
        },
        methods: {
            getData: function () {
                this.fetchRegistrasi = true;
                let uri = this.$apiconfig + 'website/registrasi/detail_registrasi';
                this.$http.get(uri, {
                    params: {
                        registrasi_id: this.$route.params.id
                    }
                }).then(res => {
                    let getResponse = res.data;

                    if (getResponse.status) {
                        this.registrasiData = getResponse;
                        this.step_register_id = this.registrasiData.data.current_position;
                        this.step_title = this.registrasiData.data.current_position_title;

                        this.changeStep(this.step_register_id, this.registrasiData.data.current_position_url, 'open', this.step_title);
                    } else {
                        alert("Data tidak ditemukan");
                        this.$router.push('/calon-siswa/formulir');
                    }

                    this.fetchRegistrasi = false;
                });
            },
            changeStep: function (step_id, url, status = 'open', title) {
                if (status == 'open') {
                    this.step_register_id = step_id;
                    this.currentRegistrasiComponent = url;
                    this.step_title = title;
                    this.hideRegistrasiMenu();
                }
            },
            openStep: function() {
                this.currentRegistrasiComponent = Cicilan;
            },
            viewFormulir: function() {
                this.currentRegistrasiComponent = 'DetailFormulir';
                this.hideRegistrasiMenu();
            },
            showRegistrasiMenu: function() {
                const registrasi_menu = document.getElementById("registrasiMenu");
                registrasi_menu.classList.add("active");
            },  
            hideRegistrasiMenu: function() {
                const registrasi_menu = document.getElementById("registrasiMenu");
                registrasi_menu.classList.remove("active");
            }         
        }
    }
</script>