<template>
    <div class="col-wiz-r">
        <div class="d-block py-lg-4 py-3 px-lg-5 px-md-4 px-3">

            <div class="show-mobile pb-3 mb-4 border-bottom">
                <div class="row">
                    <div class="col-6">
                        <small>Formulir</small>
                        <div class="d-block font-weight-bold">{{this.$parent.registrasiData.data.nama_formulir}}</div>
                    </div>
                    <div class="col-6">
                        <small>Nomor Seleksi</small>
                        <div class="d-block font-weight-bold">{{this.$parent.registrasiData.data.kode_registrasi}}</div>
                    </div>
                </div>
            </div>

            <div class="d-block pb-3 mb-4 border-bottom">
                <div class="d-block mb-2 text-sm">Tahap</div>
                <div class="d-block">
                    <h1 class="m-0">{{this.$parent.step_title}}</h1>
                </div>
            </div>

            <div class="d-block col-md-12" v-if="fetchData">
                <div class="row">
                    <p>Sedang mengambil data...</p>
                </div>
            </div>

            <div v-if="!fetchData">
                <div class="d-block mb-3">
                    <div class="row">
                        <div class="mb-3" v-if="listData.data.status_pembayaran" :class="listData.data.status_registrasi ? 'col-md-6':'col-md-12'">
                            <div class="card m-0 h-100">
                                <div class="card-body h-100">
                                    <div class="d-flex flex-column h-100">
                                        <div class="d-block mb-4 pb-3 border-bottom">{{listData.data.pembayaran.title}}</div>
                                        <div class="d-block mb-3">
                                            <div class="text-sm d-block mb-1">Tipe</div>
                                            <div class="font-weight-bold text-md" v-if="listData.data.pembayaran.metode_pembayaran == 'manual'">External Payment</div>
                                            <div class="font-weight-bold text-md" v-if="listData.data.pembayaran.metode_pembayaran != 'manual'">Payment Gateway</div>
                                        </div>

                                        <div class="d-block">
                                            <div class="text-sm d-block mb-2">Status</div>
                                            <div class="icon-circle icon-circle-primary">
                                                <span class="fe fe-check-circle"></span>
                                            </div>
                                            <div class="font-weight-bold text-md text-primary" v-if="listData.data.pembayaran.status == 'settlement'">Sudah Bayar</div>
                                            <div class="text-sm text-primary">{{listData.data.pembayaran.tanggal_pembayaran}}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-if="listData.data.status_registrasi" class="mb-3" :class="listData.data.status_pembayaran ? 'col-md-6':'col-md-12'">
                            <div class="card m-0 h-100">
                                <div class="card-body h-100">
                                    <div class="d-flex justify-content-between flex-column h-100">
                                        <div class="flex-grow-1">
                                            <div class="d-block mb-4 pb-3 border-bottom">{{listData.data.registrasi.title}}</div>
                                            <div class="d-block mb-3">
                                                <div class="text-sm d-block mb-2">Status</div>
                                                <div class="icon-circle icon-circle-primary">
                                                    <span class="fe fe-check-circle"></span>
                                                </div>
                                                <div class="font-weight-bold text-md text-primary">Sudah Terisi</div>
                                                <div class="text-sm text-primary">{{this.$parent.registrasiData.data.updated_at}}</div>
                                            </div>
                                        </div>
                                        
                                        <div class="d-block">                                            
                                            <button type="button" v-on:click="overviewData(listData.data.registrasi.id, listData.data.registrasi.url , listData.data.registrasi.title)" class="btn btn-white btn-block">Lihat Formulir<span class="fe fe-file ml-2"></span></button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Lapor Diri -->
                <div class="d-block">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="card">
                               <div class="d-block px-lg-6 py-lg-6 py-md-5 px-md-5">
                                    <div class="d-flex d-flex-mobile">
                                        <div class="mr-md-4 mr-0 mb-md-0 mb-4">
                                            <!-- JIKA STATUS APPLIED OR WAITING OR PENDING -->
                                            <img v-if="this.$parent.registrasiData.data.last_step.status_registration != 'rejected' && this.$parent.registrasiData.data.last_step.status_registration != 're-check'" src="https://scolacdn.com/frontend/argonne-img/illustration-ppdb-1.png" width="160">
                                            <!-- JIKA STATUS REJECTED -->
                                            <img v-if="this.$parent.registrasiData.data.last_step.status_registration == 'rejected'" src="https://assets.scola.id/images/forbiden-2.png" width="160">
                                            <!-- JIKA STATUS RE-CHECK -->
                                            <img v-if="this.$parent.registrasiData.data.last_step.status_registration == 're-check'" src="https://assets.scola.id/images/owl-1.png" width="160">
                                        </div>
                                        <div>
                                            <div class="d-block">
                                                <!-- JIKA STATUS PENDING OR WAITING -->
                                                <div v-if="this.$parent.registrasiData.data.last_step.status_registration == 'pending' || this.$parent.registrasiData.data.last_step.status_registration == 'waiting'">
                                                    <div class="h1 mb-3">{{this.$parent.step_title}}</div>
                                                    <div class="d-block" v-html="this.$parent.registrasiData.data.informasi_reg_ulang"></div>
                                                </div>
                                                <!-- JIKA STATUS RE-CHECK -->
                                                <div v-if="this.$parent.registrasiData.data.last_step.status_registration == 're-check'">
                                                    <div class="h1 mb-3">Periksa Kembali data {{this.$parent.step_title}} anda.</div>
                                                    <div class="d-block" v-html="this.$parent.registrasiData.data.log_register.message"></div>
                                                </div>
                                                <!-- JIKA STATUS APPLIED -->
                                                <div v-if="this.$parent.registrasiData.data.last_step.status_registration == 'applied'">
                                                    <div class="h1 mb-3">Terima kasih, Anda telah menyelesaikan proses pendaftaran penerimaan calon peserta baru.</div>
                                                    <div class="d-block" v-html="this.$parent.registrasiData.data.lolos_reg_ulang"></div>
                                                </div>
                                                <!-- JIKA STATUS REJECTED -->
                                                <div v-if="this.$parent.registrasiData.data.last_step.status_registration == 'rejected'">
                                                    <div class="h1 mb-3">Anda tidak lolos tahap akhir proses pendaftaran penerimaan calon peserta baru.</div>
                                                    <div class="d-block" v-html="this.$parent.registrasiData.data.tidak_lolos_reg_ulang"></div>
                                                </div>
                                            </div>
                                            <div v-if="this.$parent.registrasiData.data.lapor_deskripsi != null" class="px-4 shadow-sm py-3 mb-4 border shadow m-0 rounded d-flex-mobile">
                                                <div class="d-block">
                                                    <div class="m-0 d-block" v-html="this.$parent.registrasiData.data.lapor_deskripsi"></div>
                                                </div>
                                                <div v-if="this.$parent.registrasiData.data.lapor_file != null" class="d-block">                                            
                                                    <a :href="this.$parent.registrasiData.data.lapor_file" target="_blank" type="button" class="btn btn-white btn-block">Lihat Lampiran<span class="fe fe-file ml-2"></span></a>
                                                </div>
                                            </div>
                                            <div class="d-block mt-4">
                                                <button v-on:click="laporDiri" type="button" class="btn btn-primary btn-lg">{{this.$parent.step_title}} <span class="fe fe-chevron-right ml-2"></span></button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- -->

        </div>
    </div>
</template>

<script>
    export default {
        name: 'Tinjauan',
        metaInfo: {
            title: 'SchoolPro',
            titleTemplate: '%s - Tinjauan'
        },
        data() {
            return {
                search: "",
                listData: {
                    data : {
                        status_pembayaran : false,
                        status_registrasi : false,
                    }
                },
                fetchData: true,
            }
        },
        created() {
            this.getData();
        },
        methods : {
            getData: function () {
                this.fetchData = true;

                let uri = this.$apiconfig + 'website/registrasi/check_overview';
                this.$http.get(uri, {
                    params: {
                        registrasi_id: this.$route.params.id,
                        step_users_id: this.$parent.step_register_id
                    }
                }).then(res => {
                    let getResponse = res.data;
                    this.listData = getResponse;

                    this.fetchData = false;
                });
            },
            overviewData: function(id, url , title) {
                this.$parent.changeStep(id,url,'open',title);
            },
            laporDiri : function() {
                this.$parent.changeStep(this.$parent.step_register_id,'LaporDiri','open','Lapor Diri');
            }
        }
    }
</script>
