<template>
    <div class="d-block">
        <div v-if="fetchData">
            <div class="d-block px-6 py-6 text-center rounded animate-load-1" style="background-color:#dee4ec;">
                <img src="https://scolacdn.com/frontend/argonne-img/owly-loading.png" width="180" class="mx-auto mb-4">
                <p>Sedang mengambil data. Mohon tunggu...</p>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12" v-if="!fetchData">
                <div class="card" v-if="!allowAcccess">
                    <div class="card-body">
                        <p>Kamu belum diperbolehkan mengakses halaman ini</p>
                    </div>
                </div>

                <div class="card card-full-mobile card-full-mobile-px3" v-if="allowAcccess">
                    
                    <form>
                        <div class="card-body">
                            <div class="d-block border-bottom pb-3 text-muted">
                                Isian Data Berkas
                            </div>

                            <div class="px-lg-6 py-lg-4">
                                <input type="text" class="d-none form-control" :class="{ 'is-invalid': submitted && $v.form.registrasi_id.$error }" v-model="form.registrasi_id" >
                                <div v-if="submitted && !$v.form.registrasi_id.required" class="invalid-feedback"> Data Wajib Diisi</div>
                                
                                <div class="d-flex" v-for="(item, index) in form.list_file" :key="index">
                                    <div style="width: 30px;" class="flex-shrink-0">{{index+1}}.</div>
                                    <div class="form-group mb-4 border-bottom pb-4 w-100">
                                        <label class="font-weight-bold">
                                            {{item.label_name}}                                
                                            <span v-if="item.required == '1'" class="text-danger">(Wajib Diisi) *</span>
                                        </label>

                                        <div class="custom-file" >
                                            <input type="file" :name="'file_attachment'" style="cursor:pointer !important" class="custom-file-input" id="inputGroupFile01" aria-describedby="inputGroupFileAddon01" @change="previewFiles($event,item.id)" accept="image/x-png,image/gif,image/jpeg">
                                            <label class="custom-file-label" for="inputGroupFile01">Choose file</label>
                                        </div>
                                        
                                        <div v-if="item.note" class="text-sm d-block py-2 px-3 bg-warning mt-3 rounded font-weight-bold">
                                            {{item.note}}
                                        </div>

                                        <div class="d-none mt-3" :id="'file-uploaded-section-'+item.id">
                                            <div class="mb-3">
                                                <div class="file-uploaded-area" :id="'file-uploaded-area-'+item.id"></div>
                                            </div>
                                        </div>
                                        
                                        <div class="mt-3" :id="'file-uploaded-section-before-'+item.id" v-bind:class = "item.formulir_berkas_id ?'':'d-none'">
                                            <div class="mb-3">
                                                <div class="file-uploaded-area" v-bind:style="{ 'background-image': 'url(' + item.file_attachment + ')' }"></div>
                                            </div>
                                        </div>
                                        <progress class="d-none" :id="'upload-bar-'+item.id" max="100" :value.prop="uploadPercentage" style="width:100%"></progress>
                                        <button :id="'upload-button-'+item.id" v-on:click="uploadFileAttachment(item.id)" type="button" class="btn btn-primary mt-3 d-none"><i class="fe fe-upload"></i> Upload</button>
                                        <button disabled :id="'progress-button-'+item.id" type="button" class="btn btn-primary mt-3 d-none">Uploading {{uploadPercentage}}%...</button>
                                    </div>
                                </div>

                                <div class="d-flex justify-content-end pt-4 d-none">
                                    <progress v-if="uploadFile" max="100" :value.prop="uploadPercentage" style="width:100%"></progress>
                                </div>


                            </div>

                            <div class="d-flex justify-content-end pt-4 border-top">
                                <button type="button" class="btn btn-primary px-5" v-on:click="nextStep()">
                                    Selanjutnya <span class="fe fe-arrow-right ml-2"></span>
                                </button>
                            </div>
                            <div class="d-flex justify-content-end pt-4 d-none border-top" v-if="uploadFile">
                                <button type="button" class="btn btn-primary px-5" disabled>
                                    Uploading {{uploadPercentage}}%...
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {required} from "vuelidate/lib/validators";

    export default {
        name: 'DataBerkas',
        metaInfo: {
            title: 'SchoolPro',
            titleTemplate: '%s - Data Berkas'
        },
        data() {
            return {
                form :[],
                submitted: false,
                allowAcccess : false,
                fetchData : true,
                file_attachment : [] ,
                uploadPercentage: 0,
                uploadFile: false
            }
        },
        validations: {
            form : {
                registrasi_id: {
                    required
                }
            }
        },
        created() {
            this.getData();     
        },
        methods : {
            getData: function () {
                this.fetchData = true;
                let uri = this.$apiconfig + 'website/registrasi/check_berkas';
                this.$http.get(uri, {
                    params: {
                        registrasi_id: this.$route.params.id
                    }
                }
                ).then(res => {
                    let getResponse = res.data;

                    if(getResponse.status) {
                        this.form = getResponse;
                        this.form.registrasi_id = this.$route.params.id
                    }
                    
                    this.allowAcccess = getResponse.status;
                    this.fetchData = false;
                });
            },
            previewFiles(e, id) {
                const file = e.target.files[0];                
                var fsize = ((file.size/1024)/1024).toFixed(4); // MB
                
                if(fsize >= 5) {
                    alert("File yang di upload tidak boleh lebih 5 MB");
                } else {

                    this.preview_image = URL.createObjectURL(file);
                    const preview_area = document.getElementById("file-uploaded-section-"+id);
                    const prebview_before = document.getElementById("file-uploaded-section-before-"+id);
                    const preview_area_image = document.getElementById("file-uploaded-area-"+id);
                    const upload_button = document.getElementById("upload-button-"+id);

                    preview_area.classList.remove("d-none");
                    upload_button.classList.remove("d-none");
                    prebview_before.classList.add("d-none");
                    preview_area_image.style.backgroundImage = "url('"+this.preview_image+"')";

                    this.file_attachment[id] = file;
                }
            },
            uploadFileAttachment : function(id) {
                const upload_bar = document.getElementById("upload-bar-"+id);
                const progress_button = document.getElementById("progress-button-"+id);
                const upload_button = document.getElementById("upload-button-"+id);

                upload_button.classList.add("d-none");
                upload_bar.classList.remove("d-none");
                progress_button.classList.remove("d-none");

                // const url_api   = 'https://victoria-worker.edmission.id/';
                const callback  = this.$apiconfig + 'website/registrasi/save_pendaftaran';
                let formData    = new FormData();

                formData.append('file[]', this.file_attachment[id]);
                formData.append('file_path', "registrasi/berkaspendaftaran/"+this.$route.params.id+"_"+id+"_");
                formData.append('callback', callback);

                this.$http.post(this.$api_upload_engine, formData , {
                    onUploadProgress: function(progressEvent) {
                        this.uploadPercentage = Math.round( (progressEvent.loaded * 100) / progressEvent.total )
                    }.bind(this)
                })
                .then(response => {
                    const res       = response.data;

                    if (res.status && res.result != "") {
                        this.$http.post(this.$apiconfig + 'website/registrasi/save_berkas', {
                            registrasi_id   : this.$route.params.id,
                            setup_berkas_id : id,
                            file_attachment : res.result[0]['path']
                        })
                        .then(response => {
                            if (response.data.status) {
                                this.$swal({
                                    icon: 'success',
                                    title: 'Berhasil',
                                    text: response.data.message,
                                    confirmButtonText: 'OK',
                                    showCancelButton: false,
                                }).then((result) => {
                                    if (result.value) {
                                        this.getData();
                                    }
                                });
                            } else {
                                this.$swal({
                                    icon: 'error',
                                    title: 'Oops...',
                                    text: response.data.message,
                                })
                            }
                        });
                    } else {
                        this.$swal({
                            icon: 'error',
                            title: 'Oops...',
                            text: 'Gagal mengunggah file, coba lagi nanti!',
                        })
                    }

                    upload_bar.classList.add("d-none");
                    progress_button.classList.add("d-none");
                    upload_button.classList.remove("d-none");
                });
            },
            nextStep : function() {
                var statusUpload = true;
                this.form.list_file.forEach(element => {
                    if(element.required == '1') {
                        if(!element.file_attachment) {
                            statusUpload = false;
                            return false;
                        }
                    }
                });

                if(!statusUpload) {
                    this.$swal({
                        icon: 'warning',
                        title: 'Ups!',
                        text: 'Harap lengkapi form upload',
                    });
                    return;
                } else {
                    this.$swal({
                        icon: 'success',
                        title: 'Berhasil',
                        text: "File berkas sudah terlengkapi",
                        confirmButtonText: 'OK',
                        showCancelButton: false,
                    }).then((result) => {
                        if (result.value) {
                            this.$parent.getStepPendaftaran();
                            this.$parent.changeTab('Pratinjau');
                        }
                    });
                }
            }
        }
   }
</script>