<template>
    <!-- Component Pembayaran -->
    <div class="col-wiz-r">
        <div class="d-block py-lg-4 py-3 px-lg-5 px-md-4 px-3">

            <div class="show-mobile pb-3 mb-4 border-bottom">
                <div class="row">
                    <div class="col-6">
                        <small>Formulir</small>
                        <div class="d-block font-weight-bold">{{this.$parent.registrasiData.data.nama_formulir}}</div>
                    </div>
                    <div class="col-6">
                        <small>Nomor Seleksi</small>
                        <div class="d-block font-weight-bold">{{this.$parent.registrasiData.data.kode_registrasi}}</div>
                    </div>
                </div>
            </div>

            <div class="d-block pb-3 mb-4 border-bottom">
                <div class="d-block mb-2 text-sm">Tahap</div>
                <div class="d-block">
                    <h1 class="m-0">Registrasi {{this.$parent.step_title}}</h1>
                </div>
            </div>

            <div v-if="fetchData">
                <div class="d-block px-6 py-6 text-center rounded animate-load-1" style="background-color:#dee4ec;">
                    <img src="https://scolacdn.com/frontend/argonne-img/owly-loading.png" width="180" class="mx-auto mb-4">
                    <p>Sedang mengambil data. Mohon tunggu...</p>
                </div>
            </div>

            <div v-if="!fetchData">
                <!-- Re check -->
                <div class="d-block" v-if="listData.data.status_pembayaran == 're-check'">
                    <div class="d-block bg-light rounded px-4 py-3 mb-4 border shadow" v-if="listData.data.show_notes">
                        <div class="d-flex">
                            <div class="flex-shrink-0 mr-4">
                                <img src="https://assets.scola.id/images/file-archived.svg" width="100">
                            </div>
                            <div>
                                <div class="h4">Catatan</div>
                                <div class="d-block ">
                                    {{listData.data.message}}
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

                <div v-if="!listData.status" class="d-block">
                    <div class="row">
                        
                        <div class="col-md-8 mb-3">
                            <form v-on:submit.prevent="handleSubmit">
                                <div class="card m-0">
                                    <div class="d-block p-lg-5 p-md-4">
                                        <h2>{{this.$parent.step_title}}</h2>
                                        <div class="d-flex justify-content-between p-3 rounded bg-light mb-3">
                                            <div class="mr-3">
                                                <div class="d-block text-sm mb-1">Nama Formulir</div>
                                                <div class="d-block font-weight-bold">{{this.$parent.registrasiData.data.nama_formulir}}</div>
                                            </div>
                                            <div class="text-right">
                                                <div class="/d-block text-sm mb-1">&nbsp;</div>
                                                <div class="d-block font-weight-bold">{{this.$parent.registrasiData.data.harga_formulir}}</div>
                                            </div>
                                        </div>


                                        <input type="hidden" class="form-control"
                                            :class="{ 'is-invalid': submitted && $v.registrasi_id.$error }" v-model="registrasi_id"
                                            placeholder="Masukan Nama Lengkap">
                                        <div v-if="submitted && !$v.registrasi_id.required" class="invalid-feedback">Nama Lengkap
                                            tidak boleh kosong</div>

                                        <div class="mt-4 pb-2 border-bottom mb-4">
                                            <div class="d-block font-weight-bold mb-2">Informasi Pembayaran</div>
                                            <div v-if="text_external_gateway_information" v-html="text_external_gateway_information"></div>
                                            <div v-if="!text_external_gateway_information">
                                                <span class="text-muted text-sm">Tidak ada informasi pembayaran</span>
                                            </div>
                                        </div>

                                        <div class="d-block">
                                            <div class="d-block mb-2">
                                                Mohon untuk mengunggah bukti transfer/bayar dengan menekan tombol dibawah.
                                            </div>

                                            <progress v-if="uploadFile" max="100" :value.prop="uploadPercentage" style="width:100%"></progress>

                                            <input class="d-none" type="file" name="file_attachment" id="file_attachment" @change="previewFiles" accept="image/x-png,image/gif,image/jpeg">
                                            
                                            <button v-on:click="chooseFile()" type="button" class="btn btn-outline-primary btn-block mb-2" v-if="!uploadFile">
                                                <span v-if="listData.status">Unggah Ulang</span>
                                                <span v-if="!listData.status">Unggah Bukti Transfer</span>
                                            </button>

                                            <button v-if="!uploadFile" type="submit" class="btn btn-primary btn-block mb-2" :disabled="!preview_image" id="btnSend">Kirim Bukti Transfer</button>
                                            <button v-if="uploadFile" type="button" class="btn btn-primary btn-block mb-2" :disabled="true" >Uploading {{uploadPercentage}}%...</button>
                                            <div class="d-block text-sm text-muted">Format berkas JPG/JPEG/PNG, tidak lebih dari 5 MB</div>
                                        </div>

                                        <!-- Informasi data sudah diunggah -->

                                        <div class="d-block mt-3" v-if="preview_image">
                                            <div class="d-block mb-3">
                                                <div class="file-uploaded-area" v-bind:style="{ 'background-image': 'url(' + preview_image+ ')' }"></div>
                                            </div>
                                        </div>


                                    </div>
                                </div>
                            </form>
                        </div>

                        <div v-if="listData.data.status_pembayaran" class="col-md-4 mb-3">
                            <div class="card m-0">
                                <div class="d-block p-4 h-100">
                                    <h2>Log {{this.$parent.step_title}}</h2>
                                    
                                    <div v-for="(item,index) in listData.data.log" :key="index" class="mt-4 d-flex align-items-center justify-content-between pb-2 border-bottom mb-4">
                                        <div><b>Pembayaran Sebelumnya<br/><small>{{item.tanggal_pembayaran}}</small></b></div>
                                        <div><a :href="item.bukti_transfer" target="blank">Lihat</a></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div v-if="listData.status" class="d-block">
                    <div class="row">
                        <div class="col-md-6 mb-3">
                            <div class="card h-100 m-0">
                                <div class="d-block p-4 h-100">
                                    <div v-if="listData.data.status == 'applied'">
                                        <div class="d-flex align-items-center mb-4">
                                            <div class="icon-circle icon-circle-success mr-3">
                                                <span class="fe fe-check-circle text-white"></span>
                                            </div>
                                            <div>
                                                <h3 class="m-0">
                                                    Unggahan terkirim!
                                                </h3>
                                            </div>
                                        </div>
                                    </div>
                                                                        
                                     <div v-if="listData.data.status == 'waiting'">
                                        <div class="d-flex align-items-center mb-4">
                                            <div class="icon-circle mr-3 flex-shrink-0">
                                                <span class="fe fe-zap"></span>
                                            </div>
                                            <div>
                                                <h3 class="m-0">
                                                   {{this.$parent.step_title}} sedang diverifikasi...
                                                </h3>
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <div v-if="listData.data.status == 'rejected'">
                                        <div class="d-block mb-3">
                                            <div class="d-block text-sm">Status</div>
                                            <div class="d-block text-danger text-md">{{this.$parent.step_title}} di tolak</div>
                                        </div>
                                        <div class="d-block pl-3 border-left mb-4">
                                            {{listData.data.message}}
                                        </div>
                                        <div class="d-block text-sm text-muted">Hubungi Admin untuk informasi lebih lanjut</div>
                                    </div>

                                    <div v-if="listData.data.status == 'pending' || listData.data.status == 'waiting'">
                                        <div class="d-block mb-3">
                                            <div class="d-block text-sm">Status</div>
                                            <div class="d-block text-warning text-md">Menunggu Status Verifikasi {{this.$parent.step_title}}</div>
                                        </div>
                                        <div class="d-block pl-3 border-left mb-4">
                                            Mohon tunggu hingga {{this.$parent.step_title}} dikonfirmasi.
                                        </div>
                                        <!-- <div class="d-block text-sm text-muted">Proses konfirmasi akan memakan waktu 1x24 jam selama jam operasional</div> -->
                                    </div>

                                    <div v-if="listData.data.status == 'applied'">
                                        <div class="d-block mb-3">
                                            <div class="d-block text-sm">Status</div>
                                            <div class="d-block text-success text-md">Lunas</div>
                                        </div>
                                        <div class="d-block pl-3 border-left mb-4">
                                            Terima kasih biaya {{this.$parent.step_title}} telah diterima.
                                        </div>
                                        <div class="d-block text-sm text-muted">Silakan lanjut ke tahap berikutnya..</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <modal name="modal-info-payment" height="auto" :scrollable="true">
            <div class="modal-content">
                <div class="modal-card">
                    <div class="card-header">
                        <h4 class="card-header-title">
                            Informasi Pembayaran
                        </h4>
                    </div>
                    <div class="card-body">
                        <div class="" v-html="text_external_gateway_information"></div>
                    </div>
                    <div class="card-footer border-top-0">
                        <ul class="pagination mb-0"></ul>
                        <div class="d-flex justify-content-end">
                            <a href="javascript: void(0)" v-on:click="infoPayment('close')" class="btn btn-white d-none d-md-inline-block"> Tutup</a>
                        </div>
                    </div>
                </div>
            </div>
        </modal>
    </div>
</template>

<script>
import {required} from "vuelidate/lib/validators";
export default {
    name: 'Pembayaran',
    metaInfo: {
        title: 'SchoolPro',
        titleTemplate: '%s - Pembayaran'
    },
    validations: {
        registrasi_id: {
            required
        },
    },
    data() {
        return {
            fetchData        : true,
            listData         : [],
            registrasi_id    : this.$parent.registrasiData.data.registrasi_id,
            submitted        : false,
            files            : [],
            preview_image    : "",
            file_attachment  : "",
            text_external_gateway_information : this.$parent.registrasiData.data.external_gateway_information,
            uploadPercentage : 0,
            uploadFile       : false
        }
    },
    created() {
        this.getData();
    },
    methods: {
        chooseFile: function () {
            document.getElementById("file_attachment").click()                
        },
        previewFiles(e) {
            const file = e.target.files[0];                
            var fsize = ((file.size/1024)/1024).toFixed(4); // MB
            
            if(fsize >= 5) {
                alert("File yang di upload tidak boleh lebih 5 MB");
            } else {
                this.preview_image = URL.createObjectURL(file);
                this.file_attachment = file;
            }
        },
        infoPayment: function(params="") {
            if(params == 'open') {
                this.$modal.show('modal-info-payment');
            } else {
                this.$modal.hide('modal-info-payment');
            }
        },
        getData: function () {
            this.fetchData = true;

            let uri = this.$apiconfig + 'website/registrasi/check_payment';
            this.$http.get(uri, {
                params: {
                    registrasi_id: this.$parent.registrasiData.data.registrasi_id,
                    step_users_id: this.$parent.step_register_id
                }
            }).then(res => {
                let getResponse = res.data;
                this.listData = getResponse;
                this.fetchData = false;
            });
        },
        handleSubmit() {
            this.submitted = true;
            this.$v.$touch();
            if (this.$v.$invalid) {
                this.$swal({
                    icon: 'error',
                    title: 'Ups!',
                    text: 'Harap masukan file bukti pembayaran',
                });
                return;
            } else {
                this.uploadFile = true;
                
                // const url_api   = 'https://victoria-worker.edmission.id/';
                const callback  = this.$apiconfig + 'website/registrasi/submit_payment';
                let formData    = new FormData();
                
                formData.append('file[]', this.file_attachment);
                formData.append('file_path', "buktipembayaran/"+this.$route.params.id+"_");
                formData.append('callback', callback);

                this.$http.post(this.$api_upload_engine, formData , {
                    onUploadProgress: function(progressEvent) {
                        this.uploadPercentage = Math.round( (progressEvent.loaded * 100) / progressEvent.total )
                    }.bind(this)
                })
                .then(response => {
                    this.uploadFile = false;
                    const res       = response.data;

                    if (res.status && res.result != "") {
                        this.$http.post(this.$apiconfig + 'website/registrasi/submit_payment', {
                            registrasi_id   : this.$route.params.id,
                            step_users_id   : this.$parent.step_register_id,
                            files           : JSON.stringify(res.result)
                        })
                        .then(response => {
                            if (response.data.status) {
                                this.$swal({
                                    icon: 'success',
                                    title: 'Berhasil',
                                    text: response.data.message,
                                    confirmButtonText: 'OK',
                                    showCancelButton: false,
                                }).then((result) => {
                                    if (result.value) {
                                        this.getData();
                                    }
                                });
                            } else {
                                this.$swal({
                                    icon: 'error',
                                    title: 'Oops...',
                                    text: response.data.message,
                                })
                            }
                        })
                    } else {
                        this.$swal({
                            icon: 'error',
                            title: 'Oops...',
                            text: 'Gagal mengunggah file, coba lagi nanti!',
                        })
                    }
                });
            }
        },
    }
}
</script>