<template>
    <div class="d-block">
        <div v-if="fetchData">
            <div class="d-block px-6 py-6 text-center rounded animate-load-1" style="background-color:#dee4ec;">
                <img src="https://scolacdn.com/frontend/argonne-img/owly-loading.png" width="180" class="mx-auto mb-4">
                <p>Sedang mengambil data. Mohon tunggu...</p>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12" v-if="!fetchData">
                <div class="card" v-if="!allowAcccess">
                    <div class="card-body">
                        <p>Kamu belum diperbolehkan mengakses halaman ini</p>
                    </div>
                </div>
                <div class="card card-full-mobile card-full-mobile-px3" v-if="allowAcccess">
                    <form v-on:submit.prevent="handleSubmit">
                        <div class="card-body">
                            <div class="d-block border-bottom pb-3 text-muted">
                                Isian Data Pendaftar
                            </div>
                            <div class="px-lg-6 py-lg-4">
                                <div class="form-group mb-4">
                                    <!-- <label class="font-weight-bold">Nomor Seleksi <span class="text-danger">*</span></label> -->
                                    <input type="hidden" class="form-control disabled" disabled
                                        v-model="form.kode_registrasi">
                                </div>
                                <div class="form-group mb-4">
                                    <label class="font-weight-bold">Nama Lengkap Bakal Calon Peserta (Sesuai Akte Lahir) <span
                                            class="text-danger">*</span></label>
                                    <input type="text" class="form-control"
                                        :class="{ 'is-invalid': submitted && $v.form.nama_lengkap.$error }"
                                        v-model="form.nama_lengkap" placeholder="Masukan Nama">
                                    <div v-if="submitted && !$v.form.nama_lengkap.required" class="invalid-feedback"> Nama
                                        Wajib Diisi</div>
                                </div>
                                <div class="form-group mb-4">
                                    <label class="font-weight-bold">Jenis Kelamin<span class="text-danger">*</span></label>
                                    <div class="d-flex">
                                        <div class="mr-3">
                                            <div class="pretty p-svg p-curve">
                                                <input type="radio" name="jenis_kelamin" value="L"
                                                    v-model="form.jenis_kelamin" :checked="form.jenis_kelamin == 'L'" />
                                                <div class="state p-primary">
                                                    <svg class="svg svg-icon" viewBox="0 0 20 20">
                                                        <path
                                                            d="M7.629,14.566c0.125,0.125,0.291,0.188,0.456,0.188c0.164,0,0.329-0.062,0.456-0.188l8.219-8.221c0.252-0.252,0.252-0.659,0-0.911c-0.252-0.252-0.659-0.252-0.911,0l-7.764,7.763L4.152,9.267c-0.252-0.251-0.66-0.251-0.911,0c-0.252,0.252-0.252,0.66,0,0.911L7.629,14.566z"
                                                            style="stroke: white;fill:white;"></path>
                                                    </svg>
                                                    <label class="font-weight-bold">Laki-laki</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            <div class="pretty p-svg p-curve">
                                                <input type="radio" name="jenis_kelamin" value="P"
                                                    v-model="form.jenis_kelamin" :checked="form.jenis_kelamin == 'P'" />
                                                <div class="state p-primary">
                                                    <svg class="svg svg-icon" viewBox="0 0 20 20">
                                                        <path
                                                            d="M7.629,14.566c0.125,0.125,0.291,0.188,0.456,0.188c0.164,0,0.329-0.062,0.456-0.188l8.219-8.221c0.252-0.252,0.252-0.659,0-0.911c-0.252-0.252-0.659-0.252-0.911,0l-7.764,7.763L4.152,9.267c-0.252-0.251-0.66-0.251-0.911,0c-0.252,0.252-0.252,0.66,0,0.911L7.629,14.566z"
                                                            style="stroke: white;fill:white;"></path>
                                                    </svg>
                                                    <label class="font-weight-bold">Perempuan</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="form-group mb-4">
                                            <label class="font-weight-bold">Tempat Lahir <span
                                                    class="text-danger">*</span></label>
                                            <input type="text" class="form-control"
                                                :class="{ 'is-invalid': submitted && $v.form.tempat_lahir.$error }"
                                                v-model="form.tempat_lahir" placeholder="Masukan Data">
                                            <div v-if="submitted && !$v.form.tempat_lahir.required"
                                                class="invalid-feedback"> Data Wajib Diisi</div>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="form-group mb-4">
                                            <label class="font-weight-bold">Tanggal Lahir <span
                                                    class="text-danger">*</span></label>
                                            <datepicker  :input-class="'form-control'"
                                                :class="{ 'is-invalid': submitted && $v.form.tanggal_lahir.$error }"
                                                v-model="form.tanggal_lahir" placeholder="(YYYY-mm-dd)"></datepicker>
                                            <div v-if="submitted && !$v.form.tanggal_lahir.required"
                                                class="invalid-feedback"> Data Wajib Diisi</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="form-group mb-4">
                                            <label class="font-weight-bold">NISN </label>
                                            <input type="text" class="form-control"
                                                :class="{ 'is-invalid': submitted && $v.form.nisn.$error }"
                                                v-model="form.nisn" placeholder="Masukan Data">
                                            <div v-if="submitted && !$v.form.nisn.numeric"
                                                class="invalid-feedback"> Format Harus Numeric</div>
                                        </div>
                                    </div>
                                    <div class="col-md-12">
                                        <div class="form-group mb-4">
                                            <label class="font-weight-bold">NIK <span
                                                    class="text-danger">*</span> </label>
                                            <input type="text" class="form-control"
                                                :class="{ 'is-invalid': submitted && $v.form.nik.$error }"
                                                v-model="form.nik" placeholder="Masukan Data">
                                            <div v-if="submitted && !$v.form.nik.required"
                                                class="invalid-feedback"> Data Wajib Diisi </div>
                                            <div v-if="submitted && !$v.form.nik.numeric"
                                                class="invalid-feedback"> Format Harus Numeric</div>
                                        </div>
                                    </div>
                                    <div class="col-md-12">
                                        <div class="form-group mb-4">
                                            <label class="font-weight-bold">Nomor Handphone Bakal Calon Peserta</label>
                                            <input type="text" class="form-control"
                                                :class="{ 'is-invalid': submitted && $v.form.nomor_handphone.$error }"
                                                v-model="form.nomor_handphone" placeholder="Masukan Data">
                                            <div v-if="submitted && !$v.form.nomor_handphone.numeric"
                                                class="invalid-feedback"> Format Harus Numeric</div>
                                        </div>
                                    </div>
                                    <div class="col-md-12">
                                        <div class="form-group mb-4">
                                            <label class="font-weight-bold">Email Bakal Calon Peserta</label>
                                            <input type="text" class="form-control"
                                                :class="{ 'is-invalid': submitted && $v.form.email.$error }"
                                                v-model="form.email" placeholder="Masukan Data">
                                            <div v-if="submitted && !$v.form.email.email"
                                                class="invalid-feedback"> Format email tidak valid</div>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="form-group mb-4">
                                            <label class="font-weight-bold">Anak Ke <span
                                                    class="text-danger">*</span></label>
                                            <input type="text" class="form-control"
                                                :class="{ 'is-invalid': submitted && $v.form.anak_ke.$error }"
                                                v-model="form.anak_ke" placeholder="Masukan Data">
                                            <div v-if="submitted && !$v.form.anak_ke.required"
                                                class="invalid-feedback"> Data Wajib Diisi</div>
                                            <div v-if="submitted && !$v.form.anak_ke.numeric"
                                                class="invalid-feedback"> Format harus numeric</div>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="form-group mb-4">
                                            <label class="font-weight-bold">Dari<span
                                                    class="text-danger">*</span></label>
                                            <input type="text" class="form-control"
                                                :class="{ 'is-invalid': submitted && $v.form.jumlah_saudara.$error }"
                                                v-model="form.jumlah_saudara" placeholder="Masukan Data">
                                            <div v-if="submitted && !$v.form.jumlah_saudara.required"
                                                class="invalid-feedback"> Data Wajib Diisi</div>
                                            <div v-if="submitted && !$v.form.jumlah_saudara.numeric"
                                                class="invalid-feedback"> Format harus numeric</div>
                                        </div>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="form-group mb-4">
                                            <label class="font-weight-bold">Provinsi <span
                                                    class="text-danger">*</span></label>
                                            <v-select
                                                placeholder="Pilih Provinsi"
                                                label="name"
                                                :options="listProvince"
                                                v-model="form.provinsi"
                                                :class="{ 'is-invalid': submitted && $v.form.provinsi.$error }"
                                                @input="getCity"
                                                >
                                            </v-select>

                                            <div v-if="submitted && !$v.form.provinsi.required" class="invalid-feedback">
                                                Wajib Diisi</div>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="form-group mb-4">
                                            <label class="font-weight-bold">Kota <span class="text-danger">*</span></label>
                                            <v-select
                                                placeholder="Pilih Kota"
                                                label="name"
                                                :options="listCity"
                                                v-model="form.kota"
                                                :class="{ 'is-invalid': submitted && $v.form.kota.$error }"
                                                @input="getDistrict"
                                                >                                    
                                            </v-select>

                                            <div v-if="submitted && !$v.form.kota.required" class="invalid-feedback">
                                                Wajib Diisi</div>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="form-group mb-4">
                                            <label class="font-weight-bold">Kecamatan <span
                                                    class="text-danger">*</span></label>
                                            <v-select
                                                placeholder="Pilih kecamatan"
                                                label="name"
                                                :options="listDistricts"
                                                v-model="form.kecamatan"
                                                :class="{ 'is-invalid': submitted && $v.form.kecamatan.$error }"
                                                @input="getSubDistrict"
                                                >
                                            </v-select>

                                            <div v-if="submitted && !$v.form.kecamatan.required" class="invalid-feedback">
                                                Wajib Diisi</div>
                                        </div>
                                    </div>

                                    <div class="col-md-6">
                                        <div class="form-group mb-4">
                                            <label class="font-weight-bold">Kelurahan <span
                                                    class="text-danger">*</span></label>

                                            <v-select
                                                placeholder="Pilih kelurahan"
                                                label="name"
                                                :options="listSubDistricts"
                                                v-model="form.kelurahan"
                                                :class="{ 'is-invalid': submitted && $v.form.kelurahan.$error }"
                                                >
                                            </v-select>

                                            <div v-if="submitted && !$v.form.kelurahan.required" class="invalid-feedback">
                                                Wajib Diisi</div>
                                        </div>
                                    </div>

                                    <div class="col-md-6">
                                        <div class="form-group mb-4">
                                            <label class="font-weight-bold">RT <span class="text-danger">*</span></label>
                                            <input type="text" class="form-control"
                                                :class="{ 'is-invalid': submitted && $v.form.rt.$error }" v-model="form.rt"
                                                placeholder="Masukan Data">
                                            <div v-if="submitted && !$v.form.rt.required" class="invalid-feedback"> Data
                                                Wajib Diisi</div>
                                        </div>
                                    </div>

                                    <div class="col-md-6">
                                        <div class="form-group mb-4">
                                            <label class="font-weight-bold">RW <span class="text-danger">*</span></label>
                                            <input type="text" class="form-control"
                                                :class="{ 'is-invalid': submitted && $v.form.rw.$error }" v-model="form.rw"
                                                placeholder="Masukan Data">
                                            <div v-if="submitted && !$v.form.rw.required" class="invalid-feedback"> Data
                                                Wajib Diisi</div>
                                        </div>
                                    </div>
                                </div>

                                <div class="form-group mb-4">
                                    <label class="font-weight-bold">Alamat Lengkap <span
                                            class="text-danger">*</span></label>
                                    <textarea name="alamat_lengkap" id="" cols="30" rows="10" class="form-control"
                                        :class="{ 'is-invalid': submitted && $v.form.alamat_lengkap.$error }"
                                        v-model="form.alamat_lengkap"></textarea>
                                    <div v-if="submitted && !$v.form.alamat_lengkap.required" class="invalid-feedback"> Data
                                        Wajib Diisi</div>
                                </div>

                                <div class="form-group mb-4">
                                    <label class="font-weight-bold">
                                        Pas foto Bakal Calon Peserta
                                        <span class="text-danger">*</span>
                                    </label>

                                    <div class="custom-file" >
                                        <input type="file" :name="'file_attachment'" style="cursor:pointer !important" class="custom-file-input" id="inputGroupFile01" aria-describedby="inputGroupFileAddon01" @change="previewFiles($event)" accept="image/x-png,image/gif,image/jpeg">
                                        <label class="custom-file-label" for="inputGroupFile01">Choose file</label>
                                    </div>

                                    <div class="d-none mt-3" :id="'file-uploaded-section'">
                                        <div class="mb-3">
                                            <div class="file-uploaded-area" :id="'file-uploaded-area'"></div>
                                        </div>
                                    </div>                                

                                    <div class="mt-3" :id="'file-uploaded-section-before'" v-bind:class = "form.foto ?'':'d-none'">
                                        <div class="mb-3">
                                            <div class="file-uploaded-area" v-bind:style="{ 'background-image': 'url(' + form.foto + ')' }"></div>
                                        </div>
                                    </div>
                                </div>

                                <div class="d-flex justify-content-end pt-4 d-none">
                                    <progress v-if="uploadFile" max="100" :value.prop="uploadPercentage" style="width:100%"></progress>
                                </div>
                            </div>

                            <!-- Action -->
                            <div class="d-flex justify-content-end pt-4 border-top">
                                <button v-if="!uploadFile" type="submit" class="btn btn-primary px-5"
                                    :disabled="this.$parent.registrasi.status_pendaftaran == 'waiting' || this.$parent.registrasi.status_pendaftaran == 'applied'">
                                    Simpan<span class="fe fe-arrow-right ml-2"></span></button>
                            </div>

                            <div class="d-flex justify-content-end pt-4 d-none border-top" v-if="uploadFile">
                                <button type="button" class="btn btn-primary px-5" disabled>
                                    Uploading {{uploadPercentage}}%...
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
    import vSelect from "vue-select";
    import "vue-select/dist/vue-select.css";

    import {required, numeric, email} from "vuelidate/lib/validators";
    
    import Datepicker from 'vuejs-datepicker';
    import moment from 'moment';

    export default {
        name: 'DataPendaftar',
        metaInfo: {
            title: 'SchoolPro',
            titleTemplate: '%s - Data Pendaftar'
        },
        components : {
            Datepicker,
            'v-select' : vSelect,
        },
        data() {
            return {
                form: {
                    provinsi    : [],
                    kota        : [],
                    kecamatan   : [],
                    kelurahan   : [],
                },
                submitted: false,
                allowAcccess: false,
                fetchData: true,
                listProvince : [],
                listCity : [],
                listDistricts : [],
                listSubDistricts : [],
                uploadFile : false,
                file_attachment : [],
                uploadPercentage: 0
            }
        },
        validations: {
            form: {
                nama_lengkap: {
                    required
                },
                tempat_lahir: {
                    required
                },
                tanggal_lahir: {
                    required
                },
                provinsi: {
                    required
                },
                kota: {
                    required
                },
                kecamatan: {
                    required
                },
                kelurahan: {
                    required
                },
                rt: {
                    required
                },
                rw: {
                    required
                },
                alamat_lengkap: {
                    required
                },
                anak_ke: {
                    required,
                    numeric
                },
                jumlah_saudara: {
                    required,
                    numeric
                },
                nisn: {
                    numeric
                },
                nik: {
                    required,
                    numeric
                },
                nomor_handphone: {
                    numeric
                },
                email: {
                    email
                },
            }
        },
        created() {
            this.getData();
        },
        methods: {
            handleSubmit() {
                this.submitted = true;
                this.$v.$touch();
                if (this.$v.$invalid) {
                    this.$swal({
                        icon: 'warning',
                        title: 'Ups!',
                        text: 'Harap lengkapi form',
                    });
                    return;
                } else {
                    if(!this.file_attachment.name && !this.form.foto) {
                        this.$swal({
                            icon: 'warning',
                            title: 'Ups!',
                            text: 'Foto wajib diisi!',
                        });
                        return;
                    } else {                        
                        if(this.file_attachment.name) {
                            this.uploadFile = true;

                            // const url_api   = 'https://victoria-worker.edmission.id/';
                            const callback  = this.$apiconfig + 'website/registrasi/save_pendaftaran';
                            let formData    = new FormData();
                            
                            formData.append('file[]', this.file_attachment);
                            formData.append('file_path', "registrasi/foto/"+this.$route.params.id+"_");
                            formData.append('callback', callback);

                            this.$http.post(this.$api_upload_engine, formData , {
                                onUploadProgress: function(progressEvent) {
                                    this.uploadPercentage = Math.round( (progressEvent.loaded * 100) / progressEvent.total )
                                }.bind(this)
                            })
                            .then(response => {
                                this.uploadFile = false;
                                const res       = response.data;

                                if (res.status && res.result != "") {
                                    this.saveData(res.result[0]['path']);
                                } else {
                                    this.$swal({
                                        icon: 'error',
                                        title: 'Oops...',
                                        text: 'Gagal mengunggah file, coba lagi nanti!',
                                    })
                                }
                            });
                        } else {
                            this.saveData(this.form.foto);
                        }
                    }
                }
            },
            getData: function () {
                this.fetchData = true;
                let uri = this.$apiconfig + 'website/registrasi/check_pendaftaran';
                this.$http.get(uri, {
                    params: {
                        registrasi_id: this.$route.params.id
                    }
                }).then(res => {
                    let getResponse = res.data;

                    if (getResponse.status) {
                        this.form = getResponse.data;
                    }

                    this.allowAcccess   = getResponse.status;
                    this.fetchData      = false;
                    this.getProvince();
                });
            },
            convertDate : function (date) {
                return moment(date, 'YYYY-MM-DD').format('yyyy-MM-DD');
            },
            getProvince: function () {
                let uri = 'https://somme.scola.id/region/province';
                
                this.$http.get(uri).then(res => {
                    let getResponse = res.data;
                    this.listProvince = getResponse.data;
                    this.getCity();
                });
            },
            getCity: function() {
                this.listCity = [];
                
                if(this.form.provinsi) {
                    if(this.form.provinsi.id) {
                        let uri = 'https://somme.scola.id/region/province_detail';
                        this.$http.get(uri, {
                            params: {
                                type: 'city',
                                id_province : this.form.provinsi.id
                            }
                        }
                        ).then(res => {
                            let getResponse = res.data;
                            this.listCity = getResponse.data;
                            this.getDistrict();
                        });
                    }
                }
            },
            getDistrict: function() {
                this.listDistricts = [];
                
                if(this.form.provinsi) {
                    if(this.form.provinsi.id) {
                        let uri = 'https://somme.scola.id/region/province_detail';
                        this.$http.get(uri, {
                            params: {
                                type: 'district',
                                id_province : this.form.provinsi.id,
                                id_city : this.form.kota.id
                            }
                        }
                        ).then(res => {
                            let getResponse = res.data;
                            this.listDistricts = getResponse.data;
                            this.getSubDistrict();
                        });
                    }
                }
            },
            getSubDistrict: function() {
                this.listSubDistricts = [];
                
                if(this.form.kecamatan) {
                    if(this.form.kecamatan.id) {
                        let uri = 'https://somme.scola.id/region/province_detail';
                        this.$http.get(uri, {
                            params: {
                                type: 'subdistrict',
                                id_province : this.form.provinsi.id,
                                id_city : this.form.kota.id,
                                id_district : this.form.kecamatan.id,
                            }
                        }
                        ).then(res => {
                            let getResponse = res.data;
                            this.listSubDistricts = getResponse.data;
                        });
                    }
                }
            },
            previewFiles(e) {
                const file = e.target.files[0];                
                var fsize = ((file.size/1024)/1024).toFixed(4); // MB
                
                if(fsize >= 5) {
                    alert("File yang di upload tidak boleh lebih 5 MB");
                } else {

                    this.preview_image = URL.createObjectURL(file);
                    const preview_area = document.getElementById("file-uploaded-section");
                    const preview_area_image = document.getElementById("file-uploaded-area");
                    const prebview_before = document.getElementById("file-uploaded-section-before");

                    preview_area.classList.remove("d-none");
                    preview_area_image.style.backgroundImage = "url('"+this.preview_image+"')";
                    prebview_before.classList.add("d-none");

                    this.file_attachment = file;
                }
            },
            saveData : function(foto) {
                this.$http.post(this.$apiconfig + 'website/registrasi/save_pendaftaran', {
                    registrasi_id: this.$route.params.id,
                    nama_lengkap: this.form.nama_lengkap,
                    jenis_kelamin: this.form.jenis_kelamin,
                    tempat_lahir: this.form.tempat_lahir,
                    tanggal_lahir: this.convertDate(this.form.tanggal_lahir),
                    nisn: this.form.nisn,
                    provinsi: this.form.provinsi,
                    kota: this.form.kota,
                    kecamatan: this.form.kecamatan,
                    kelurahan: this.form.kelurahan,
                    rt: this.form.rt,
                    rw: this.form.rw,
                    alamat_lengkap: this.form.alamat_lengkap,
                    anak_ke: this.form.anak_ke,
                    jumlah_saudara: this.form.jumlah_saudara,
                    nik: this.form.nik,
                    nomor_handphone: this.form.nomor_handphone,
                    email: this.form.email,
                    foto: foto,
                    form_step: 2
                })
                .then(response => {
                    if (response.data.status) {
                        this.$swal({
                            icon: 'success',
                            title: 'Berhasil',
                            text: response.data.message,
                            confirmButtonText: 'OK',
                            showCancelButton: false,
                        }).then((result) => {
                            if (result.value) {
                                this.$parent.getStepPendaftaran();
                                this.$parent.changeTab('DataWali');
                            }
                        });
                    } else {
                        this.$swal({
                            icon: 'error',
                            title: 'Oops...',
                            text: response.data.message,
                        })
                    }
                });
            }
        }
    }
</script>