<template>
    <div class="col-wiz-r">
        <div class="d-block py-lg-4 py-3 px-lg-5 px-md-4 px-3">

            <div class="show-mobile pb-3 mb-4 border-bottom">
                <div class="row">
                    <div class="col-6">
                        <small>Formulir</small>
                        <div class="d-block font-weight-bold">{{this.$parent.registrasiData.data.nama_formulir}}</div>
                    </div>
                    <div class="col-6">
                        <small>Nomor Seleksi</small>
                        <div class="d-block font-weight-bold">{{this.$parent.registrasiData.data.kode_registrasi}}</div>
                    </div>
                </div>
            </div>
            
            <!-- Informasi dari Admin -->
            <div class="d-block bg-light rounded px-4 py-3 mb-4 border shadow" v-if="registrasi.show_notes && registrasi.message">
                <div class="d-flex">
                    <div class="flex-shrink-0 mr-4">
                        <img src="https://assets.scola.id/images/file-archived.svg" width="100">
                    </div>
                    <div>
                        <div class="h4">Catatan</div>
                        <div class="d-block ">
                            {{registrasi.message}}
                        </div>
                    </div>
                </div>
            </div>
            <!-- -->

            <div class="d-block pb-3 mb-4 border-bottom">
                <div class="d-block mb-2 text-sm">Tahap</div>
                <div class="d-block">
                    <h1 class="m-0">{{this.$parent.step_title}}</h1>
                </div>
            </div>

            <div class="d-flex pb-md-3 pb-0 mb-4 border-bottom d-flex-wrap" v-if="this.registrasi.status_pendaftaran != 'waiting' && this.registrasi.status_pendaftaran != 'applied' && this.registrasi.status_pendaftaran != 'rejected'">
                <div class="nav-pendaftaran mb-md-0 mb-4" style="cursor:pointer !important"
                    v-for="(item, index) in componentStep"
                    :key="index"
                    :class="item.enable ? item.finished ? ''+item.class: currentTabComponent == item.component ? ''+item.class:'block-muted block-muted-have-icon-circle '+item.class: 'block-muted block-muted-have-icon-circle '+item.class"
                    :id="item.component"
                    v-on:click="changeTab(item.component,item.enable)">
                    <div class="d-block text-center mb-2">
                        <div class="icon-circle-lg" :class="item.finished ? currentTabComponent == item.component ? 'icon-circle-primary':'icon-circle-success' :'icon-circle-primary'">
                            <span :class="item.icon+' text-lg'"></span>
                        </div>
                    </div>
                    <div class="d-block text-center">{{item.component_name}}</div>
                </div>
            </div>

            <div class="tab-content">
                <keep-alive>
                    <component :is="currentTab"></component>
                </keep-alive>
            </div>

        </div>
    </div>
</template>

<script>
import AsalPendaftar from "@/components/siswa/registrasi/pendaftaran/AsalPendaftar.vue"
import DataPendaftar from "@/components/siswa/registrasi/pendaftaran/DataPendaftar.vue"
import DataWali from "@/components/siswa/registrasi/pendaftaran/DataWali.vue"
import DataBerkas from "@/components/siswa/registrasi/pendaftaran/DataBerkas.vue"
import Pratinjau from "@/components/siswa/registrasi/pendaftaran/Pratinjau.vue"

// Beta Component
import AsalPendaftarBeta from "@/components/siswa/registrasi/pendaftaran/beta/AsalPendaftar.vue"
import DataPendaftarBeta from "@/components/siswa/registrasi/pendaftaran/beta/DataPendaftar.vue"
import DataWaliBeta from "@/components/siswa/registrasi/pendaftaran/beta/DataWali.vue"
import PratinjauBeta from "@/components/siswa/registrasi/pendaftaran/beta/Pratinjau.vue"

export default {
    name: 'Pendaftaran',
    metaInfo: {
        title: 'SchoolPro',
        titleTemplate: '%s - Pendaftaran'
    },
    components: {
        AsalPendaftar,
        AsalPendaftarBeta,
        DataPendaftar,
        DataPendaftarBeta,
        DataWali,
        DataWaliBeta,
        DataBerkas,
        Pratinjau,
    },
    data() {
        return {
            currentTabComponent : 'AsalPendaftar',
            currentTab          : 'AsalPendaftar',
            componentStep       : [],
            registrasi          : []
        }
    },
    created() {
        this.changeTab(this.currentTab);
        this.getStepPendaftaran();
    },
    methods: {
        getStepPendaftaran: function () {
            this.fetchSidebar = true;
            let uri = this.$apiconfig + 'website/registrasi/step_pendaftaran';
            this.$http.get(uri, {
                params: {
                    registrasi_id: this.$route.params.id,
                    step_users_id: this.$parent.step_register_id
                }
            }).then(res => {
                let getResponse     = res.data;
                this.componentStep  = getResponse.step_pendaftaran;
                this.registrasi     = getResponse.registrasi;

                if(this.registrasi.status_pendaftaran == 'waiting' || this.registrasi.status_pendaftaran == 'applied' || this.registrasi.status_pendaftaran == 'rejected') {
                    this.changeTab('Pratinjau');            
                }
            });
        },
        changeTab: function(tabActive, enable=true) {
            if(enable) {
                this.currentTabComponent = tabActive;

                if(this.$beta_custom_form) {
                    if(tabActive == 'AsalPendaftar') {
                        tabActive = AsalPendaftarBeta;
                    }
                    else if(tabActive == 'DataPendaftar') {
                        tabActive = DataPendaftarBeta;
                    }
                    else if(tabActive == 'DataWali') {
                        tabActive = DataWaliBeta;
                    }
                    else if(tabActive == 'Pratinjau') {
                        tabActive = PratinjauBeta;
                    }
                }

                this.currentTab = tabActive;
            }
        },
    }
}
</script>
