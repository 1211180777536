<template>
    <div class="col-wiz-r">
        <div class="d-block py-lg-4 py-3 px-lg-5 px-md-4 px-3">

            <!-- Fetching -->
            <div class="d-none">
                <div class="d-block px-6 py-6 text-center rounded animate-load-1" style="background-color:#dee4ec;">
                    <img src="https://scolacdn.com/frontend/argonne-img/owly-loading.png" width="180" class="mx-auto mb-4">
                    <p>Sedang mengambil data. Mohon tunggu...</p>
                </div>
            </div>

            <div class="show-mobile pb-3 mb-4 border-bottom">
                <div class="row">
                    <div class="col-6">
                        <small>Formulir</small>
                        <div class="d-block font-weight-bold">{{this.$parent.registrasiData.data.nama_formulir}}</div>
                    </div>
                    <div class="col-6">
                        <small>Nomor Seleksi</small>
                        <div class="d-block font-weight-bold">{{this.$parent.registrasiData.data.kode_registrasi}}</div>
                    </div>
                </div>
            </div>

            <div class="d-block pb-3 mb-4 border-bottom">
                <div class="d-block mb-2 text-sm">Tahap</div>
                <div class="d-block">
                    <h1 class="m-0">{{this.$parent.step_title}}</h1>
                </div>
            </div>

            <div class="d-block">
                <div class="card">
                    <div class="d-block px-lg-6 py-lg-6 px-md-5 py-md-5">
                        <div class="d-flex align-items-center mb-4 pb-4 border-bottom">
                            <div class="icon-circle flex-shrink-0 mr-3">
                                <span class="fe fe-book-open m-0 text-muted"></span>
                            </div>
                            <div>
                                <h2 class="m-0">Tes Seleksi Masuk {{listData.data.nama_formulir}}</h2>
                            </div>
                        </div>
                        <div
                            class="d-flex align-items-center justify-content-between pb-2 border-bottom mb-4">
                            <div v-html="listData.data.informasi_tes"></div>
                        </div>
                        <div class="d-flex pb-2">
                            Jadwal Tes - <b>{{listData.data.jadwal_tes_bc}}</b>
                        </div>

                        <div v-if="listData.data.status_testing == 'pending'" class="d-block mt-4">
                            <div class="d-block" v-if="listData.data.open_testing">
                                <a v-if="listData.data.url_tes" :href="listData.data.url_tes" target="blank" class="btn btn-primary btn-block mb-2">
                                    Mulai Tes</a>
                            </div>

                            <div class="d-block" v-if="!listData.data.open_testing">
                                <button v-if="listData.data.url_tes" type="button" class="btn btn-primary btn-block mb-2 disabled">
                                    Mulai Tes</button>

                                <div class="text-sm d-block py-2 px-3 bg-warning mt-3 rounded font-weight-bold">Tes Belum Dimulai</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Lulus -->
            <div class="d-block" v-if="listData.data.status_testing == 'applied'">
                <div class="card m-0">
                    <div class="card-body pb-0">
                        <div class="d-block">
                            <div class="alert alert-success shadow px-3 py-2 m-0">

                                <div class="d-flex align-items-center">
                                    <div class="flex-shrink-0 mr-3">
                                        <span class="fe fe-zap h2 m-0"></span>
                                    </div>
                                    <div>
                                        Selamat Anda lolos!
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="d-block px-lg-6 pt-lg-6 pb-lg-5 px-md-5 pt-md-5 pb-md-4">
                        <div class="d-flex d-flex-mobile">
                            <div class="mr-md-5 mr-0 mb-md-0 mb-4">
                                <img src="https://scolacdn.com/frontend/argonne-img/illustration-ppdb-1.png" width="160">
                            </div>
                            <div>
                                <div class="d-block">
                                    <div class="h1 mb-3">Selamat Anda lolos pada tahap seleksi masuk calon peserta!</div>
                                    <div class="d-block" v-html="listData.data.deskripsi_lulus"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- -->

            <!-- Tidak Lulus -->
            <div class="d-block" v-if="listData.data.status_testing == 'rejected'">
                <div class="row">
                    <div class="col-md-12">
                        <div class="card">
                            <div class="d-block px-lg-6 py-lg-6 px-md-5 py-md-5">
                                <div class="d-flex d-flex-mobile">
                                    <div class="mr-md-4 mr-0 mb-md-0 mb-4">
                                        <img src="https://assets.scola.id/images/forbiden-2.png" width="160">
                                    </div>
                                    <div>
                                        <div class="d-block mb-2">
                                            <div class="h1 mb-3">Anda belum lolos pada tahap seleksi masuk calon peserta.</div>
                                            <div v-if="listData.data.deskripsi_tidak_lulus" class="d-block" v-html="listData.data.deskripsi_tidak_lulus"></div>
                                            <div v-if="!listData.data.deskripsi_tidak_lulus" class="d-block">
                                                Silakan mencoba kembali pada gelombang selanjutnya                                                
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- -->
        </div>
    </div>    
</template>

<script>
export default {
    name: 'Seleksi',
    metaInfo: {
        title: 'SchoolPro',
        titleTemplate: '%s - Seleksi'
    },
    data() {
        return {
            search: "",
            listData: [],
            fetchData: true,
        }
    },
    created() {
        this.getData();
    },
    methods : {
        getData: function () {
            this.fetchData = true;

            let uri = this.$apiconfig + 'website/registrasi/check_testing';
            this.$http.get(uri, {
                params: {
                    registrasi_id: this.$route.params.id,
                    step_users_id: this.$parent.step_register_id
                }
            }).then(res => {
                let getResponse = res.data;
                this.listData = getResponse;

                this.fetchData = false;
            });
        },
    }
}
</script>
