<template>
    <div class="d-block">
        <div v-if="fetchData">
            <div class="d-block px-6 py-6 text-center rounded animate-load-1" style="background-color:#dee4ec;">
                <img src="https://scolacdn.com/frontend/argonne-img/owly-loading.png" width="180" class="mx-auto mb-4">
                <p>Sedang mengambil data. Mohon tunggu...</p>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12" v-if="!fetchData">
                <div class="card" v-if="!allowAcccess">
                    <div class="card-body">
                        <p>Kamu belum diperbolehkan mengakses halaman ini</p>
                    </div>
                </div>
                
                <div v-if="error" class="alert alert-danger mb-3" role="alert" id="error">
                    <div class="align-items-center">
                        <ul>
                            <li v-for="(item, index) in errorString" :key="index">
                                <b>{{item}}</b> Wajib diisi!
                            </li>
                        </ul>
                    </div>
                </div>

                <div class="card card-full-mobile card-full-mobile-px3" v-if="allowAcccess">
                    <form v-on:submit.prevent="handleSubmit">
                        <div class="card-body">
                            <div class="d-block border-bottom pb-3 text-muted">
                                Isian Data Pendaftar
                            </div>
                            <div class="px-lg-6 py-lg-4">
                                <!-- <div class="form-group mb-4">
                                    <label class="font-weight-bold">Nomor Seleksi</label>
                                </div> -->
                                <input type="hidden" class="form-control disabled" disabled
                                    v-model="form.kode_registrasi">

                                <div class="form-group mb-4" v-if="dataComponent.default.nama_lengkap.value == '1'">
                                    <label class="font-weight-bold">Nama Lengkap Bakal Calon Peserta (Sesuai Akte Lahir)
                                        <small v-if="dataComponent.default.nama_lengkap.required == '1'"><span class="text-danger">(Wajib Diisi)</span></small>
                                    </label>
                                    <input type="text" class="form-control" v-model="form.nama_lengkap" placeholder="Masukan Nama">
                                </div>
                                
                                <div class="form-group mb-4" v-if="dataComponent.default.jenis_kelamin.value == '1'">
                                    <label class="font-weight-bold">Jenis Kelamin
                                        <small v-if="dataComponent.default.jenis_kelamin.required == '1'"><span class="text-danger">(Wajib Diisi)</span></small>
                                    </label>
                                    <div class="d-flex">
                                        <div class="mr-3">
                                            <div class="pretty p-svg p-curve">
                                                <input type="radio" name="jenis_kelamin" value="L"
                                                    v-model="form.jenis_kelamin" :checked="form.jenis_kelamin == 'L'" />
                                                <div class="state p-primary">
                                                    <svg class="svg svg-icon" viewBox="0 0 20 20">
                                                        <path
                                                            d="M7.629,14.566c0.125,0.125,0.291,0.188,0.456,0.188c0.164,0,0.329-0.062,0.456-0.188l8.219-8.221c0.252-0.252,0.252-0.659,0-0.911c-0.252-0.252-0.659-0.252-0.911,0l-7.764,7.763L4.152,9.267c-0.252-0.251-0.66-0.251-0.911,0c-0.252,0.252-0.252,0.66,0,0.911L7.629,14.566z"
                                                            style="stroke: white;fill:white;"></path>
                                                    </svg>
                                                    <label class="font-weight-bold">Laki-laki</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            <div class="pretty p-svg p-curve">
                                                <input type="radio" name="jenis_kelamin" value="P"
                                                    v-model="form.jenis_kelamin" :checked="form.jenis_kelamin == 'P'" />
                                                <div class="state p-primary">
                                                    <svg class="svg svg-icon" viewBox="0 0 20 20">
                                                        <path
                                                            d="M7.629,14.566c0.125,0.125,0.291,0.188,0.456,0.188c0.164,0,0.329-0.062,0.456-0.188l8.219-8.221c0.252-0.252,0.252-0.659,0-0.911c-0.252-0.252-0.659-0.252-0.911,0l-7.764,7.763L4.152,9.267c-0.252-0.251-0.66-0.251-0.911,0c-0.252,0.252-0.252,0.66,0,0.911L7.629,14.566z"
                                                            style="stroke: white;fill:white;"></path>
                                                    </svg>
                                                    <label class="font-weight-bold">Perempuan</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-md-6" v-if="dataComponent.default.tempat_lahir.value == '1'">
                                        <div class="form-group mb-4">
                                            <label class="font-weight-bold">Tempat Lahir
                                                <small v-if="dataComponent.default.tempat_lahir.required == '1'"><span class="text-danger">(Wajib Diisi)</span></small>
                                            </label>
                                            <input type="text" class="form-control" v-model="form.tempat_lahir" placeholder="Masukan Data">
                                        </div>
                                    </div>
                                    <div class="col-md-6" v-if="dataComponent.default.tanggal_lahir.value == '1'">
                                        <div class="form-group mb-4">
                                            <label class="font-weight-bold">Tanggal Lahir
                                                <small v-if="dataComponent.default.tanggal_lahir.required == '1'"><span class="text-danger">(Wajib Diisi)</span></small>
                                            </label>
                                            <datepicker  :input-class="'form-control'" v-model="form.tanggal_lahir" placeholder="(YYYY-mm-dd)"></datepicker>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-12" v-if="dataComponent.default.nisn.value == '1'">
                                        <div class="form-group mb-4">
                                            <label class="font-weight-bold">NISN
                                                <small v-if="dataComponent.default.nisn.required == '1'"><span class="text-danger">(Wajib Diisi)</span></small>
                                            </label>
                                            <input type="text" class="form-control" v-model="form.nisn" placeholder="Masukan Data">
                                        </div>
                                    </div>
                                    <div class="col-md-12" v-if="dataComponent.default.nik.value == '1'">
                                        <div class="form-group mb-4">
                                            <label class="font-weight-bold">NIK
                                                <small v-if="dataComponent.default.nik.required == '1'"><span class="text-danger">(Wajib Diisi)</span></small>
                                            </label>
                                            <input type="text" class="form-control" v-model="form.nik" placeholder="Masukan Data">
                                        </div>
                                    </div>
                                    <div class="col-md-12" v-if="dataComponent.default.nomor_handphone.value == '1'">
                                        <div class="form-group mb-4">
                                            <label class="font-weight-bold">Nomor Handphone Bakal Calon Peserta
                                                <small v-if="dataComponent.default.nomor_handphone.required == '1'"><span class="text-danger">(Wajib Diisi)</span></small>
                                            </label>
                                            <input type="text" class="form-control" v-model="form.nomor_handphone" placeholder="Masukan Data">
                                        </div>
                                    </div>
                                    <div class="col-md-12" v-if="dataComponent.default.email.value == '1'">
                                        <div class="form-group mb-4">
                                            <label class="font-weight-bold">Email Bakal Calon Peserta
                                                <small v-if="dataComponent.default.email.required == '1'"><span class="text-danger">(Wajib Diisi)</span></small>
                                            </label>
                                            <input type="text" class="form-control"                                                
                                                v-model="form.email" placeholder="Masukan Data">
                                        </div>
                                    </div>
                                    <div class="col-md-6" v-if="dataComponent.default.anak_ke.value == '1'">
                                        <div class="form-group mb-4">
                                            <label class="font-weight-bold">Anak Ke
                                                <small v-if="dataComponent.default.anak_ke.required == '1'"><span class="text-danger">(Wajib Diisi)</span></small>
                                            </label>
                                            <input type="text" class="form-control" v-model="form.anak_ke" placeholder="Masukan Data">
                                        </div>
                                    </div>
                                    <div class="col-md-6" v-if="dataComponent.default.jumlah_saudara.value == '1'">
                                        <div class="form-group mb-4">
                                            <label class="font-weight-bold">Dari
                                                <small v-if="dataComponent.default.jumlah_saudara.required == '1'"><span class="text-danger">(Wajib Diisi)</span></small>
                                            </label>
                                            <input type="text" class="form-control" v-model="form.jumlah_saudara" placeholder="Masukan Data">
                                        </div>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-md-6" v-if="dataComponent.default.provinsi.value == '1'">
                                        <div class="form-group mb-4">
                                            <label class="font-weight-bold">Provinsi
                                                <small v-if="dataComponent.default.provinsi.required == '1'"><span class="text-danger">(Wajib Diisi)</span></small>
                                            </label>
                                            <v-select
                                                placeholder="Pilih Provinsi"
                                                label="name"
                                                :options="listProvince"
                                                v-model="form.provinsi"
                                                @input="getCity"
                                                >
                                            </v-select>
                                        </div>
                                    </div>
                                    <div class="col-md-6" v-if="dataComponent.default.kota.value == '1'">
                                        <div class="form-group mb-4">
                                            <label class="font-weight-bold">Kota
                                                <small v-if="dataComponent.default.kota.required == '1'"><span class="text-danger">(Wajib Diisi)</span></small>
                                            </label>
                                            <v-select
                                                placeholder="Pilih Kota"
                                                label="name"
                                                :options="listCity"
                                                v-model="form.kota"
                                                @input="getDistrict"
                                                >                                    
                                            </v-select>
                                        </div>
                                    </div>
                                    <div class="col-md-6" v-if="dataComponent.default.kecamatan.value == '1'">
                                        <div class="form-group mb-4">
                                            <label class="font-weight-bold">Kecamatan
                                                <small v-if="dataComponent.default.kecamatan.required == '1'"><span class="text-danger">(Wajib Diisi)</span></small>
                                            </label>
                                            <v-select
                                                placeholder="Pilih kecamatan"
                                                label="name"
                                                :options="listDistricts"
                                                v-model="form.kecamatan"
                                                @input="getSubDistrict"
                                                >
                                            </v-select>
                                        </div>
                                    </div>

                                    <div class="col-md-6" v-if="dataComponent.default.kelurahan.value == '1'">
                                        <div class="form-group mb-4">
                                            <label class="font-weight-bold">Kelurahan
                                                <small v-if="dataComponent.default.kelurahan.required == '1'"><span class="text-danger">(Wajib Diisi)</span></small>
                                            </label>

                                            <v-select
                                                placeholder="Pilih kelurahan"
                                                label="name"
                                                :options="listSubDistricts"
                                                v-model="form.kelurahan"
                                                >
                                            </v-select>
                                        </div>
                                    </div>

                                    <div class="col-md-6" v-if="dataComponent.default.rt.value == '1'">
                                        <div class="form-group mb-4">
                                            <label class="font-weight-bold">RT
                                                <small v-if="dataComponent.default.rt.required == '1'"><span class="text-danger">(Wajib Diisi)</span></small>
                                            </label>
                                            <input type="text" class="form-control"
                                                v-model="form.rt"
                                                placeholder="Masukan Data">
                                        </div>
                                    </div>

                                    <div class="col-md-6" v-if="dataComponent.default.rw.value == '1'">
                                        <div class="form-group mb-4">
                                            <label class="font-weight-bold">RW
                                                <small v-if="dataComponent.default.rw.required == '1'"><span class="text-danger">(Wajib Diisi)</span></small>
                                            </label>
                                            <input type="text" class="form-control"
                                                v-model="form.rw"
                                                placeholder="Masukan Data">
                                        </div>
                                    </div>
                                </div>

                                <div class="form-group mb-4" v-if="dataComponent.default.alamat_lengkap.value == '1'">
                                    <label class="font-weight-bold">Alamat Lengkap
                                            <small v-if="dataComponent.default.alamat_lengkap.required == '1'"><span class="text-danger">(Wajib Diisi)</span></small>
                                    </label>
                                    <textarea name="alamat_lengkap" id="" cols="30" rows="10" class="form-control"
                                        v-model="form.alamat_lengkap"></textarea>
                                </div>

                                <div class="form-group mb-4" v-if="dataComponent.default.foto.value == '1'">
                                    <label class="font-weight-bold">
                                        Pas foto Bakal Calon Peserta                               
                                        <small v-if="dataComponent.default.foto.required == '1'"><span class="text-danger">(Wajib Diisi)</span></small>
                                    </label>

                                    <div class="custom-file" >
                                        <input type="file" :name="'file_attachment'" style="cursor:pointer !important" class="custom-file-input" id="inputGroupFile01" aria-describedby="inputGroupFileAddon01" @change="previewFiles($event)" accept="image/x-png,image/gif,image/jpeg">
                                        <label class="custom-file-label" for="inputGroupFile01">Choose file</label>
                                    </div>

                                    <div class="d-none mt-3" :id="'file-uploaded-section'">
                                        <div class="mb-3">
                                            <div class="file-uploaded-area" :id="'file-uploaded-area'"></div>
                                        </div>
                                    </div>                                

                                    <div class="mt-3" :id="'file-uploaded-section-before'" v-bind:class = "form.foto ?'':'d-none'">
                                        <div class="mb-3">
                                            <div class="file-uploaded-area" v-bind:style="{ 'background-image': 'url(' + form.foto + ')' }"></div>
                                        </div>
                                    </div>
                                </div>

                                <div class="form-group mb-4" v-for="(item, index) in dataComponent.customList" :key="index">
                                    <label class="font-weight-bold">{{item.name}}
                                        <small v-if="item.required == '1'"><span class="text-danger">(Wajib Diisi)</span></small>
                                    </label>
                                    <input v-if="item.input == 'text'" type="text" class="form-control"
                                        v-model="form.custom_form[item.code]" :placeholder="item.placeholder">
                                    <select v-if="item.input == 'option'" v-model="form.custom_form[item.code]" class="form-control">
                                        <option :value="form.custom_form[item.code] == null ? form.custom_form[item.code] : ''">- Pilihan -</option>
                                        <option v-for="(list, ind) in item.option" :key="ind" :value="list">{{ list }}</option>
                                    </select>
                                </div>

                                <div class="d-flex justify-content-end pt-4 d-none">
                                    <progress v-if="uploadFile" max="100" :value.prop="uploadPercentage" style="width:100%"></progress>
                                </div>
                            </div>

                            <!-- Action -->
                            <div class="d-flex justify-content-end pt-4 border-top">
                                <button v-if="!uploadFile" type="submit" class="btn btn-primary px-5"
                                    :disabled="this.$parent.registrasi.status_pendaftaran == 'waiting' || this.$parent.registrasi.status_pendaftaran == 'applied'">
                                    Simpan<span class="fe fe-arrow-right ml-2"></span></button>
                            </div>

                            <div class="d-flex justify-content-end pt-4 d-none border-top" v-if="uploadFile">
                                <button type="button" class="btn btn-primary px-5" disabled>
                                    Uploading {{uploadPercentage}}%...
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
    import vSelect from "vue-select";
    import "vue-select/dist/vue-select.css";
    
    import Datepicker from 'vuejs-datepicker';
    import moment from 'moment';

    export default {
        name: 'DataPendaftar',
        metaInfo: {
            title: 'SchoolPro',
            titleTemplate: '%s - Data Pendaftar'
        },
        components : {
            Datepicker,
            'v-select' : vSelect,
        },
        data() {
            return {
                form: {
                    provinsi    : [],
                    kota        : [],
                    kecamatan   : [],
                    kelurahan   : [],
                    custom_form  : []
                },
                submitted: false,
                allowAcccess: false,
                fetchData: true,
                listProvince : [],
                listCity : [],
                listDistricts : [],
                listSubDistricts : [],
                uploadFile : false,
                file_attachment : [],
                uploadPercentage: 0,
                dataComponent   : {
                    default     : [],
                    customList  : [],
                },
                error : 0,
                errorString : []
            }
        },
        created() {
            this.getComponentForm();
            this.getData();
        },
        methods: {
            getComponentForm : function() {
                let uri = this.$apiconfig + 'website/registrasi/load_component_form';
                
                this.$http.get(uri, {
                    params : {
                        formulir_id : this.$parent.$parent.registrasiData.data.formulir_id,
                        flag : 'data_pendaftar'
                    }
                }).then(res => {
                    if(res.data.status) {
                        this.dataComponent = res.data.data;
                    }
                });
            },
            handleSubmit() {
                this.submitted = true;
                if(!this.file_attachment.name && !this.form.foto) {
                    this.$swal({
                        icon: 'warning',
                        title: 'Ups!',
                        text: 'Foto wajib diisi!',
                    });
                    return;
                } else {                        
                    if(this.file_attachment.name) {
                        this.uploadFile = true;

                        // const url_api   = 'https://victoria-worker.edmission.id/';
                        const callback  = this.$apiconfig + 'website/registrasi/save_data_pendaftar';
                        let formData    = new FormData();
                        
                        formData.append('file[]', this.file_attachment);
                        formData.append('file_path', "registrasi/foto/"+this.$route.params.id+"_");
                        formData.append('callback', callback);

                        this.$http.post(this.$api_upload_engine, formData , {
                            onUploadProgress: function(progressEvent) {
                                this.uploadPercentage = Math.round( (progressEvent.loaded * 100) / progressEvent.total )
                            }.bind(this)
                        })
                        .then(response => {
                            this.uploadFile = false;
                            const res       = response.data;
                            if (res.status && res.result != "") {
                                this.saveData(res.result[0]['path']);
                            } else {
                                this.$swal({
                                    icon: 'error',
                                    title: 'Oops...',
                                    text: 'Gagal mengunggah file, coba lagi nanti!',
                                })
                            }
                        });
                    } else {
                        this.saveData(this.form.foto);
                    }
                }
            },
            getData: function () {
                this.fetchData = true;
                let uri = this.$apiconfig + 'website/registrasi/check_pendaftaran';
                this.$http.get(uri, {
                    params: {
                        registrasi_id: this.$route.params.id
                    }
                }).then(res => {
                    let getResponse = res.data;

                    if (getResponse.status) {
                        this.form               = getResponse.data;
                        this.form.custom_form   = [];
                        
                        getResponse.data.custom_form_data_pendaftar.forEach(element => {
                            this.form.custom_form[element.code] = element.value;
                        });

                     }

                    this.allowAcccess   = getResponse.status;
                    this.fetchData      = false;
                    this.getProvince();
                });
            },
            convertDate : function (date) {
                return moment(date, 'YYYY-MM-DD').format('yyyy-MM-DD');
            },
            getProvince: function () {
                let uri = 'https://somme.scola.id/region/province';
                
                this.$http.get(uri).then(res => {
                    let getResponse = res.data;
                    this.listProvince = getResponse.data;
                    this.getCity();
                });
            },
            getCity: function() {
                this.listCity = [];
                
                if(this.form.provinsi) {
                    if(this.form.provinsi.id) {
                        let uri = 'https://somme.scola.id/region/province_detail';
                        this.$http.get(uri, {
                            params: {
                                type: 'city',
                                id_province : this.form.provinsi.id
                            }
                        }
                        ).then(res => {
                            let getResponse = res.data;
                            this.listCity = getResponse.data;
                            this.getDistrict();
                        });
                    }
                }
            },
            getDistrict: function() {
                this.listDistricts = [];
                
                if(this.form.provinsi) {
                    if(this.form.provinsi.id) {
                        let uri = 'https://somme.scola.id/region/province_detail';
                        this.$http.get(uri, {
                            params: {
                                type: 'district',
                                id_province : this.form.provinsi.id,
                                id_city : this.form.kota.id
                            }
                        }
                        ).then(res => {
                            let getResponse = res.data;
                            this.listDistricts = getResponse.data;
                            this.getSubDistrict();
                        });
                    }
                }
            },
            getSubDistrict: function() {
                this.listSubDistricts = [];
                
                if(this.form.kecamatan) {
                    if(this.form.kecamatan.id) {
                        let uri = 'https://somme.scola.id/region/province_detail';
                        this.$http.get(uri, {
                            params: {
                                type: 'subdistrict',
                                id_province : this.form.provinsi.id,
                                id_city : this.form.kota.id,
                                id_district : this.form.kecamatan.id,
                            }
                        }
                        ).then(res => {
                            let getResponse = res.data;
                            this.listSubDistricts = getResponse.data;
                        });
                    }
                }
            },
            previewFiles(e) {
                const file = e.target.files[0];                
                var fsize = ((file.size/1024)/1024).toFixed(4); // MB
                
                if(fsize >= 5) {
                    alert("File yang di upload tidak boleh lebih 5 MB");
                } else {

                    this.preview_image = URL.createObjectURL(file);
                    const preview_area = document.getElementById("file-uploaded-section");
                    const preview_area_image = document.getElementById("file-uploaded-area");
                    const prebview_before = document.getElementById("file-uploaded-section-before");

                    preview_area.classList.remove("d-none");
                    preview_area_image.style.backgroundImage = "url('"+this.preview_image+"')";
                    prebview_before.classList.add("d-none");

                    this.file_attachment = file;
                }
            },
            saveData : function(foto) {
                let formData    = new FormData();

                if (typeof (this.form.tanggal_lahir) !== 'undefined' && this.form.tanggal_lahir !== null) {                
                    formData.append('tanggal_lahir', this.convertDate(this.form.tanggal_lahir));
                } else {
                    formData.append('tanggal_lahir', this.form.tanggal_lahir);
                }

                formData.append('formulir_id', this.$parent.$parent.registrasiData.data.formulir_id);
                formData.append('registrasi_id', this.$route.params.id);
                formData.append('nama_lengkap', this.form.nama_lengkap);
                formData.append('jenis_kelamin', this.form.jenis_kelamin);
                formData.append('tempat_lahir', this.form.tempat_lahir);
                formData.append('nisn', this.form.nisn);
                formData.append('provinsi', JSON.stringify(this.form.provinsi));
                formData.append('kota', JSON.stringify(this.form.kota));
                formData.append('kecamatan', JSON.stringify(this.form.kecamatan));
                formData.append('kelurahan', JSON.stringify(this.form.kelurahan));
                formData.append('rt', this.form.rt);
                formData.append('rw', this.form.rw);
                formData.append('alamat_lengkap', this.form.alamat_lengkap);
                formData.append('anak_ke', this.form.anak_ke);
                formData.append('jumlah_saudara', this.form.jumlah_saudara);
                formData.append('nik', this.form.nik);
                formData.append('nomor_handphone', this.form.nomor_handphone);
                formData.append('email', this.form.email);
                formData.append('foto', foto);

                let custom_form = [];
                this.dataComponent.customList.forEach(element => {
                    if (typeof (this.form.custom_form[element.code]) !== 'undefined' && this.form.custom_form[element.code] !== null) {
                        formData.append(element.code, this.form.custom_form[element.code]);
                        custom_form.push({
                            code    : element.code,
                            name    : element.name,
                            value   : this.form.custom_form[element.code]
                        });
                    } else {
                        formData.append(element.code, '');
                        custom_form.push({
                            code    : element.code,
                            name    : element.name,
                            value   : ''
                        });
                    }
                });

                formData.append('custom_form_data_pendaftar', JSON.stringify(custom_form));

                this.$http.post(this.$apiconfig + 'website/registrasi/save_data_pendaftar', formData)
                .then(response => {
                    if (response.data.status) {
                        this.$swal({
                            icon: 'success',
                            title: 'Berhasil',
                            text: response.data.message,
                            confirmButtonText: 'OK',
                            showCancelButton: false,
                        }).then((result) => {
                            if (result.value) {
                                this.error          = response.data.error;
                                this.errorString    = response.data.error_string;

                                this.$parent.getStepPendaftaran();
                                this.$parent.changeTab('DataWali');
                            }
                        });
                    } else {
                        if(response.data.error) {
                            this.error          = response.data.error;
                            this.errorString    = response.data.error_string;
                        }
                        this.$swal({
                            icon: 'error',
                            title: 'Oops...',
                            text: response.data.message,
                        })
                        
                        let element = document.getElementById("error");
                        element.scrollIntoView(false);                        
                    }
                });
            }
        }
    }
</script>