<template>
    <div>
        <div v-if="error" class="alert alert-danger mb-3" role="alert" id="error">
            <div class="align-items-center">
                <ul>
                    <li v-for="(item, index) in errorString" :key="index">
                        <b>{{item}}</b> Wajib diisi!
                    </li>
                </ul>
            </div>
        </div>

        <form v-on:submit.prevent="handleSubmit">
            <div class="row row-sm">
                <div class="col-md-12 mb-md-0 mb-3">
                    <div class="d-block px-lg-6 py-lg-4">
                        <div class="d-flex" v-if="dataComponent.default.nama_wali.value == '1'">
                            <div class="form-group mb-4 w-100">
                                <label class="font-weight-bold">Nama wali <small><span class="text-danger" v-if="dataComponent.default.nama_wali.required == '1'">(Wajib Diisi)</span></small></label>
                                <input type="text" class="form-control" v-model="form.nama_wali">
                            </div>
                        </div>
                        <div class="d-flex" v-if="dataComponent.default.alamat_wali.value == '1'">
                            <div class="form-group mb-4 w-100">
                                <label class="font-weight-bold">Alamat Rumah Tinggal <small><span class="text-danger" v-if="dataComponent.default.alamat_wali.required == '1'">(Wajib Diisi)</span></small></label>
                                <textarea v-model="form.alamat_wali" cols="30" rows="5" class="form-control"></textarea>
                            </div>
                        </div>
                        <div class="d-flex" v-if="dataComponent.default.telepon_wali.value == '1'">
                            <div class="form-group mb-4 w-100">
                                <label class="font-weight-bold">Nomor Hp <small><span class="text-danger" v-if="dataComponent.default.telepon_wali.required == '1'">(Wajib Diisi)</span></small></label>
                                <input type="text" class="form-control"
                                    v-model="form.telepon_wali">
                            </div>
                        </div>
                        <div class="d-flex" v-if="dataComponent.default.pekerjaan_wali.value == '1'">
                            <div class="form-group mb-4 w-100">
                                <label class="font-weight-bold">Pekerjaan<small><span class="text-danger" v-if="dataComponent.default.pekerjaan_wali.required == '1'">(Wajib Diisi)</span></small></label>
                                <input type="text" class="form-control" v-model="form.pekerjaan_wali">
                            </div>
                        </div>
                        <div class="d-flex" v-if="dataComponent.default.kantor_wali.value == '1'">
                            <div class="form-group mb-4 w-100">
                                <label class="font-weight-bold">Nama Kantor/Perusahaan<small><span class="text-danger" v-if="dataComponent.default.kantor_wali.required == '1'">(Wajib Diisi)</span></small></label>
                                <input type="text" class="form-control" v-model="form.kantor_wali">
                            </div>
                        </div>
                        <div class="d-flex" v-if="dataComponent.default.email_wali.value == '1'">
                            <div class="form-group mb-4 w-100">
                                <label class="font-weight-bold">Email <small><span class="text-danger" v-if="dataComponent.default.email_wali.required == '1'">(Wajib Diisi)</span></small></label>
                                <input type="text" class="form-control" v-model="form.email_wali">
                            </div>
                        </div>
                        <div class="d-flex" v-if="dataComponent.default.alamat_kantor_wali.value == '1'">
                            <div class="form-group mb-4 w-100">
                                <label class="font-weight-bold">Alamat Kantor/Perusahaan Wali <small><span class="text-danger" v-if="dataComponent.default.alamat_kantor_wali.required == '1'">(Wajib Diisi)</span></small></label>
                                <textarea v-model="form.alamat_kantor_wali" cols="30" rows="5" class="form-control"></textarea>
                            </div>
                        </div>

                        <div class="d-flex" v-for="(item, index) in dataComponent.customList" :key="index">
                            <div class="form-group mb-4 w-100">
                                <label class="font-weight-bold">{{item.name}}
                                    <small v-if="item.required == '1'"><span class="text-danger">(Wajib Diisi)</span></small>
                                </label>
                                <input v-if="item.input == 'text'" type="text" class="form-control"
                                    v-model="form.custom_form[item.code]" :placeholder="item.placeholder">
                                <select v-if="item.input == 'option'" v-model="form.custom_form[item.code]" class="form-control">
                                        <option :value="form.custom_form[item.code] == null ? form.custom_form[item.code] : ''">- Pilihan -</option>
                                        <option v-for="(list, ind) in item.option" :key="ind" :value="list">{{ list }}</option>
                                    </select>
                            </div>
                        </div>

                        <div v-if="$beta_wali == false" class="d-flex">
                            <div class="form-group mb-4 w-100">
                                <label class="font-weight-bold">Apakah ada kakak/adik yang bersekolah disini sebelumnya?</label>
                                <div class="d-flex">
                                    <div class="mr-3">
                                        <div class="pretty p-svg p-curve">
                                            <input type="radio" name="wali_lainnya" value="iya"
                                                v-model="form.wali_lainnya" :checked="form.wali_lainnya == 'iya'" />
                                            <div class="state p-primary">
                                                <svg class="svg svg-icon" viewBox="0 0 20 20">
                                                    <path
                                                        d="M7.629,14.566c0.125,0.125,0.291,0.188,0.456,0.188c0.164,0,0.329-0.062,0.456-0.188l8.219-8.221c0.252-0.252,0.252-0.659,0-0.911c-0.252-0.252-0.659-0.252-0.911,0l-7.764,7.763L4.152,9.267c-0.252-0.251-0.66-0.251-0.911,0c-0.252,0.252-0.252,0.66,0,0.911L7.629,14.566z"
                                                        style="stroke: white;fill:white;"></path>
                                                </svg>
                                                <label class="font-weight-bold">Iya</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <div class="pretty p-svg p-curve">
                                            <input type="radio" name="wali_lainnya" value="tidak"
                                                v-model="form.wali_lainnya" :checked="form.wali_lainnya == 'tidak'" />
                                            <div class="state p-primary">
                                                <svg class="svg svg-icon" viewBox="0 0 20 20">
                                                    <path
                                                        d="M7.629,14.566c0.125,0.125,0.291,0.188,0.456,0.188c0.164,0,0.329-0.062,0.456-0.188l8.219-8.221c0.252-0.252,0.252-0.659,0-0.911c-0.252-0.252-0.659-0.252-0.911,0l-7.764,7.763L4.152,9.267c-0.252-0.251-0.66-0.251-0.911,0c-0.252,0.252-0.252,0.66,0,0.911L7.629,14.566z"
                                                        style="stroke: white;fill:white;"></path>
                                                </svg>
                                                <label class="font-weight-bold">Tidak</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div v-if="form.wali_lainnya == 'iya'">
                                    <div v-for="(row, index) in form.keterangan_wali_lainnya" :key="index" class="d-block mt-3 border rounded-lg bg-light p-4">
                                        <div class="d-flex">
                                            <div class="flex-shrink-0 mr-md-4 mr-3">
                                                <div class="icon-circle bg-dark text-white">
                                                    {{index + 1}}
                                                </div>
                                            </div>
                                            <div class="w-100">
                                                <!-- <div class="d-block">
                                                    <label class="text-sm">Kakak/adik {{index + 1}}</label>
                                                </div> -->
                                                <div class="d-block mb-3">
                                                    <label class="font-weight-bold">Nama Lengkap Peserta Kakak/Adik</label>
                                                    <input type="text" class="form-control form-control-rounded" v-model="form.keterangan_wali_lainnya[index].name" placeholder="Masukan nama peserta">
                                                </div>
                                                <div class="d-block">
                                                    <label class="font-weight-bold">Nama Sekolah</label>
                                                    <input type="text" class="form-control form-control-rounded" v-model="form.keterangan_wali_lainnya[index].school" placeholder="Masukan nama sekolah peserta">
                                                </div>
                                            </div>
                                        </div>
                                        <div v-if="index > 0" class="text-right d-block mt-3">
                                            <button type="button" class="btn btn-link p-0 text-danger" v-on:click="removeFormBrother(index)"><span class="fe fe-trash mr-2"></span>Hapus</button>
                                        </div>
                                    </div>
                                    
                                    <div class="d-block mt-3" v-if="form.keterangan_wali_lainnya.length < 5">
                                        <button type="button" class="btn btn-white text-primary" v-on:click="addFormBrother()">
                                            <div class="d-flex align-items-center">
                                                <span class="fe fe-plus mr-2"></span>
                                                <span>Tambah Anggota</span>
                                            </div>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                </div>
            </div>

            <div class="d-flex justify-content-end pt-4 border-top">
                <button type="submit" class="btn btn-primary px-5">
                    Simpan<span class="fe fe-arrow-right ml-2"></span></button>
            </div>
        </form>
    </div>
</template>

<script>
   export default {
        name: 'DataWali',
        metaInfo: {
            title: 'SchoolPro',
            titleTemplate: '%s - Data Wali'
        },
        data() {
            return {
                form : {
                    nama_wali : "",
                    alamat_wali : "",
                    telepon_wali : "",
                    pekerjaan_wali : "",
                    kantor_wali : "",
                    email_wali : "",
                    alamat_kantor_wali : "",
                    wali_lainnya: "tidak",
                    keterangan_wali_lainnya: [{'name': '', 'school': ''}],
                    custom_form: [],
                },
                submitted: false,
                allowAcccess : false,
                fetchData : true,
                currentFlagComponent: 'ortu',
                dataComponent   : {
                    default: {
                        nama_wali : {
                            required : '',
                            value : ''
                        },
                        alamat_wali : {
                            required : '',
                            value : ''
                        },
                        telepon_wali : {
                            required : '',
                            value : ''
                        },
                        pekerjaan_wali : {
                            required : '',
                            value : ''
                        },
                        kantor_wali : {
                            required : '',
                            value : ''
                        },
                        alamat_kantor_wali : {
                            required : '',
                            value : ''
                        },
                        telepon_kantor_wali : {
                            required : '',
                            value : ''
                        },
                        email_wali : {
                            required : '',
                            value : ''
                        },
                        wali_lainnya : {
                            required : '',
                            value : ''
                        },
                        keterangan_wali_lainnya : {
                            required : '',
                            value : ''
                        }
                    },
                    customList  : [],
                },
                error : 0,
                errorString : []
            }
        },
        created() {
            this.getComponentForm();
            this.getData();
        },
        methods: {
            getComponentForm : function() {
                let uri = this.$apiconfig + 'website/registrasi/load_component_form';
                
                this.$http.get(uri, {
                    params : {
                        formulir_id : this.$parent.$parent.$parent.registrasiData.data.formulir_id,
                        flag : 'data_wali'
                    }
                }).then(res => {
                    if(res.data.status) {
                        this.dataComponent = res.data.data;
                    }
                });
            },
            handleSubmit() {
                let formData    = new FormData();

                formData.append('formulir_id', this.$parent.$parent.$parent.registrasiData.data.formulir_id);
                formData.append('registrasi_id', this.$route.params.id);
                formData.append('nama_wali', this.form.nama_wali);
                formData.append('alamat_wali', this.form.alamat_wali);
                formData.append('telepon_wali', this.form.telepon_wali);
                formData.append('pekerjaan_wali', this.form.pekerjaan_wali);
                formData.append('kantor_wali', this.form.kantor_wali);
                formData.append('email_wali', this.form.email_wali);
                formData.append('alamat_kantor_wali', this.form.alamat_kantor_wali);
                formData.append('wali_lainnya', this.form.wali_lainnya);
                formData.append('keterangan_wali_lainnya', JSON.stringify(this.form.keterangan_wali_lainnya));

                let custom_form = [];
                this.dataComponent.customList.forEach(element => {
                    if (typeof (this.form.custom_form[element.code]) !== 'undefined' && this.form.custom_form[element.code] !== null) {
                        formData.append(element.code, this.form.custom_form[element.code]);
                        custom_form.push({
                            code    : element.code,
                            name    : element.name,
                            value   : this.form.custom_form[element.code]
                        });
                    } else {
                        formData.append(element.code, '');
                        custom_form.push({
                            code    : element.code,
                            name    : element.name,
                            value   : ''
                        });
                    }
                });

                formData.append('custom_form_data_wali', JSON.stringify(custom_form));

                this.$http.post(this.$apiconfig + 'website/registrasi/save_wali_beta',formData)
                .then(response => {
                    if (response.data.status) {
                        this.$swal({
                            icon: 'success',
                            title: 'Berhasil',
                            text: response.data.message,
                            confirmButtonText: 'OK',
                            showCancelButton: false,
                        }).then((result) => {
                            if (result.value) {
                                this.error          = response.data.error;
                                this.errorString    = response.data.error_string;

                                this.$parent.getData();
                                this.$parent.$parent.getStepPendaftaran();
                                this.$parent.$parent.changeTab('DataBerkas');
                            }
                        });
                    } else {
                        if(response.data.error) {
                            this.error          = response.data.error;
                            this.errorString    = response.data.error_string;
                        }

                        this.$swal({
                            icon: 'error',
                            title: 'Oops...',
                            text: response.data.message,
                        })

                        let element = document.getElementById("error");
                        element.scrollIntoView(false);                        
                    }
                })
                .catch(function (error) {
                    // Swal.fire('Uh oh!', 'Please check your internet connection.', 'error')
                    console.debug("Login issue: " + error)
                });
            },
            getData: function () {
                this.fetchData = true;
                let uri = this.$apiconfig + 'website/registrasi/check_wali';
                this.$http.get(uri, {
                    params: {
                        registrasi_id: this.$route.params.id
                    }
                }
                ).then(res => {
                    let getResponse = res.data;

                    if(getResponse.status) {
                        if(getResponse.data) {
                            this.form = getResponse.data;
                            this.form.custom_form = [];

                            getResponse.data.custom_form_data_wali.forEach(element => {
                                this.form.custom_form[element.code] = element.value;
                            });
                        }
                    }
                    
                    this.allowAcccess = getResponse.status;
                    this.fetchData = false;
                });
            },
            addFormBrother: function() {
                this.form.keterangan_wali_lainnya.push({'name': '', 'school': ''});
            },
            removeFormBrother: function(index) {
                this.form.keterangan_wali_lainnya.splice(index, 1);
            }
        }
   }
</script>
