<template>
    <div class="col-wiz-r">
        <div class="d-block py-lg-4 py-3 px-lg-5 px-md-4 px-3">
            <div class="d-block pb-3 mb-4 border-bottom">
                <a  href="javascript: void(0)" v-on:click="backToOverview()" class="d-inline-flex align-items-center mb-3 text-dark"><span class="fe fe-arrow-left mr-2"></span>Kembali</a>
                <div class="d-block">
                    <h1 class="m-0">{{this.$parent.step_title}}</h1>
                </div>
            </div>

            <div v-if="fetching">
                <div class="d-block px-6 py-6 text-center rounded animate-load-1" style="background-color:#dee4ec;">
                    <img src="https://scolacdn.com/frontend/argonne-img/owly-loading.png" width="180" class="mx-auto mb-4">
                    <p>Sedang mengambil data. Mohon tunggu...</p>
                </div>
            </div>

            <div class="d-block" v-if="!fetching">
                <div v-if="!registrasiData.status" class="card">
                    <div class="card-body">
                        <p>Kamu belum diperbolehkan mengakses halaman ini</p>
                    </div>
                </div>
                
                <div v-if="registrasiData.status" class="card">

                    <div v-if="registrasiData.data.berkas.length == 0" class="card-body">
                        <p>Halaman belum bisa di akses untuk saat ini</p>
                    </div>

                    <form v-if="registrasiData.data.berkas.length > 0" v-on:submit.prevent="handleSubmit">
                        
                        <input type="text" class="d-none form-control" :class="{ 'is-invalid': submitted && $v.form.registrasi_id.$error }" v-model="form.registrasi_id" >
                        <div v-if="submitted && !$v.form.registrasi_id.required" class="invalid-feedback"> Data Wajib Diisi</div>

                        <div class="card-body">

                            <!-- Informasi -->
                            <div class="d-flex px-4 shadow-sm py-3 mb-4 border rounded d-flex-mobile">
                                <div class="flex-shrink-0 mr-md-4 mr-0 mb-md-0 mb-4">
                                    <img src="https://assets.scola.id/images/report.png" width="100">
                                </div>
                                <div>
                                    <div class="h4">Informasi</div>
                                    <div class="m-0" v-html="registrasiData.data.informasi_reg_ulang"></div>
                                    <div v-if="this.$parent.registrasiData.data.lapor_deskripsi != null" class="px-4 shadow-sm py-3 mb-4 border shadow m-0 rounded d-flex-mobile">
                                        <div class="d-block">
                                            <div class="m-0 d-block" v-html="this.$parent.registrasiData.data.lapor_deskripsi"></div>
                                        </div>
                                        <div v-if="this.$parent.registrasiData.data.lapor_file != null" class="d-block">                                            
                                            <a :href="this.$parent.registrasiData.data.lapor_file" target="_blank" type="button" class="btn btn-white btn-block">Lihat Lampiran<span class="fe fe-file ml-2"></span></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- -->

                            <!-- Input Berkas -->
                            <div class="d-block">
                                <div class="d-block text-sm mb-4">Unggah berkas lapor diri dibawah ini</div>

                                <!-- Alert -->
                                <div class="d-block mb-4" v-if="registrasiData.data.status_daftar_ulang != 'pending'">
                                    <div class="alert alert-danger shadow m-0" v-if="registrasiData.data.status_daftar_ulang == 'waiting'">
                                        <div class="d-flex align-items-center">
                                            <div class="flex-shrink-0 mr-4">
                                                <span class="fe fe-alert-triangle h2 m-0"></span>
                                            </div>
                                            <div >
                                                Status Lapor Diri Anda Sedang Dalam Pengecekan
                                            </div>
                                        </div>
                                    </div>

                                    <div class="alert alert-success shadow m-0" v-if="registrasiData.data.status_daftar_ulang == 'applied'">
                                        <div class="d-flex align-items-center">
                                            <div class="flex-shrink-0 mr-4">
                                                <span class="fe fe-check-circle h2 m-0"></span>
                                            </div>
                                            <div >
                                                Status Lapor Diri Anda Diterima
                                            </div>
                                        </div>
                                    </div>

                                    <div class="alert alert-warning shadow m-0" v-if="registrasiData.data.status_daftar_ulang == 're-check'">
                                        <div class="d-flex align-items-center">
                                            <div class="flex-shrink-0 mr-4">
                                                <span class="fe fe-alert-triangle h2 m-0"></span>
                                            </div>
                                            <div >
                                                Periksa kembali Data Lapor Diri yang sudah dikirim
                                            </div>
                                        </div>
                                    </div>

                                    <div class="alert alert-danger shadow m-0" v-if="registrasiData.data.status_daftar_ulang == 'rejected'">
                                        <div class="d-flex align-items-center">
                                            <div class="flex-shrink-0 mr-4">
                                                <span class="fe fe-alert-triangle h2 m-0"></span>
                                            </div>
                                            <div >
                                                Status Lapor Diri Anda Ditolak
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <!-- Informasi dari Admin -->
                                <div class="d-block bg-light rounded px-4 py-3 mb-5" v-if="registrasiData.data.status_daftar_ulang == 're-check' || registrasiData.data.status_daftar_ulang == 'rejected'">
                                    <div class="d-flex">
                                        <div class="flex-shrink-0 mr-4">
                                            <img src="https://assets.scola.id/images/file-archived.svg" width="100">
                                        </div>
                                        <div>
                                            <div class="h4">Catatan</div>
                                            <div class="d-block" v-if="registrasiData.data.notes_message">
                                                {{registrasiData.data.notes_message}}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="d-flex" v-for="(item, index) in registrasiData.data.berkas" :key="index">
                                    <div style="width: 30px;" class="flex-shrink-0">{{index+1}}.</div>
                                    <div class="form-group mb-4 border-bottom pb-4 w-100">
                                        
                                        <label class="font-weight-bold">
                                            {{item.label_name}}                                
                                            <span v-if="item.required == '1'" class="text-danger">(Wajib Diisi) *</span>
                                        </label>

                                        <div class="custom-file" v-if="registrasiData.data.status_daftar_ulang != 'waiting' && registrasiData.data.status_daftar_ulang != 'rejected'">
                                            <input type="file" :name="'file_attachment'" style="cursor:pointer !important" class="custom-file-input" id="inputGroupFile01" aria-describedby="inputGroupFileAddon01" @change="previewFiles($event,item.id)" accept="image/x-png,image/gif,image/jpeg">
                                            <label class="custom-file-label" for="inputGroupFile01">Choose file</label>
                                        </div>
                                        
                                        <div v-if="item.note" class="text-sm d-block py-2 px-3 bg-warning mt-3 rounded font-weight-bold">
                                            {{item.note}}
                                        </div>

                                        <div class="d-none mt-3" :id="'file-uploaded-section-'+item.id">
                                            <div class="mb-3">
                                                <div class="file-uploaded-area" :id="'file-uploaded-area-'+item.id"></div>
                                            </div>
                                        </div>
                                        
                                        <div class="mt-3" :id="'file-uploaded-section-before-'+item.id" v-bind:class = "item.formulir_berkas_id ?'':'d-none'">
                                            <div class="mb-3">
                                                <div class="file-uploaded-area" v-bind:style="{ 'background-image': 'url(' + item.file_attachment + ')' }"></div>
                                            </div>
                                        </div>

                                        <progress class="d-none" :id="'upload-bar-'+item.id" max="100" :value.prop="uploadPercentage" style="width:100%"></progress>
                                        <button :id="'upload-button-'+item.id" v-on:click="uploadFileAttachment(item.id)" type="button" class="btn btn-primary mt-3 d-none"><i class="fe fe-upload"></i> Upload</button>
                                        <button disabled :id="'progress-button-'+item.id" type="button" class="btn btn-primary mt-3 d-none">Uploading {{uploadPercentage}}%...</button>
                                    </div>
                                </div>
                            </div>
                            <!-- -->

                            <!-- Action -->
                            <div class="d-flex justify-content-end pt-4" v-if="registrasiData.data.status_daftar_ulang == 'pending' || registrasiData.data.status_daftar_ulang == 're-check'">
                                <button type="button" class="btn btn-primary" v-on:click="nextStep()">
                                    <span class="fe fe-send mr-2"></span> Kirim Formulir Lapor Diri
                                </button>
                            </div>
                            <!-- -->

                        </div>
                    </form>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
    import {required} from "vuelidate/lib/validators";

    export default {
        name: 'LaporDiri',
        metaInfo: {
            title: 'SchoolPro',
            titleTemplate: '%s - Lapor Diri'
        },
        validations: {
            form : {
                registrasi_id: {
                    required
                }
            }
        },
        data() {
            return {
                form :[],
                registrasiData: [],
                fetching: true,
                submitted: false,
                file_attachment : [],
                uploadPercentage: 0,
                uploadFile: false
            }
        },
        created() {
            this.checkRegistrasiUlang();  
        },
        methods : {
            backToOverview : function() {
                this.$parent.changeStep(this.$parent.step_register_id,'tinjauan','open','Tinjauan');
            },
            checkRegistrasiUlang: function () {
                this.fetching = true;
                let uri = this.$apiconfig + 'website/registrasi/check_registrasi_ulang';
                this.$http.get(uri, {
                    params: {
                        registrasi_id: this.$route.params.id,
                        step_users_id: this.$parent.step_register_id
                    }
                }
                ).then(res => {
                    this.fetching           = false;
                    let getResponse         = res.data;
                    this.registrasiData     = getResponse;
                    this.form.registrasi_id = this.$route.params.id
                });
            },
            handleSubmit() {
                this.submitted = true;
                this.$v.$touch();
                if (this.$v.$invalid) {
                    this.$swal({
                        icon: 'warning',
                        title: 'Ups!',
                        text: 'Harap lengkapi form',
                    });
                    return;
                } else {
                    this.uploadFile = true;

                    let formData = new FormData();

                    var statusUpload = true;
                    formData.append('registrasi_id', this.form.registrasi_id);
                    formData.append('step_users_id', this.$parent.step_register_id);

                    this.registrasiData.data.berkas.forEach(element => {
                        const checkFile = this.file_attachment[element.id];

                        if(!checkFile) {
                            if(element.required == '1') {
                                statusUpload = false;
                                return false;
                            }
                        }

                        formData.append('file_attachment_'+element.id, this.file_attachment[element.id]);
                    });

                    if(!statusUpload) {
                        this.$swal({
                            icon: 'warning',
                            title: 'Ups!',
                            text: 'Harap lengkapi form upload',
                        });
                        window.scrollTo({ top: 0, behavior: 'smooth' });
                        this.uploadFile = false;
                        return;
                    } else {                        
                        this.$http.post(this.$apiconfig + 'website/registrasi/save_berkas_registrasi_ulang', formData , {
                            headers: {
                                'Content-Type': 'multipart/form-data'
                            },
                            onUploadProgress: function(progressEvent) {
                                this.uploadPercentage = Math.round( (progressEvent.loaded * 100) / progressEvent.total )
                            }.bind(this)
                        })
                        .then(response => {
                            this.uploadFile = false;
                            if (response.data.status) {
                                this.$swal({
                                    icon: 'success',
                                    title: 'Berhasil',
                                    text: response.data.message,
                                    confirmButtonText: 'OK',
                                    showCancelButton: false,
                                }).then((result) => {
                                    if (result.value) {
                                        location.reload();
                                    }
                                });
                            } else {
                                this.$swal({
                                    icon: 'error',
                                    title: 'Oops...',
                                    text: response.data.message,
                                })
                            }
                        })
                        .catch(function (error) {
                            console.debug("Login issue: " + error)
                        });
                    }
                }
            },
            previewFiles(e, id) {
                const file = e.target.files[0];                
                var fsize = ((file.size/1024)/1024).toFixed(4); // MB
                
                if(fsize >= 5) {
                    alert("File yang di upload tidak boleh lebih 5 MB");
                } else {
                    this.preview_image = URL.createObjectURL(file);
                    const preview_area = document.getElementById("file-uploaded-section-"+id);
                    const prebview_before = document.getElementById("file-uploaded-section-before-"+id);
                    const preview_area_image = document.getElementById("file-uploaded-area-"+id);
                    const upload_button = document.getElementById("upload-button-"+id);

                    preview_area.classList.remove("d-none");
                    prebview_before.classList.add("d-none");
                    upload_button.classList.remove("d-none");
                    preview_area_image.style.backgroundImage = "url('"+this.preview_image+"')";

                    this.file_attachment[id] = file;
                }
            },
            uploadFileAttachment : function(id) {
                const upload_bar = document.getElementById("upload-bar-"+id);
                const progress_button = document.getElementById("progress-button-"+id);
                const upload_button = document.getElementById("upload-button-"+id);

                upload_button.classList.add("d-none");
                upload_bar.classList.remove("d-none");
                progress_button.classList.remove("d-none");

                // const url_api   = 'https://victoria-worker.edmission.id/';
                const callback  = this.$apiconfig + 'website/registrasi/save_berkas_registrasi_ulang';
                let formData    = new FormData();

                formData.append('file[]', this.file_attachment[id]);
                formData.append('file_path', "registrasi/lapordiri/"+this.$route.params.id+"_"+id+"_");
                formData.append('callback', callback);

                this.$http.post(this.$api_upload_engine, formData , {
                    onUploadProgress: function(progressEvent) {
                        this.uploadPercentage = Math.round( (progressEvent.loaded * 100) / progressEvent.total )
                    }.bind(this)
                })
                .then(response => {
                    const res       = response.data;

                    if (res.status && res.result != "") {
                        this.$http.post(this.$apiconfig + 'website/registrasi/save_berkas_registrasi_ulang', {
                            registrasi_id   : this.$route.params.id,
                            setup_berkas_id : id,
                            file_attachment : res.result[0]['path']
                        })
                        .then(response => {
                            if (response.data.status) {
                                this.$swal({
                                    icon: 'success',
                                    title: 'Berhasil',
                                    text: response.data.message,
                                    confirmButtonText: 'OK',
                                    showCancelButton: false,
                                }).then((result) => {
                                    if (result.value) {
                                        this.checkRegistrasiUlang();
                                    }
                                });
                            } else {
                                this.$swal({
                                    icon: 'error',
                                    title: 'Oops...',
                                    text: response.data.message,
                                })

                                upload_bar.classList.add("d-none");
                                progress_button.classList.add("d-none");
                                upload_button.classList.remove("d-none");
                            }
                        });
                    } else {
                        this.$swal({
                            icon: 'error',
                            title: 'Oops...',
                            text: 'Gagal mengunggah file, coba lagi nanti!',
                        })

                        upload_bar.classList.add("d-none");
                        progress_button.classList.add("d-none");
                        upload_button.classList.remove("d-none");
                    }
                });
            },
            nextStep : function() {
                var statusUpload = true;
                
                this.registrasiData.data.berkas.forEach(element => {
                    if(element.required == '1') {
                        if(!element.file_attachment) {
                            statusUpload = false;
                            return false;
                        }
                    }
                });

                if(!statusUpload) {
                    this.$swal({
                        icon: 'warning',
                        title: 'Ups!',
                        text: 'Harap lengkapi form upload',
                    });
                    return;
                } else {
                    this.$swal({
                        icon: 'warning',
                        title: 'Apakah anda yakin',
                        text: "Akan mengirimkan data berkas ini ?",
                        confirmButtonText: 'Kirim',
                        cancelButtonText: 'Batal',
                        showCancelButton: true,
                    }).then((result) => {
                        if (result.value) {
                            let formData = new FormData();
                            formData.append('registrasi_id', this.form.registrasi_id);
                            formData.append('step_users_id', this.$parent.step_register_id);

                            this.$http.post(this.$apiconfig + 'website/registrasi/save_berkas_registrasi_ulang', formData , {
                                headers: {
                                    'Content-Type': 'multipart/form-data'
                                }
                            })
                            .then(response => {
                                if (response.data.status) {
                                    this.$swal({
                                        icon: 'success',
                                        title: 'Berhasil',
                                        text: response.data.message,
                                        confirmButtonText: 'OK',
                                        showCancelButton: false,
                                    }).then((result) => {
                                        if (result.value) {
                                            this.checkRegistrasiUlang();
                                        }
                                    });
                                } else {
                                    this.$swal({
                                        icon: 'error',
                                        title: 'Oops...',
                                        text: response.data.message,
                                    })
                                }
                            })
                            .catch(function (error) {
                                console.debug("Login issue: " + error)
                            });

                        }
                    });
                }
            }
        }
    }
</script>