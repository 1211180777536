<template>
    <div class="col-wiz-r">
        <div class="d-block py-lg-4 py-3 px-lg-5 px-md-4 px-3">
            <div class="d-block pb-3 mb-4 border-bottom">
                <div class="d-block">
                    <h1 class="m-0">Cicilan</h1>
                </div>
            </div>

            <div v-if="fetching">
                <div class="d-block px-6 py-6 text-center rounded animate-load-1" style="background-color:#dee4ec;">
                    <img src="https://scolacdn.com/frontend/argonne-img/owly-loading.png" width="180" class="mx-auto mb-4">
                    <p>Sedang mengambil data. Mohon tunggu...</p>
                </div>
            </div>

            <div class="d-block">
                
                <div class="card">

                    <form v-on:submit.prevent="handleSubmit">
                        <div class="card-body">

                            <!-- Informasi -->
                            <div class="d-flex px-4 shadow-sm py-3 mb-4 border rounded d-flex-mobile">
                                <div class="flex-shrink-0 mr-md-4 mr-0 mb-md-0 mb-4">
                                    <img src="https://assets.scola.id/images/report.png" width="100">
                                </div>
                                <div>
                                    <div class="h4">Informasi</div>
                                    <div class="h3">Daftar berkas Cicilan Siswa</div>
                                    <div class="m-0" v-html="registrasiData.cicilan_deskripsi"></div>
                                    <div class="m-0">
                                        <p>Total Pembayaran : Rp. {{ formatPrice(registrasiData.cicilan_total) }}</p> 
                                        <p>Banyaknya Cicilan : {{ registrasiData.count_cicilan }}/{{ registrasiData.list_cicilan.length }} kali cicilan sudah di bayarkan</p> 
                                    </div>
                                    <div v-if="registrasiData.cicilan_file" class="d-block">                                            
                                        <a :href="registrasiData.cicilan_file" target="_blank" type="button" class="btn btn-white btn-block">Lihat Lampiran<span class="fe fe-file ml-2"></span></a>
                                    </div>
                                </div>
                            </div>
                            <!-- -->

                            <!-- Input Berkas -->
                            <div class="d-block">
                                <div class="d-block text-sm mb-4">Unggah bukti pembayaran dibawah ini</div>

                                <div class="d-flex" v-for="(item, index) in registrasiData.list_cicilan" :key="index">
                                    <div style="width: 30px;" class="flex-shrink-0">{{index+1}}.</div>
                                    <div class="form-group mb-4 border-bottom pb-4 w-100">
                                        
                                        <label class="font-weight-bold">
                                            Cicilan Tanggal {{ convertMount(item.date) }} 
                                            <span v-if="item.status == 3" style="width:100px" class="ml-2 badge badge-danger">Re-check</span>                            
                                            <span v-if="item.status == 2" style="width:100px" class="ml-2 badge badge-success">Approve</span>                            
                                        </label>
                                        <br>
                                        <p v-if="item.status == 3" class="badge badge-info">{{ item.cicilan_note }} </p>
                                        <h4>Rp. {{ formatPrice(item.cicilan) }}</h4> 

                                        <div v-if="item.status != 2" class="custom-file">
                                            <input type="file" :name="'file_attachment'" style="cursor:pointer !important" class="custom-file-input" id="inputGroupFile01" aria-describedby="inputGroupFileAddon01" @change="previewFiles($event,item.id)" accept="image/x-png,image/gif,image/jpeg">
                                            <label class="custom-file-label" for="inputGroupFile01">Choose file</label>
                                        </div>

                                        <div class="d-none mt-3" :id="'file-uploaded-section-'+item.id">
                                            <div class="mb-3">
                                                <div class="file-uploaded-area" :id="'file-uploaded-area-'+item.id"></div>
                                            </div>
                                        </div>
                                        
                                        <div class="mt-3" :id="'file-uploaded-section-before-'+item.id" v-bind:class = "item.id ?'':'d-none'">
                                            <div class="mb-3">
                                                <div class="file-uploaded-area" v-bind:style="{ 'background-image': 'url(' + item.attachment + ')' }"></div>
                                            </div>
                                        </div>
                                        <progress class="d-none" :id="'upload-bar-'+item.id" max="100" :value.prop="uploadPercentage" style="width:100%"></progress>
                                        <button :id="'upload-button-'+item.id" v-on:click="uploadFileAttachment(item.id, index)" type="button" class="btn btn-primary mt-3 d-none"><i class="fe fe-upload"></i> Upload</button>
                                        <button disabled :id="'progress-button-'+item.id" type="button" class="btn btn-primary mt-3 d-none">Uploading {{uploadPercentage}}%...</button>
                                    </div>
                                </div>
                            </div>
                            <!-- -->

                        </div>
                    </form>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
    import {required} from "vuelidate/lib/validators";
    import moment from 'moment';

    export default {
        validations: {
            form : {
                registrasi_id: {
                    required
                }
            }
        },
        data() {
            return {
                form :[],
                registrasiData: [],
                fetching: true,
                submitted: false,
                file_attachment : [],
                uploadPercentage: 0,
                uploadFile: false
            }
        },
        created() {
            this.checkRegistrasiUlang();  
        },
        methods : {
            checkRegistrasiUlang: function () {
                this.fetching = true;
                let uri = this.$apiconfig + 'website/registrasi/check_cicilan';
                this.$http.get(uri, {
                    params: {
                        registrasi_id: this.$route.params.id
                    }
                }
                ).then(res => {
                    console.log(res.data);
                    this.fetching           = false;
                    let getResponse         = res.data[0];
                    this.registrasiData     = getResponse;
                    // this.form.registrasi_id = this.$route.params.id
                });
            },
            handleSubmit() {
                this.submitted = true;
                this.$v.$touch();
                if (this.$v.$invalid) {
                    this.$swal({
                        icon: 'warning',
                        title: 'Ups!',
                        text: 'Harap lengkapi form',
                    });
                    return;
                } else {
                    this.uploadFile = true;

                    let formData = new FormData();

                    var statusUpload = true;
                    formData.append('registrasi_id', this.form.registrasi_id);
                    formData.append('step_users_id', this.$parent.step_register_id);

                    this.registrasiData.list_cicilan.forEach(element => {
                        const checkFile = this.file_attachment[element.id];

                        if(!checkFile) {
                            if(element.required == '1') {
                                statusUpload = false;
                                return false;
                            }
                        }

                        formData.append('file_attachment_'+element.id, this.file_attachment[element.id]);
                    });

                    if(!statusUpload) {
                        this.$swal({
                            icon: 'warning',
                            title: 'Ups!',
                            text: 'Harap lengkapi form upload',
                        });
                        window.scrollTo({ top: 0, behavior: 'smooth' });
                        this.uploadFile = false;
                        return;
                    } else {                        
                        this.$http.post(this.$apiconfig + 'website/registrasi/save_berkas_registrasi_ulang', formData , {
                            headers: {
                                'Content-Type': 'multipart/form-data'
                            },
                            onUploadProgress: function(progressEvent) {
                                this.uploadPercentage = Math.round( (progressEvent.loaded * 100) / progressEvent.total )
                            }.bind(this)
                        })
                        .then(response => {
                            this.uploadFile = false;
                            if (response.data.status) {
                                this.$swal({
                                    icon: 'success',
                                    title: 'Berhasil',
                                    text: response.data.message,
                                    confirmButtonText: 'OK',
                                    showCancelButton: false,
                                }).then((result) => {
                                    if (result.value) {
                                        location.reload();
                                    }
                                });
                            } else {
                                this.$swal({
                                    icon: 'error',
                                    title: 'Oops...',
                                    text: response.data.message,
                                })
                            }
                        })
                        .catch(function (error) {
                            console.debug("Login issue: " + error)
                        });
                    }
                }
            },
            previewFiles(e, id) {
                const file = e.target.files[0];                
                var fsize = ((file.size/1024)/1024).toFixed(4); // MB
                
                if(fsize >= 5) {
                    alert("File yang di upload tidak boleh lebih 5 MB");
                } else {
                    this.preview_image = URL.createObjectURL(file);
                    const preview_area = document.getElementById("file-uploaded-section-"+id);
                    const prebview_before = document.getElementById("file-uploaded-section-before-"+id);
                    const preview_area_image = document.getElementById("file-uploaded-area-"+id);
                    const upload_button = document.getElementById("upload-button-"+id);

                    preview_area.classList.remove("d-none");
                    prebview_before.classList.add("d-none");
                    upload_button.classList.remove("d-none");
                    preview_area_image.style.backgroundImage = "url('"+this.preview_image+"')";

                    this.file_attachment[id] = file;
                }
            },
            uploadFileAttachment : function(id, sortIdx) {
                const upload_bar = document.getElementById("upload-bar-"+id);
                const progress_button = document.getElementById("progress-button-"+id);
                const upload_button = document.getElementById("upload-button-"+id);

                upload_button.classList.add("d-none");
                upload_bar.classList.remove("d-none");
                progress_button.classList.remove("d-none");

                const callback  = this.$apiconfig + 'website/registrasi/save_berkas_cicilan';
                let formData    = new FormData();

                formData.append('file[]', this.file_attachment[id]);
                formData.append('file_path', "registrasi/cicilan/"+this.$route.params.id+"_"+id+"_");
                formData.append('callback', callback);

                this.$http.post(this.$api_upload_engine, formData , {
                    onUploadProgress: function(progressEvent) {
                        this.uploadPercentage = Math.round( (progressEvent.loaded * 100) / progressEvent.total )
                    }.bind(this)
                })
                .then(response => {
                    const res       = response.data;
                    console.log(response);

                    if (res.status && res.result != "") {
                        this.$http.post(this.$apiconfig + 'website/registrasi/save_berkas_cicilan', {
                            registrasi_id   : this.$route.params.id,
                            sort : sortIdx,
                            file_attachment : res.result[0]['path']
                        })
                        .then(response => {
                            if (response.data.status) {
                                this.$swal({
                                    icon: 'success',
                                    title: 'Berhasil',
                                    text: response.data.message,
                                    confirmButtonText: 'OK',
                                    showCancelButton: false,
                                }).then((result) => {
                                    if (result.value) {
                                        this.checkRegistrasiUlang();
                                    }
                                });

                                upload_bar.classList.add("d-none");
                                progress_button.classList.add("d-none");
                                upload_button.classList.add("d-none");
                            } else {
                                this.$swal({
                                    icon: 'error',
                                    title: 'Oops...',
                                    text: response.data.message,
                                })

                                upload_bar.classList.add("d-none");
                                progress_button.classList.add("d-none");
                                upload_button.classList.remove("d-none");
                            }
                        });
                    } else {
                        this.$swal({
                            icon: 'error',
                            title: 'Oops...',
                            text: 'Gagal mengunggah file, coba lagi nanti!',
                        })

                        upload_bar.classList.add("d-none");
                        progress_button.classList.add("d-none");
                        upload_button.classList.remove("d-none");
                    }
                });
            },
            convertMount : function (date) {
                return moment(date, 'YYYY-MM-DD').format('DD MMMM YYYY'); 
            },
            formatPrice(value) {
                let val = (value/1).toFixed(0).replace('.', ',')
                return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
            }
        }
    }
</script>