<template>
    <div class="d-block">
        <div v-if="fetchData">
            <div class="d-block px-6 py-6 text-center rounded animate-load-1" style="background-color:#dee4ec;">
                <img src="https://scolacdn.com/frontend/argonne-img/owly-loading.png" width="180" class="mx-auto mb-4">
                <p>Sedang mengambil data. Mohon tunggu...</p>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12" v-if="!fetchData">
                <div class="card" v-if="!allowAcccess">
                    <div class="card-body">
                        <p>Kamu belum diperbolehkan mengakses halaman ini</p>
                    </div>
                </div>

                <div class="card card-full-mobile card-full-mobile-px3" v-if="allowAcccess">
                    <div class="card-body">
                        
                        <div class="d-block border-bottom pb-3  text-muted">
                            Isian Data Informasi Orang Tua/Wali
                        </div>

                        <!-- Pilih Ortu atau Wali -->
                        <div class="d-block py-4" v-if="!form.flag_wali && $beta_wali == false">
                            <div class="d-block text-center mb-3 font-weight-bold">
                                Pilih data yang akan dimasukan
                            </div>
                            <div class="d-flex align-items-center justify-content-center">
                                <button type="button" class="btn btn-block-square hover-primary mx-2" v-on:click="changeFlag('FlagOrtu')">
                                    <div>
                                        <div class="mb-3 text-muted">A</div>
                                        <div class="h3 m-0">Informasi<br>Orang Tua {{ betaWali }}</div>
                                    </div>
                                </button>
                                <button type="button" class="btn btn-block-square hover-primary mx-2" v-on:click="changeFlag('FlagWali')">
                                    <div>
                                        <div class="mb-3 text-muted">B</div>
                                        <div class="h3 m-0">Informasi<br>Wali</div>
                                    </div>
                                </button>
                            </div>
                        </div>
                        <!-- -->

                        <div class="tab-content">
                            <keep-alive>
                                <component :is="currentFlagComponent"></component>
                            </keep-alive>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
    import FlagOrtu from "@/components/siswa/registrasi/pendaftaran/beta/FlagOrtu.vue"
    import FlagWali from "@/components/siswa/registrasi/pendaftaran/beta/FlagWali.vue"

   export default {
        name: 'DataWali',
        components: {
            FlagOrtu,
            FlagWali
        },
        metaInfo: {
            title: 'SchoolPro',
            titleTemplate: '%s - Data Wali'
        },
        data() {
            return {
                form : {
                    custom_form  : []
                },
                submitted: false,
                allowAcccess : false,
                fetchData : true,
                currentFlagComponent: '',
                betaWali : ''
            }
        },
        created() {
            this.getData();  
            this.checkPage();          
        },
        methods: {
            getData: function () {
                this.fetchData = true;
                let uri = this.$apiconfig + 'website/registrasi/check_wali';
                this.$http.get(uri, {
                    params: {
                        registrasi_id: this.$route.params.id
                    }
                }
                ).then(res => {
                    let getResponse = res.data;

                    if(getResponse.status) {
                        this.form = getResponse;
                    }
                    
                    if(this.form.flag_wali) {
                        if(this.form.flag_wali == 'ortu') {
                            this.currentFlagComponent = 'FlagOrtu';
                        } else {
                            this.currentFlagComponent = 'FlagWali';
                        }
                    }
                    
                    this.currentFlagComponent ;

                    this.allowAcccess = getResponse.status;
                    this.fetchData = false;
                });
            },
            changeFlag: function(flagActive) {
                this.currentFlagComponent = flagActive;
            },
            checkPage: function(){
                if(this.$beta_wali == true){
                    this.currentFlagComponent = 'FlagWali';
                }
            }
        }
   }
</script>
