<template>
    <div class="d-block">
        <div v-if="fetchData">
            <div class="d-block px-6 py-6 text-center rounded animate-load-1" style="background-color:#dee4ec;">
                <img src="https://scolacdn.com/frontend/argonne-img/owly-loading.png" width="180" class="mx-auto mb-4">
                <p>Sedang mengambil data. Mohon tunggu...</p>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12" v-if="!fetchData">
                <div class="card card-full-mobile-px3 card-full-mobile">
                    <form v-on:submit.prevent="handleSubmit">
                        <div class="card-body">
                            <div class="d-block border-bottom pb-3 text-muted">
                                Isian Asal Pendaftar
                            </div>
                            <div class="px-lg-6 py-lg-4">
                                <div class="form-group mb-4">
                                    <label class="font-weight-bold">Kewarganegaraan <span class="text-danger">*</span></label>
                                    <select
                                        name="kewarganegaraan"
                                        class="form-control custom-select" v-model="form.kewarganegaraan"
                                        :class="{ 'is-invalid': submitted && $v.form.kewarganegaraan.$error }"
                                        >
                                        <option value="">Pilih Kewarganegaraan</option>
                                        <option value="wni">WNI</option>
                                        <option value="wna">WNA</option>
                                    </select>

                                    <div v-if="submitted && !$v.form.kewarganegaraan.required" class="invalid-feedback">
                                        Kewarganegaraan Wajib Diisi</div>
                                </div>
                                
                                <div class="form-group mb-4">
                                    <label class="font-weight-bold">Provinsi Asal Bakal Calon Peserta<span class="text-danger">*</span></label>
                                    <v-select
                                        placeholder="Pilih Provinsi"
                                        label="name"
                                        :options="listProvince"
                                        v-model="form.asal_provinsi"
                                        :class="{ 'is-invalid': submitted && $v.form.asal_provinsi.$error }"
                                        @input="getCity"
                                        >                                    
                                    </v-select>

                                    <div v-if="submitted && !$v.form.asal_provinsi.required" class="invalid-feedback">
                                        Provinsi Asal Wajib Diisi</div>
                                </div>

                                <div class="form-group mb-4">
                                    <label class="font-weight-bold">Kota Asal Bakal Calon Peserta<span class="text-danger">*</span></label>
                                    <v-select
                                        placeholder="Pilih Kota"
                                        label="name"
                                        :options="listCity"
                                        v-model="form.asal_kota"
                                        :class="{ 'is-invalid': submitted && $v.form.asal_kota.$error }"
                                        >                                    
                                    </v-select>


                                    <div v-if="submitted && !$v.form.asal_kota.required" class="invalid-feedback">
                                        Kota Asal Wajib Diisi</div>
                                </div>

                                <div class="form-group mb-4">
                                    <label class="font-weight-bold">Asal Sekolah <span class="text-danger">*</span></label>
                                    <select
                                        name="asal_pilihan_sekolah"
                                        class="form-control custom-select" v-model="form.asal_pilihan_sekolah"
                                        :class="{ 'is-invalid': submitted && $v.form.asal_pilihan_sekolah.$error }"
                                        >
                                        <option value="">Pilih Asal Sekolah</option>
                                        <option v-for="(item, index) in asalSekolah" :key="index" :value="item.value">{{item.name}}</option>
                                    </select>

                                    <div v-if="submitted && !$v.form.asal_pilihan_sekolah.required" class="invalid-feedback">
                                        Asal Sekolah Wajib Diisi</div>
                                </div>

                                <div class="form-group mb-4">
                                    <label class="font-weight-bold">Nama Asal Sekolah Bakal Calon Peserta</label>
                                    <input type="text" class="form-control" v-model="form.asal_sekolah" placeholder="Masukan Asal Sekolah">
                                    <!--div v-if="submitted && !$v.form.asal_sekolah.required" class="invalid-feedback">
                                        Nama Asal Sekolah Wajib Diisi</div-->
                                </div>
                                <div class="form-group m-0">
                                    <label class="font-weight-bold">NPSN (Nomor Pokok Sekolah Nasional) *Khusus untuk masuk SD, SMP, SMA</label>
                                    <input type="text" class="form-control"
                                        :class="{ 'is-invalid': submitted && $v.form.npsn.$error }"
                                        v-model="form.npsn" placeholder="Masukan Data">
                                    <div v-if="submitted && !$v.form.npsn.numeric"
                                        class="invalid-feedback"> Format Harus Numeric</div>
                                </div>
                            </div>
                            <div class="d-flex justify-content-end pt-4 border-top">
                                <button type="submit" class="btn btn-primary px-5" :disabled="this.$parent.registrasi.status_pendaftaran == 'waiting' || this.$parent.registrasi.status_pendaftaran == 'applied'">
                                    Simpan
                                    <span class="fe fe-arrow-right ml-2"></span>
                                </button>
                            </div>
                        </div>
                    </form>                    
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import vSelect from "vue-select";
    import "vue-select/dist/vue-select.css";
    import {required, numeric} from "vuelidate/lib/validators";

    export default {
        name: 'AsalPendaftar',
        metaInfo: {
            title: 'SchoolPro',
            titleTemplate: '%s - Asal Pendaftar'
        },
        components: {
            'v-select' : vSelect,
        },
        data() {
            return {
                form : {
                    kewarganegaraan: "",
                    asal_provinsi: [],
                    asal_kota: [],
                    asal_sekolah: "",
                    asal_pilihan_sekolah: "",
                    npsn: "",
                },
                submitted: false,
                dataPendaftar : [],
                fetchData : true,
                listProvince : [],
                listCity : [],
                asalSekolah : [],
            }
        },
        validations: {
            form : {
                kewarganegaraan: {
                    required
                },
                asal_provinsi: {
                    required
                },
                asal_kota: {
                    required
                },
                asal_pilihan_sekolah: {
                    required
                },
                npsn: {
                    numeric
                },
            }
        },
        created() {
            this.getData();
            this.getAsalSekolah();
        },
        methods: {
            getAsalSekolah : function() {
                let uri = this.$apiconfig + 'website/registrasi/load_asal_sekolah';
                
                this.$http.get(uri, {
                    params : {
                        type : 'asal_sekolah'
                    }
                }).then(res => {
                    this.asalSekolah = res.data.data;
                });
            },
            handleSubmit() {
                this.submitted = true;
                this.$v.$touch();
                if (this.$v.$invalid) {
                    this.$swal({
                        icon: 'warning',
                        title: 'Ups!',
                        text: 'Harap lengkapi form',
                    });
                    return;
                } else {
                    this.$http.post(this.$apiconfig + 'website/registrasi/save_pendaftaran', {
                        registrasi_id           : this.$route.params.id,
                        kewarganegaraan         : this.form.kewarganegaraan,
                        asal_sekolah            : this.form.asal_sekolah,
                        asal_provinsi           : this.form.asal_provinsi,
                        asal_kota               : this.form.asal_kota,
                        asal_pilihan_sekolah    : this.form.asal_pilihan_sekolah,
                        npsn                    : this.form.npsn,
                        form_step               : 1
                    })
                    .then(response => {
                        if (response.data.status) {
                            this.$swal({
                                icon: 'success',
                                title: 'Berhasil',
                                text: response.data.message,
                                confirmButtonText: 'OK',
                                showCancelButton: false,
                            }).then((result) => {
                                if (result.value) {
                                    this.$parent.getStepPendaftaran();
                                    this.$parent.changeTab('DataPendaftar');
                                }
                            });
                        } else {
                            this.$swal({
                                icon: 'error',
                                title: 'Oops...',
                                text: response.data.message,
                            })
                        }
                    })
                    .catch(function (error) {
                        console.debug("Login issue: " + error)
                    });
                }
            },
            getData: function () {
                this.fetchData = true;
                let uri = this.$apiconfig + 'website/registrasi/check_pendaftaran';
                this.$http.get(uri, {
                    params: {
                        registrasi_id: this.$route.params.id
                    }
                }
                ).then(res => {
                    let getResponse = res.data;

                    if(getResponse.status) {
                        this.form.kewarganegaraan = getResponse.data.kewarganegaraan;
                        this.form.asal_sekolah = getResponse.data.asal_sekolah;
                        this.form.asal_provinsi = getResponse.data.asal_provinsi;
                        this.form.asal_kota = getResponse.data.asal_kota;
                        this.form.asal_pilihan_sekolah = getResponse.data.asal_pilihan_sekolah;
                        this.form.npsn = getResponse.data.npsn;
                    }

                    this.getProvince();
                    this.fetchData = false;
                });
            },
            getProvince: function () {
                let uri = 'https://somme.scola.id/region/province';
                
                this.$http.get(uri).then(res => {
                    let getResponse = res.data;
                    this.listProvince = getResponse.data;
                    this.getCity();
                });
            },
            getCity: function() {
                this.listCity = [];
                
                if(this.form.asal_provinsi) {
                    if(this.form.asal_provinsi.id) {
                        let uri = 'https://somme.scola.id/region/province_detail';
                        this.$http.get(uri, {
                            params: {
                                type: 'city',
                                id_province : this.form.asal_provinsi.id
                            }
                        }
                        ).then(res => {
                            let getResponse = res.data;
                            this.listCity = getResponse.data;
                        });
                    }
                }
            }
        }
    }
</script>
