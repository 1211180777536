<template>
    <div class="d-block">
        <div v-if="fetchData">
            <div class="d-block px-6 py-6 text-center rounded animate-load-1" style="background-color:#dee4ec;">
                <img src="https://scolacdn.com/frontend/argonne-img/owly-loading.png" width="180" class="mx-auto mb-4">
                <p>Sedang mengambil data. Mohon tunggu...</p>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12" v-if="!fetchData">
                <div v-if="error" class="alert alert-danger mb-3" role="alert" id="error">
                    <div class="align-items-center">
                        <ul>
                            <li v-for="(item, index) in errorString" :key="index">
                                <b>{{item}}</b> Wajib diisi!
                            </li>
                        </ul>
                    </div>
                </div>

                <div class="card card-full-mobile-px3 card-full-mobile">
                    <form v-on:submit.prevent="handleSubmit" id="form_submit">
                        <div class="card-body">
                            <div class="d-block border-bottom pb-3 text-muted">
                                Isian Asal Pendaftar
                            </div>
                            <div class="px-lg-6 py-lg-4">
                                <div class="form-group mb-4" v-if="dataComponent.default.kewarganegaraan.value == '1'">
                                    <label class="font-weight-bold">Kewarganegaraan
                                        <small v-if="dataComponent.default.kewarganegaraan.required == '1'"><span class="text-danger">(Wajib Diisi)</span></small>
                                    </label>
                                    <select
                                        name="kewarganegaraan"
                                        class="form-control custom-select" v-model="form.kewarganegaraan">
                                        <option value="">Pilih Kewarganegaraan</option>
                                        <option value="wni">WNI</option>
                                        <option value="wna">WNA</option>
                                    </select>
                                </div>
                                
                                <div class="form-group mb-4" v-if="dataComponent.default.asal_provinsi.value == '1'">
                                    <label class="font-weight-bold">Provinsi Asal Bakal Calon Peserta
                                        <small v-if="dataComponent.default.asal_provinsi.required == '1'"><span class="text-danger">(Wajib Diisi)</span></small>
                                    </label>
                                    <v-select
                                        placeholder="Pilih Provinsi"
                                        label="name"
                                        :options="listProvince"
                                        v-model="form.asal_provinsi"
                                        @input="getCity"
                                        >                                    
                                    </v-select>
                                </div>

                                <div class="form-group mb-4" v-if="dataComponent.default.asal_kota.value == '1'">
                                    <label class="font-weight-bold">Kota Asal Bakal Calon Peserta
                                        <small v-if="dataComponent.default.asal_kota.required == '1'"><span class="text-danger">(Wajib Diisi)</span></small>
                                    </label>
                                    <v-select
                                        placeholder="Pilih Kota"
                                        label="name"
                                        :options="listCity"
                                        v-model="form.asal_kota"
                                        >
                                    </v-select>
                                </div>

                                <div class="form-group mb-4" v-if="dataComponent.default.asal_pilihan_sekolah.value == '1'">
                                    <label class="font-weight-bold">Asal Sekolah
                                        <small v-if="dataComponent.default.asal_pilihan_sekolah.required == '1'"><span class="text-danger">(Wajib Diisi)</span></small>
                                    </label>
                                    <select
                                        name="asal_pilihan_sekolah"
                                        class="form-control custom-select" v-model="form.asal_pilihan_sekolah"
                                        >
                                        <option value="">Pilih Asal Sekolah</option>
                                        <option v-for="(item, index) in asalSekolah" :key="index" :value="item.value">{{item.name}}</option>
                                    </select>
                                </div>

                                <div class="form-group mb-4" v-if="dataComponent.default.asal_sekolah.value == '1'">
                                    <label class="font-weight-bold">Nama Asal Sekolah Bakal Calon Peserta
                                        <small v-if="dataComponent.default.asal_sekolah.required == '1'"><span class="text-danger">(Wajib Diisi)</span></small>
                                    </label>
                                    <input type="text" class="form-control" v-model="form.asal_sekolah" placeholder="Masukan Asal Sekolah">
                                </div>

                                
                                <div class="form-group mb-4" v-if="dataComponent.default.npsn.value == '1'">
                                    <label class="font-weight-bold">NPSN (Nomor Pokok Sekolah Nasional) *Khusus untuk masuk SD, SMP, SMA
                                        <small v-if="dataComponent.default.npsn.required == '1'"><span class="text-danger">(Wajib Diisi)</span></small>
                                    </label>
                                    <input type="text" class="form-control"
                                        v-model="form.npsn" placeholder="Masukan Data">
                                </div>

                                <div class="form-group mb-4" v-for="(item, index) in dataComponent.customList" :key="index">
                                    <label class="font-weight-bold">{{item.name}}
                                        <small v-if="item.required == '1'"><span class="text-danger">(Wajib Diisi)</span></small>
                                    </label>
                                    <input v-if="item.input == 'text'" type="text" class="form-control"
                                        v-model="form.custom_form[item.code]" :placeholder="item.placeholder">
                                    <select v-if="item.input == 'option'" v-model="form.custom_form[item.code]" class="form-control">
                                        <option :value="form.custom_form[item.code] == null ? form.custom_form[item.code] : ''">- Pilihan -</option>
                                        <option v-for="(list, ind) in item.option" :key="ind" :value="list">{{ list }}</option>
                                    </select>
                                </div>
                            </div>
                            <div class="d-flex justify-content-end pt-4 border-top">
                                <button type="submit" class="btn btn-primary px-5" :disabled="this.$parent.registrasi.status_pendaftaran == 'waiting' || this.$parent.registrasi.status_pendaftaran == 'applied'">
                                    Simpan
                                    <span class="fe fe-arrow-right ml-2"></span>
                                </button>
                            </div>
                        </div>
                    </form>                    
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import vSelect from "vue-select";
    import "vue-select/dist/vue-select.css";

    export default {
        name: 'AsalPendaftar',
        metaInfo: {
            title: 'SchoolPro',
            titleTemplate: '%s - Asal Pendaftar'
        },
        components: {
            'v-select' : vSelect,
        },
        data() {
            return {
                form : {
                    kewarganegaraan: "",
                    asal_provinsi: [],
                    asal_kota: [],
                    asal_sekolah: "",
                    asal_pilihan_sekolah: "",
                    npsn: "",
                    custom_form : []
                },
                submitted: false,
                dataPendaftar : [],
                fetchData : true,
                listProvince : [],
                listCity : [],
                dataComponent   : {
                    default: {
                        kewarganegaraan : {
                            required : '',
                            value : ''
                        },
                        asal_provinsi : {
                            required : '',
                            value : ''
                        },
                        asal_kota : {
                            required : '',
                            value : ''
                        },
                        asal_pilihan_sekolah : {
                            required : '',
                            value : ''
                        },
                        asal_sekolah : {
                            required : '',
                            value : ''
                        },
                        npsn : {
                            required : '',
                            value : ''
                        }
                    },
                    customList : [],
                },
                asalSekolah : [],
                error : 0,
                errorString : []
            }
        },
        created() {
            this.getComponentForm();
            this.getAsalSekolah();
            this.getData();
        },
        methods: {
            getComponentForm : function() {
                let uri = this.$apiconfig + 'website/registrasi/load_component_form';
                
                this.$http.get(uri, {
                    params : {
                        formulir_id : this.$parent.$parent.registrasiData.data.formulir_id,
                        flag : 'asal_pendaftar'
                    }
                }).then(res => {
                    if(res.data.status) {
                        this.dataComponent = res.data.data;
                    }
                });
            },
            getAsalSekolah : function() {
                let uri = this.$apiconfig + 'website/registrasi/load_asal_sekolah';
                
                this.$http.get(uri, {
                    params : {
                        type : 'asal_sekolah'
                    }
                }).then(res => {
                    this.asalSekolah = res.data.data;
                });
            },
            handleSubmit() {
                let formData    = new FormData();
                formData.append('formulir_id', this.$parent.$parent.registrasiData.data.formulir_id);
                formData.append('registrasi_id', this.$route.params.id);
                formData.append('kewarganegaraan', this.form.kewarganegaraan);
                formData.append('asal_sekolah', this.form.asal_sekolah);
                formData.append('asal_provinsi', JSON.stringify(this.form.asal_provinsi));
                formData.append('asal_kota', JSON.stringify(this.form.asal_kota));
                formData.append('asal_pilihan_sekolah', this.form.asal_pilihan_sekolah);
                formData.append('npsn', this.form.npsn);

                let custom_form = [];
                this.dataComponent.customList.forEach(element => {
                    if (typeof (this.form.custom_form[element.code]) !== 'undefined' && this.form.custom_form[element.code] !== null) {
                        formData.append(element.code, this.form.custom_form[element.code]);
                        custom_form.push({
                            code    : element.code,
                            name    : element.name,
                            value   : this.form.custom_form[element.code]
                        });
                    } else {
                        formData.append(element.code, '');
                        custom_form.push({
                            code    : element.code,
                            name    : element.name,
                            value   : ''
                        });
                    }
                });

                formData.append('custom_form_asal_pendaftar', JSON.stringify(custom_form));

                this.$http.post(this.$apiconfig + 'website/registrasi/save_asal_pendaftar', formData)
                .then(response => {
                    if (response.data.status) {
                        this.$swal({
                            icon: 'success',
                            title: 'Berhasil',
                            text: response.data.message,
                            confirmButtonText: 'OK',
                            showCancelButton: false,
                        }).then((result) => {
                            if (result.value) {
                                this.error          = response.data.error;
                                this.errorString    = response.data.error_string;

                                this.$parent.getStepPendaftaran();
                                this.$parent.changeTab('DataPendaftar');
                            }
                        });
                    } else {
                        if(response.data.error) {
                            this.error          = response.data.error;
                            this.errorString    = response.data.error_string;
                        }
                        this.$swal({
                            icon: 'error',
                            title: 'Oops...',
                            text: response.data.message,
                        })
                        
                        let element = document.getElementById("error");
                        element.scrollIntoView(false);                        
                    }
                })
                .catch(function (error) {
                    console.debug("Login issue: " + error)
                });
            },
            getData: function () {
                this.fetchData = true;
                let uri = this.$apiconfig + 'website/registrasi/check_pendaftaran';
                this.$http.get(uri, {
                    params: {
                        registrasi_id: this.$route.params.id
                    }
                }
                ).then(res => {
                    let getResponse = res.data;

                    if(getResponse.status) {
                        this.form.kewarganegaraan = getResponse.data.kewarganegaraan;
                        this.form.asal_sekolah = getResponse.data.asal_sekolah;
                        this.form.asal_provinsi = getResponse.data.asal_provinsi;
                        this.form.asal_kota = getResponse.data.asal_kota;
                        this.form.asal_pilihan_sekolah = getResponse.data.asal_pilihan_sekolah;
                        this.form.npsn = getResponse.data.npsn;

                        getResponse.data.custom_form_asal_pendaftar.forEach(element => {
                            this.form.custom_form[element.code] = element.value;
                        });
                    }

                    this.getProvince();
                    this.fetchData = false;
                });
            },
            getProvince: function () {
                let uri = 'https://somme.scola.id/region/province';
                
                this.$http.get(uri).then(res => {
                    let getResponse = res.data;
                    this.listProvince = getResponse.data;
                    this.getCity();
                });
            },
            getCity: function() {
                this.listCity = [];
                
                if(this.form.asal_provinsi) {
                    if(this.form.asal_provinsi.id) {
                        let uri = 'https://somme.scola.id/region/province_detail';
                        this.$http.get(uri, {
                            params: {
                                type: 'city',
                                id_province : this.form.asal_provinsi.id
                            }
                        }
                        ).then(res => {
                            let getResponse = res.data;
                            this.listCity = getResponse.data;
                        });
                    }
                }
            }
        }
    }
</script>
